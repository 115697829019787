<template>
  <div class="info-banner" :class="bannerClass">
    <Icon v-bind="iconParams" class="info-banner_icon" />
    <p class="info-banner_message">
      <template v-if="slots.default">
        <slot></slot>
      </template>
      <template v-else>
        {{ props.message }}
      </template>
    </p>
  </div>
</template>

<script>
const BANNER_BASE_CLASS = "info-banner";

const BANNER_TYPES = {
  error: "error",
  primary: "primary",
  success: "success",
  warning: "warning",
  attention: "attention",
  unpaid: "unpaid",
};

const BANNER_ICONS = {
  [BANNER_TYPES.error]: {
    icon: "danger",
    color: "#d63230",
  },
  [BANNER_TYPES.primary]: {
    icon: "info",
    color: "#2348a3",
  },
  [BANNER_TYPES.success]: {
    icon: "success",
    color: "#79C99E",
  },
  [BANNER_TYPES.warning]: {
    icon: "danger",
    color: "#a37023",
  },
  [BANNER_TYPES.attention]: {
    icon: "attention-round2",
    color: "#a37023",
  },
  [BANNER_TYPES.unpaid]: {
    icon: "attention-round2",
    color: "#a37023",
  },
};
</script>

<script setup>
import { computed, useSlots } from "vue";

import Icon from "@/components/icons/Icon.vue";

const slots = useSlots();

const props = defineProps({
  message: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    required: true,
    validator: (val) => Object.values(BANNER_TYPES).includes(val),
  },
  visible: {
    type: Boolean,
    default: true,
  },
});

const iconParams = computed(() => ({
  icon: BANNER_ICONS[props.type]?.icon,
  fill: BANNER_ICONS[props.type]?.color,
}));

const bannerClass = computed(() => {
  const isMessagePassed = props.message && props.message.length > 0;
  const isVisible = !!slots.default && props.visible;
  const rawModifiersList = [props.type];

  if (isMessagePassed || isVisible) {
    rawModifiersList.push("visible");
  }

  const modifiersList = rawModifiersList.map((modifier) => `${BANNER_BASE_CLASS}__${modifier}`);

  return modifiersList;
});
</script>

<style lang="scss" scoped>
.info-banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 10px 12px;
  visibility: hidden;
  min-height: 44px;

  &_message {
    font-weight: 500;
    font-size: 16px;
    margin-left: 7px;

    a {
      color: #346aed;
    }
  }

  &_icon {
    flex-shrink: 0;
  }

  &__visible {
    visibility: visible;
  }

  &__error {
    background: #fff6f5;
    color: #d63230;
  }

  &__primary {
    background: #eff4ff;
    color: #2348a3;
  }

  &__success {
    background: #f4fff9;
    color: #79c99e;
  }

  &__warning {
    background: #fffdef;
    color: #a37023;
  }

  &__attention {
    padding: 4px 24px;
    min-height: 24px;
    background: #ffeec7;
    border-radius: 5px;
    color: #444545;

    .info-banner_message {
      font-weight: 400;
      font-size: 13px;
    }
  }

  &__unpaid {
    padding: 4px 24px;
    min-height: 24px;
    background: #ffecec;
    border-radius: 5px;
    color: #444545;

    .info-banner_message {
      font-weight: 400;
      font-size: 13px;
    }
  }
}
</style>
