import { createI18n } from "vue-i18n";

import getLanguage from "@/helpers/i18n/getLanguage";

import LOCALES from "@/config/locales";
import messages from "@/i18n/messages";
import pluralizationRules from "./custom/pluralizationRules";

export default createI18n({
  fallbackLocale: LOCALES.DEFAULT_LOCALE,
  locale: getLanguage(),
  globalInjection: true,
  messages,
  pluralizationRules,
});
