import { toRaw } from "vue";
import { defineStore } from "pinia";

export const STORE_NAMESPACE = "import";

export default defineStore(STORE_NAMESPACE, {
  state: () => {
    return {
      data: null,
      date: null,
      filename: null,
    };
  },
  getters: {
    errorsCounter: (state) =>
      state.data ? state.data.filter((el) => !!el.error).length : 0,
    sortedSubscriptionsList: (state) => (sortField, sortDirection) => {
      if (!sortField || !sortDirection) {
        return state.data;
      }

      let list = Array.from(state.data).map(toRaw);

      if (list.length > 0) {
        list.sort((a, b) => {
          let valA = a[sortField]?.toString() || "";
          let valB = b[sortField]?.toString() || "";

          if (sortDirection === "asc") {
            return valA.localeCompare(valB);
          } else {
            return valB.localeCompare(valA);
          }
        });
      }

      return list;
    },
  },
});
