import packageJson from "../../package.json";

import LOCALES from "./locales";
import ROLES from "./roles";
import URLS from "./urls";
import VALIDATORS_CONFIG from "./validators";

export const BUILD_NUMBER =
  process.env.VUE_APP_BITBUCKET_BUILD_NUMBER || "local";

export const DEPLOYMENT_ENVIRONMENT =
  process.env.VUE_APP_BITBUCKET_DEPLOYMENT_ENVIRONMENT || "local";

export const LOCALSTORAGE_PREFIX = "hrportal_";
export const STORAGE_KEYS = {
  instructionsAccepted: "instructions_accepted",
  language: "language",
  token: "token",
  user: "client",
};

export const BASE_URL = process.env.VUE_APP_BASE_URL;
export const WEBSITE_BASE_URL = process.env.VUE_APP_WWW_URL;

export const BRANDS_LIST = ["allsports", "sportbenefit"];
export const BRAND = process.env.VUE_APP_BRAND?.toLowerCase() || BRANDS_LIST[0];

export const POLICY_LINKS = {
  allsports: `${WEBSITE_BASE_URL}/by/processing-personal-data`,
  sportbenefit: `${WEBSITE_BASE_URL}/cy_en/member_policy_personal_data_processing/privacy_policy_statement`,
};

export const DOWNLOAD_LINKS = {
  sportbenefit: {
    apple: "https://apps.apple.com/by/app/holder-sportbenefit/id1627690269",
    google:
      "https://play.google.com/store/apps/details?id=eu.sportbenefit.a&hl=en",
  },
};

export const SERVICE_PHONES = {
  lt: {
    support: "+37060894673",
  },
};

export const DEPLOYMENT_RELEASE = `${packageJson.name}@${packageJson.version}+${BUILD_NUMBER}`;

export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;

export { LOCALES, ROLES, URLS, VALIDATORS_CONFIG };

export default {
  BASE_URL,
  BRAND,
  WEBSITE_BASE_URL,
  BRANDS_LIST,
  DEPLOYMENT_ENVIRONMENT,
  DEPLOYMENT_RELEASE,
  DOWNLOAD_LINKS,
  LOCALES,
  LOCALSTORAGE_PREFIX,
  ROLES,
  SERVICE_PHONES,
  STORAGE_KEYS,
  URLS,
  VALIDATORS_CONFIG,
};
