<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_879_948)">
      <path
        d="M19.1664 9.16667C18.9454 9.16667 18.7335 9.25447 18.5772 9.41074C18.4209 9.56703 18.3331 9.77899 18.3331 10C18.3399 11.9377 17.6734 13.8177 16.4477 15.3185C15.222 16.8193 13.513 17.8478 11.613 18.2282C9.71298 18.6086 7.73981 18.3173 6.03076 17.4041C4.32171 16.4908 2.98289 15.0124 2.24313 13.2214C1.50337 11.4304 1.40861 9.43811 1.97503 7.58501C2.54146 5.7319 3.73391 4.13305 5.34856 3.06173C6.96321 1.9904 8.89983 1.51313 10.8274 1.71148C12.755 1.90982 14.5538 2.77148 15.9164 4.14917C15.889 4.15664 15.8612 4.16249 15.8331 4.16667H13.3331C13.1121 4.16667 12.9001 4.25446 12.7438 4.41074C12.5876 4.56703 12.4998 4.77899 12.4998 5C12.4998 5.22101 12.5876 5.43298 12.7438 5.58926C12.9001 5.74554 13.1121 5.83333 13.3331 5.83333H15.8331C16.4961 5.83333 17.132 5.56994 17.6009 5.1011C18.0697 4.63226 18.3331 3.99637 18.3331 3.33333V0.833333C18.3331 0.61232 18.2453 0.400358 18.089 0.244078C17.9327 0.0877974 17.7208 0 17.4998 0C17.2788 0 17.0668 0.0877974 16.9105 0.244078C16.7542 0.400358 16.6664 0.61232 16.6664 0.833333V2.55417C14.9602 1.02878 12.7822 0.135139 10.4963 0.0225855C8.21042 -0.0899677 5.95514 0.585381 4.10734 1.93578C2.25953 3.28618 0.931132 5.22982 0.344124 7.44192C-0.242884 9.65402 -0.0529441 12.0006 0.882205 14.0895C1.81735 16.1784 3.44106 17.883 5.48201 18.9187C7.52295 19.9543 9.85749 20.2581 12.0955 19.7794C14.3335 19.3006 16.3395 18.0683 17.7781 16.2884C19.2168 14.5084 20.001 12.2887 19.9998 10C19.9998 9.77899 19.912 9.56703 19.7557 9.41074C19.5994 9.25447 19.3874 9.16667 19.1664 9.16667Z"
        fill="black"
      />
      <path
        d="M10.0003 5C9.77931 5 9.56735 5.0878 9.41107 5.24408C9.25479 5.40036 9.16699 5.61232 9.16699 5.83333V10C9.16704 10.221 9.25487 10.4329 9.41116 10.5892L11.9112 13.0892C12.0683 13.241 12.2788 13.325 12.4973 13.3231C12.7158 13.3212 12.9248 13.2335 13.0793 13.079C13.2338 12.9245 13.3215 12.7155 13.3234 12.497C13.3253 12.2785 13.2413 12.068 13.0895 11.9108L10.8337 9.655V5.83333C10.8337 5.61232 10.7459 5.40036 10.5896 5.24408C10.4333 5.0878 10.2213 5 10.0003 5Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_879_948">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
