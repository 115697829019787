export function convertForInput(val) {
  if (!val) {
    return val;
  }

  const month = val.slice(2);
  const year = val.slice(0, 2);

  return `20${year}-${month}`;
}

export function convertFromInput(val) {
  if (!val) {
    return val;
  }
  const [fullYear, month] = val.split("-");
  const year = fullYear.slice(2);

  return `${year}${month}`;
}

export default {
  convertForInput,
  convertFromInput,
};
