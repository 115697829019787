import createAxios from "./createAxios";
import {
  defaultErrorInterceptor,
  requestSuccessInterceptor,
} from "./interceptors";
import downloadBlob from "@/helpers/downloadBlob";

const downloadClient = createAxios();

downloadClient.interceptors.request.use(
  requestSuccessInterceptor,
  defaultErrorInterceptor
);

function runCallback(cb) {
  const { fn, args } = cb;

  if (!Array.isArray(args)) {
    console.warn("Download callback args must be array type");
  }

  if (fn && args) {
    fn(...args);
  } else {
    console.error("Missed required params for download callback object.");
  }
}

export default async (url, errorCallback, successCallback) => {
  try {
    const response = await downloadClient(url, {
      responseType: "blob",
    });

    downloadBlob(response);
  } catch (error) {
    if (errorCallback) {
      return runCallback(errorCallback);
    }

    return Promise.reject(error);
  }

  if (successCallback) {
    return runCallback(successCallback);
  }

  return Promise.resolve();
};
