<template>
  <aside class="sidebar">
    <div class="sidebar__top">
      <div class="logo" :class="BRAND"></div>
      <SidebarSearch
        v-if="USER_ROLE.manager"
        v-model="state.search"
        :message="$t('Companies total: {0}', [filteredCompaniesList.length])"
      />
      <div class="datepicker-container">
        <MonthPicker v-if="!showMonthPicker" v-model="selectedDate" />
      </div>
      <nav class="sidebar-menu">
        <SidebarCompany
          v-for="company in filteredCompaniesList"
          :key="`sidebar-company-${company.id}`"
          :company="company"
        />
      </nav>
    </div>
    <div class="sidebar_bottom">
      <p class="name">{{ userStore.name }}</p>

      <div class="footer">
        <p class="position">{{ userStore.position }}</p>

        <div class="controls">
          <a @click="settings" @mouseenter="onMouseEnter('settings')" @mouseleave="onMouseLeave('settings')">
            <Icon class="icon" icon="settings" :fill="iconFillStyle('settings')" />
          </a>
          <a @click="logout" @mouseenter="onMouseEnter('logout')" @mouseleave="onMouseLeave('logout')">
            <Icon class="icon" icon="logout" :fill="iconFillStyle('logout')" />
          </a>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { computed, reactive } from "vue";

import MonthPicker from "@/components/inputs/datepicker/MonthPicker.vue";
import SidebarCompany from "./SidebarCompany.vue";
import SidebarSearch from "./SidebarSearch.vue";
import Icon from "@/components/icons/Icon.vue";

import { BRAND } from "@/config";
import useStore, { COMPANIES_REGISTRATION_TYPES } from "@/store";
import useUserRole from "@/composables/useUserRole";
import useUserStore, { ACTION_LOGOUT } from "@/store/user";
import { useRoute, useRouter } from "vue-router";
import { ROUTE_NAMES } from "@/router";

const ICONS_COLORS = {
  logout: "#D63230",
  settings: "#346AED",
};

const DEFAULT_ICON_FILL_COLOR = "#000000";

export default {
  components: {
    MonthPicker,
    SidebarCompany,
    SidebarSearch,
    Icon,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const { USER_ROLE } = useUserRole();

    const state = reactive({
      search: "",

      hover: {
        logout: false,
        settings: false,
      },
    });

    const selectedDate = computed({
      get() {
        return store.selectedDate;
      },
      set(newVal) {
        store.selectedDate = newVal;
      },
    });

    const companiesList = computed(() => store.companies);

    const filteredCompaniesList = computed(() => {
      if (!state.search) {
        return companiesList.value;
      }

      const filteredList = companiesList.value.filter(({ name }) => {
        const companyName = name.toLowerCase();
        const searchString = state.search.toLowerCase();
        return companyName.includes(searchString);
      });

      return filteredList;
    });

    const showMonthPicker = computed(() => {
      const company = companiesList.value.find((company) => String(company.id) === String(route.query.company));

      if (company.registration_type === COMPANIES_REGISTRATION_TYPES.copayments) {
        return true;
      }

      return false;
    });

    function iconFillStyle(icon) {
      if (state.hover[icon]) {
        return ICONS_COLORS[icon];
      }

      return DEFAULT_ICON_FILL_COLOR;
    }

    function onMouseEnter(key) {
      state.hover[key] = true;
    }

    function onMouseLeave(key) {
      state.hover[key] = false;
    }

    function logout() {
      userStore[ACTION_LOGOUT]();
    }

    function settings() {
      router.push({ name: ROUTE_NAMES.settings.notifications, query: route.query });
    }

    return {
      BRAND,

      USER_ROLE,
      userStore,

      state,

      selectedDate,
      filteredCompaniesList,
      showMonthPicker,

      logout,
      settings,
      iconFillStyle,
      onMouseEnter,
      onMouseLeave,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.sidebar {
  padding-top: 24px;
  background: #ffbe3f linear-gradient(270deg, rgba(33, 33, 33, 0.08) 0%, rgba(33, 33, 33, 0) 7%);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__top {
    .logo {
      height: 60px;
      width: 208px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 24px;

      @include logo-full-backgrounds;
    }

    .datepicker-container {
      margin-top: 18px;
      padding: 0 24px;
    }

    .sidebar-menu {
      margin-top: 34px;
    }
  }

  .sidebar_bottom {
    position: relative;
    width: 100%;
    border-top: 1px solid #ffb000;
    padding: 24px;
    background: #ffbe3f linear-gradient(270deg, rgba(33, 33, 33, 0.08) 0%, rgba(33, 33, 33, 0) 7%);

    .actions {
      position: absolute;
      top: 24px;
      right: 24px;
    }

    .name {
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      line-height: 1.5em;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: 2em;

      .position {
        font-weight: 400;
        font-size: 10px;
        color: #444;
      }

      .controls {
        display: flex;
        gap: 16px;

        a {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
