import PHONES_CONFIG from "@/config/phones";

const HIDDEN_SYMBOL = "*";
const MAX_VISIBLE_SYMBOLS = 2;

export function hideSymbols(originalString) {
  if (originalString.length <= 1) {
    return originalString;
  }

  let maxVisibleSymbols;

  if (originalString.length <= MAX_VISIBLE_SYMBOLS * 2) {
    maxVisibleSymbols = Math.max(1, Math.ceil(originalString.length / 2) - 1);
  } else {
    maxVisibleSymbols = MAX_VISIBLE_SYMBOLS;
  }

  let hiddenSymbolsQnt = originalString.length - maxVisibleSymbols * 2;
  hiddenSymbolsQnt = Math.max(1, hiddenSymbolsQnt);

  const stringStart = originalString.slice(0, maxVisibleSymbols);
  const stringMiddle = HIDDEN_SYMBOL.repeat(hiddenSymbolsQnt);
  const stringEndIndex = stringStart.length + stringMiddle.length;
  const stringEnd = originalString.slice(stringEndIndex);

  return stringStart + stringMiddle + stringEnd;
}

function hideTextContent(el, binding, vnode) {
  const text = vnode.children[0].el.textContent;

  if (binding.value?.visible) {
    el.innerText = text;
    return;
  }

  let updatedText = "";

  if (binding.arg === "phone") {
    const phonePrefixLength =
      PHONES_CONFIG.COUNTRIES[binding.value.country].code.length;

    const phonePrefix = text.slice(0, phonePrefixLength);
    const phoneOriginal = text.slice(phonePrefixLength);
    const phoneHidden = hideSymbols(phoneOriginal);

    updatedText = phonePrefix + phoneHidden;
  } else {
    const names = text.split(" ").filter(Boolean);
    const updatedNames = names.map((str) => hideSymbols(str));

    updatedText = updatedNames.join(" ");
  }

  el.innerText = updatedText;
}

export default {
  name: "hide-textcontent",
  mounted: hideTextContent,
  updated: hideTextContent,
};
