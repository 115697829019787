export default function prepareErrors(error) {
  const responseErrors = error?.response?.data?.errors;

  let errors = {};

  if (responseErrors) {
    errors = Object.entries(responseErrors).reduce(
      (errorsObj, [errorField, errorMessages]) => {
        let errorMessage = errorMessages;

        if (Array.isArray(errorMessages)) {
          errorMessage = errorMessages[0];
        }

        return Object.assign({}, errorsObj, { [errorField]: errorMessage });
      },
      {}
    );
  }

  return errors;
}
