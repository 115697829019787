<template>
  <div class="button_container" :class="containerClass">
    <button type="button" @click="toggleState">
      <Icon icon="dots-vertical" fill="#000" />
    </button>
    <ul class="actions-menu" :class="{ opened: state.isOpened }">
      <template v-if="props.type === ACTIONS_BUTTON_TYPES.logout">
        <li>
          <a @mouseenter="onMouseEnter('logout')" @mouseleave="onMouseLeave('logout')" @click="triggerEvent('logout')">
            {{ $t("Logout") }}
            <Icon class="icon" icon="logout" :fill="iconFillStyle('logout')" width="18" height="18" />
          </a>
        </li>
      </template>
      <template v-else>
        <li>
          <a
            href="#"
            @mouseenter="onMouseEnter('edit')"
            @mouseleave="onMouseLeave('edit')"
            @click.prevent="triggerEvent('edit')"
          >
            {{ $t("Edit") }}
            <Icon class="icon" icon="edit" :fill="iconFillStyle('edit')" />
          </a>
        </li>
        <li>
          <a
            href="#"
            @mouseenter="onMouseEnter('delete')"
            @mouseleave="onMouseLeave('delete')"
            @click.prevent="triggerEvent('delete')"
          >
            {{ $t("Delete") }}
            <Icon class="icon" icon="delete" :fill="iconFillStyle('delete')" />
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
const ICONS_COLORS = {
  edit: "#346AED",
  delete: "#D63230",
  logout: "#D63230",
};

export const ACTIONS_BUTTON_TYPES = {
  subscriptionEdit: "subscriptionEdit",
  logout: "logout",
};
</script>

<script setup>
import { computed, reactive } from "vue";

import Icon, { DEFAULT_ICON_FILL_COLOR } from "@/components/icons/Icon.vue";

import useClickOutside from "@/composables/useClickOutside";

const emit = defineEmits(["edit", "delete", "logout"]);

const props = defineProps({
  type: {
    type: String,
    default: ACTIONS_BUTTON_TYPES.subscriptionEdit,
  },
  relativeContainer: {
    type: Boolean,
    default: false,
  },
});

const { addOutsideClickHandler, removeOutsideClickHandler } = useClickOutside();

const state = reactive({
  isOpened: false,
  hover: {
    edit: false,
    delete: false,
  },
});

const containerClass = computed(() => ({
  button_container__relative: props.relativeContainer,
}));

const iconFillStyle = (icon) => {
  if (state.hover[icon]) {
    return ICONS_COLORS[icon];
  }
  return DEFAULT_ICON_FILL_COLOR;
};

function toggleState(e) {
  const newState = !state.isOpened;

  state.isOpened = newState;

  if (newState) {
    addOutsideClickHandler(closeMenu, e);
  } else {
    removeOutsideClickHandler(closeMenu);
  }
}

function closeMenu() {
  state.isOpened = false;
}

function onMouseEnter(key) {
  state.hover[key] = true;
}

function onMouseLeave(key) {
  state.hover[key] = false;
}

function triggerEvent(event) {
  emit(event);
}
</script>

<style lang="scss" scoped>
.button_container {
  width: 24px;
  height: 24px;

  button {
    background: none;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .actions-menu {
    position: absolute;
    top: calc(100% + 1px);
    right: 0;
    list-style-type: none;
    background: #fff;
    padding: 0 24px;
    border-radius: 8px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
    display: none;
    z-index: 1;

    &.opened {
      display: block;
    }

    li {
      padding: 12px 0;

      a {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #666;
        cursor: pointer;

        &:hover {
          color: #000;
        }

        .icon {
          margin-left: 24px;
        }
      }
    }
  }

  &__relative {
    position: relative;
  }
}
</style>
