<template>
  <div class="table">
    <div v-if="props.processing" class="table_spinner">
      <Spinner />
    </div>
    <table v-else>
      <thead v-if="showTableHead">
        <tr>
          <td
            v-for="(column, i) in props.columns"
            :key="`table_${instance.uid}_header_${i}`"
            v-bind="getHeadCellAttributes(column)"
          >
            <component :is="column.sortable ? 'a' : 'span'" @click.prevent="updateSorting(column)">
              {{ $t(column.header) }}
              <IconSort
                v-if="column.sortable"
                class="sort_direction"
                :active="isSortIconActive(column.key)"
                :direction="getSortIconDirection(column.key)"
              />
            </component>
          </td>
        </tr>
      </thead>
      <tbody v-if="showTableBody">
        <tr
          v-for="item in sortedItemsList"
          :key="`table_${instance.uid}_body_row_${item.id}`"
          :class="getRowClass(item.id)"
        >
          <td v-for="column in props.columns" :key="`table_${instance.uid}_body_row_${item.id}_${column.key}`">
            {{ getCellValue(column, item) }}
          </td>
          <td v-if="showActionsButton" class="actions">
            <ActionsButtonV2
              :actions="props.actions"
              @action="onAction($event, item.id)"
              @actions:show="onActionsShow(item.id)"
              @actions:close="onActionsClose(item.id)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, reactive, watchEffect } from "vue";
import isFunction from "lodash/isFunction";

import ActionsButtonV2, { ACTIONS } from "@/components/buttons/ActionsButtonV2.vue";
import IconSort from "@/components/icons/IconSort.vue";
import Spinner from "@/components/loaders/Spinner.vue";

const instance = getCurrentInstance();

const emit = defineEmits(Object.values(ACTIONS));

const props = defineProps({
  actions: {
    type: Array,
    default: () => [],
  },
  columns: {
    type: Array,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  processing: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  openedActions: null,

  sortDirection: "asc",
  sortField: null,
});

const tableHeaders = computed(() => props.columns.map(({ header }) => header));

const showTableHead = computed(() => tableHeaders.value.filter(Boolean).length > 0);
const showTableBody = computed(() => props.items.length > 0);
const showActionsButton = computed(() => props.actions?.length > 0);

const sortedItemsList = computed(() => {
  const { sortDirection, sortField } = state;
  const isSortApplicable = sortDirection && sortField && props.items.length > 0;

  if (isSortApplicable) {
    const sortedList = Array.from(props.items);

    sortedList.sort((a, b) => {
      let valA = a[sortField].toString();
      let valB = b[sortField].toString();

      if (sortDirection === "asc") {
        return valA.localeCompare(valB);
      } else {
        return valB.localeCompare(valA);
      }
    });

    return sortedList;
  }

  return props.items;
});

function isSortIconActive(key) {
  return key === state.sortField;
}

function getSortIconDirection(key) {
  return isSortIconActive(key) ? state.sortDirection : "asc";
}

function getHeadCellAttributes({ width } = {}) {
  const defaultAttrs = {
    width: "auto",
  };

  const attrs = Object.assign({}, defaultAttrs, {
    width,
  });

  return attrs;
}

function getRowClass(itemId) {
  if (state.openedActions === itemId) {
    return "actions-show";
  }

  return "";
}

function getCellValue(column, item) {
  const { key, value } = column;

  if (value) {
    if (isFunction(value)) {
      return value(item);
    }

    return item[value];
  }

  return item[key];
}

function resetSorting() {
  state.sortDirection = "asc";
  state.sortField = null;
}

function updateSorting({ key, sortable } = {}) {
  if (!sortable) {
    return;
  }

  if (key !== state.sortField) {
    state.sortDirection = "asc";
  } else {
    state.sortDirection = state.sortDirection === "asc" ? "desc" : "asc";
  }

  state.sortField = key;
}

function onAction(action, itemId) {
  emit(action, itemId);
}

function onActionsShow(itemId) {
  state.openedActions = itemId;
}

function onActionsClose(itemId) {
  if (state.openedActions === itemId) {
    state.openedActions = null;
  }
}

watchEffect(() => resetSorting(props.processing));
</script>

<style lang="scss" scoped>
.table {
  width: 100%;

  .table_spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    thead {
      font-weight: 400;
      font-size: 13px;

      td {
        padding: 0.5em 10px;
        user-select: none;

        a {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    tbody {
      tr {
        &:hover,
        &.actions-show {
          td {
            border-color: rgba(0, 0, 0, 0.3);
          }
        }

        td {
          padding: 1em 10px;
          font-weight: 500;
          font-size: 16px;
          border-width: 1px;
          border-style: solid none solid none;
          border-color: transparent;

          &:first-child {
            border-radius: 10px 0 0 10px;
            border-style: solid none solid solid;
          }

          &:last-child {
            border-radius: 0 10px 10px 0;
            border-style: solid solid solid none;
          }

          &.actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
</style>
