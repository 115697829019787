<template>
  <div class="base-input-container" :class="containerClass" :style="inlineBaseInputContainerStyles">
    <label>
      <Icon v-if="props.icon" class="icon" :icon="props.icon" />
      <input ref="inputRef" v-model="value" :type="props.type" :class="inputClass" v-bind="$attrs" />
    </label>
    <span v-if="!props.hideErrorBlock && showError" class="input_error">
      <template v-if="errorMsg === VALIDATION_ERRORS.requiredField">Заполните поле</template>
      <template v-else-if="errorMsg === VALIDATION_ERRORS.invalidCharacters">
        {{ $t("The field contains forbidden characters") }}
      </template>
      <template v-else-if="errorMsg === VALIDATION_ERRORS.invalidFormat">
        {{ $t("The phone format is incorrect") }}
      </template>
      <template v-else-if="errorMsg === VALIDATION_ERRORS.invalidEmailFormat">
        {{ $t("The email format is incorrect") }}
      </template>
      <template v-else>{{ $t(errorMsg) || $t("Field error") }}</template>
    </span>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";

import Icon from "@/components/icons/Icon.vue";

import validators, { VALIDATION_ERRORS } from "@/helpers/validators";

const emit = defineEmits(["update:modelValue"]);

const inputRef = ref(null);

const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: null,
  },
  icon: {
    type: String,
    default: null,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  hideErrorBlock: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    type: [Number, String],
    default: "",
  },
  validator: {
    type: String,
    default: null,
  },
  width: {
    type: String,
    default: null,
  },
});

const error = ref(null);
const changed = ref(false);

const containerClass = computed(() => ({
  "base-input-container__hide-error-block": props.hideErrorBlock,
  inline: props.inline,
  "width-auto": !props.width,
}));

const inlineBaseInputContainerStyles = computed(() => ({
  width: props.width,
}));

const inputClass = computed(() => ({ error: showError.value }));

const errorMsg = computed(() => (changed.value ? error.value : props.error));
const showError = computed(() => Boolean(errorMsg.value));
const value = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    changed.value = true;
    emit("update:modelValue", val);
  },
});

function valueWatcher(val) {
  const validatorName = props.validator;

  if (validatorName && validators[validatorName]) {
    const validationError = validators[validatorName](val);

    if (validationError) {
      error.value = validationError;
    } else {
      error.value = null;
    }
  }
}

onMounted(() => {
  if (props.autofocus) {
    inputRef.value.focus();
  }
});

watch(value, valueWatcher);
watch(
  () => props.error,
  () => (changed.value = false)
);

defineExpose({ error });
</script>

<style lang="scss" scoped>
$input-height: 48px;

.base-input-container {
  position: relative;
  padding-bottom: $input-height / 2;

  &__hide-error-block {
    padding-bottom: 0;
  }

  label {
    position: relative;
    display: block;
  }

  .icon {
    position: absolute;
    top: $input-height / 2;
    left: $input-height / 2;
    transform: translate(-50%, -50%);

    & + input {
      padding-left: $input-height;
    }
  }

  input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    height: $input-height;
    padding: 0 $input-height / 2;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    background-color: #fff;

    &[disabled] {
      background-color: rgba(231, 231, 231, 0.5);
      border-color: rgba(0, 0, 0, 0.3);
    }

    &:focus {
      border-color: #000;
    }

    &.error {
      border-color: #d63230;
    }
  }

  .input_error {
    font-size: 13px;
    color: #d63230;
    line-height: $input-height / 2;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: $input-height / 2;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  &.width-auto {
    width: 100%;
  }

  &.inline {
    padding-bottom: 22px;

    .icon {
      top: 11px;
      left: 11px;

      & + input {
        padding-left: 22px;
      }
    }

    input {
      border: none;
      height: 22px;
      padding: 0 11px;
      font-weight: 500;
      font-size: 18px;
      color: #444545;
      background-color: transparent;

      &[disabled] {
        color: rgba(231, 231, 231, 0.5);
      }

      &:focus {
        color: #346aed;
      }
    }

    .input_error {
      line-height: 22px;
      left: 22px;
      max-height: 22px;
    }
  }
}
</style>
