<template>
  <form class="modal_container" @submit.prevent="onSubmit">
    <div class="modal_row header">
      <h3 v-if="isEditMode">{{ $t("Edit employee data") }}</h3>
      <h3 v-else>{{ $t("Add employee") }}</h3>
      <a href="#" class="modal_close" @click.prevent="close">
        <Icon icon="modal-close" fill="#000" />
      </a>
    </div>
    <ErrorBanner class="error_banner" :message="state.errors.errorMessage" />
    <BaseInput v-model="state.name" :placeholder="$t('Name Surname')" />
    <BaseInput
      ref="phoneInputRef"
      v-model="state.phone"
      :placeholder="$t('Phone number')"
      validator="phone"
      :error="state.errors.phone"
    />
    <SelectInput v-model="state.level" :placeholder="$t('Subscription level')" :options="levelSelectOptions" />
    <div class="modal_row buttons">
      <Button button-type="light" @click.prevent="close">{{ $t("Cancel") }}</Button>
      <Button :disabled="!isSaveEnabled" :processing="state.processing" type="submit">
        <template v-if="isEditMode">{{ $t("Save") }}</template>
        <template v-else>{{ $t("Create") }}</template>
      </Button>
    </div>
  </form>
</template>

<script>
export const SUBSCRIPTION_EDIT_MODAL_MODES = {
  add: "add",
  edit: "edit",
};

export const SUBSCRIPTION_EDIT_MODAL_FLOWS = {
  import: "import",
};
</script>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useRoute } from "vue-router";

import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import Icon from "@/components/icons/Icon.vue";
import SelectInput from "@/components/inputs/SelectInput.vue";

import useStore, { ACTION_UPDATE_SUBSCRIPTION } from "@/store";
import useModalStore from "@/store/modal";
import useImportStore from "@/store/import";
import clearPhone from "@/helpers/converters/clearPhone";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";

const emit = defineEmits(["close"]);

const store = useStore();
const modalStore = useModalStore();
const importStore = useImportStore();
const route = useRoute();

const phoneInputRef = ref(null);

const state = reactive({
  id: null,
  name: "",
  phone: "",
  level: null,

  processing: false,
  errors: {},
});

const isPhoneError = computed(() => phoneInputRef.value?.error);
const isEditMode = computed(() => modalStore.mode === SUBSCRIPTION_EDIT_MODAL_MODES.edit);
const isImportFlow = computed(() => modalStore.flow === SUBSCRIPTION_EDIT_MODAL_FLOWS.import);
const levelSelectOptions = computed(() => {
  const levelsList = store.coutrySubscriptionLevels(modalStore.payload.country);
  const levels = levelsList.map((level) => ({ text: level, value: level }));
  return levels;
});
const isError = computed(() => Object.keys(state.errors).length > 0);
const isSaveEnabled = computed(() => !!state.name && !!state.phone && !!state.level && !isPhoneError.value);

function close(e) {
  emit("close");
}

async function onSubmit() {
  if (!isSaveEnabled.value || state.processing) {
    return;
  }

  if (isImportFlow.value) {
    const month = modalStore.payload.date;
    const subscription = {
      level: state.level,
      name: state.name,
      month,
      phone: clearPhone(state.phone),
    };

    // TODO: move to action
    if (isEditMode.value) {
      const index = importStore.data.findIndex((el) => el.id == state.id);

      if (index >= 0) {
        importStore.data[index] = subscription;
        emit("close");
        return;
      }
    }

    importStore.data.push(subscription);

    emit("close");
    return;
  }

  state.processing = true;
  state.errors = {};

  const { company } = route.query;
  const month = modalStore.payload.date;
  const data = {
    company,
    id: state.id,
    level: state.level,
    month,
    name: state.name,
    phone: clearPhone(state.phone),
  };

  try {
    await store[ACTION_UPDATE_SUBSCRIPTION](data);
  } catch (xhrError) {
    const { errors, errorMessage } = prepareAxiosErrors(xhrError);
    state.errors = {
      errorMessage,
      ...errors,
    };
  }

  state.processing = false;

  if (!isError.value) {
    emit("close");
  }
}

onMounted(() => {
  if (modalStore.payload) {
    const { name, level, id, phone } = modalStore.payload;
    const isOrderItemDataFilled = [name, level, id, phone].some(Boolean);

    if (isOrderItemDataFilled) {
      state.name = name;
      state.level = level;
      state.id = id;
      state.phone = phone.toString();
    }
  }
});
</script>
