function validateMonthInputFormat(val) {
  const [year, month] = val.split("-").map(Number);
  const isValueCorrect = Number.isInteger(year) && Number.isInteger(month);

  if (!isValueCorrect) {
    return false;
  }

  const isMonthCorrect = 1 <= month && month <= 12;

  return isMonthCorrect;
}

export function validateMonthInput(val) {
  if (val.length < 7) {
    return false;
  }

  return validateMonthInputFormat(val);
}
