<template>
  <div class="spinner-container" :class="containerClass">
    <div class="spinner" :class="BRAND"></div>
  </div>
</template>

<script setup>
import { computed } from "vue";

import { BRAND } from "@/config";

const props = defineProps({
  centered: {
    type: Boolean,
    default: false,
  },
});

const containerClass = computed(() => ({ centered: props.centered }));
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

@keyframes as-spin {
  0% {
    transform: scale(1) rotate(0deg);
  }

  40% {
    transform: scale(1.25) rotate(360deg);
  }

  80%,
  100% {
    transform: scale(1) rotate(360deg);
  }
}

.spinner-container {
  &.centered {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinner {
    animation: as-spin 1.4s infinite ease-in-out both;
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    @include spinner-brand-backgrounds;
  }
}
</style>
