import PHONES from "./phones";

export const SMS_CODE_LENGTH = 4;
export const SMS_CODE_VALIDATION_REGEXP = /^\d{1,4}$/;
export const EMAIL_COMMON_REGEXP =
  /^([a-zа-я0-9_-]+\.)*[a-zа-я0-9_-]+@[a-zа-я0-9_-]+(\.[a-zа-я0-9_-]+)*\.[a-zа-я]{2,6}$/;

export default {
  PHONES,
  SMS_CODE_LENGTH,
  SMS_CODE_VALIDATION_REGEXP,
  EMAIL_COMMON_REGEXP,
};
