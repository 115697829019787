import { default as phone } from "./phone";
import { default as sms_code } from "./phone";
import { default as email } from "./email";

export const VALIDATION_ERRORS = {
  invalidCharacters: "invalid_characters",
  invalidFormat: "invalid_format",
  invalidEmailFormat: "invalid_email_format",
  requiredField: "required_field",
};

export default {
  phone,
  sms_code,
  email,
  VALIDATION_ERRORS,
};
