<template>
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7141 6.135C18.1091 6.65166 18.1091 7.34916 17.7141 7.865C16.4699 9.48916 13.4849 12.8333 9.99993 12.8333C6.51493 12.8333 3.52993 9.48916 2.28576 7.865C2.09358 7.61761 1.98926 7.31326 1.98926 7C1.98926 6.68673 2.09358 6.38239 2.28576 6.135C3.52993 4.51083 6.51493 1.16666 9.99993 1.16666C13.4849 1.16666 16.4699 4.51083 17.7141 6.135V6.135Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 9.5C11.3807 9.5 12.5 8.38071 12.5 7C12.5 5.61929 11.3807 4.5 10 4.5C8.61929 4.5 7.5 5.61929 7.5 7C7.5 8.38071 8.61929 9.5 10 9.5Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
