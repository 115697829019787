<template>
  <div class="modal_container">
    <div class="modal_row header">
      <h3>{{ $t("Confirmation required") }}</h3>
      <a href="#" class="modal_close" @click.prevent="close">
        <Icon icon="modal-close" fill="#000" />
      </a>
    </div>
    <template v-if="deleteSubscriptionPersonInfo">
      <p>{{ $t("Are you sure you want to delete employee?") }}</p>
      <p>{{ deleteSubscriptionPersonInfo }}</p>
    </template>
    <p v-else>{{ $t("Are you sure?") }}</p>
    <div class="modal_row buttons">
      <Button button-type="light" @click.prevent="close">{{ $t("Cancel") }}</Button>
      <Button :processing="state.processing" @click.prevent="onAccept">{{ $t("Delete") }}</Button>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive } from "vue";

import Button from "@/components/buttons/Button.vue";
import Icon from "@/components/icons/Icon.vue";

import useModalStore from "@/store/modal";

const emit = defineEmits(["close"]);

const modalStore = useModalStore();

const state = reactive({
  processing: false,
});

const isDeleteSubscriptionAction = computed(() => modalStore.mode === "delete");
const deleteSubscriptionPersonInfo = computed(() => {
  if (isDeleteSubscriptionAction.value) {
    const { name, level, phone } = modalStore.payload;
    const personInfo = [name, level, phone].filter(Boolean).join(" ");

    return personInfo;
  } else {
    return null;
  }
});

function close() {
  emit("close");
}

async function onAccept() {
  if (state.processing) {
    return;
  }

  const { payload } = modalStore;

  if (payload && payload.callback) {
    state.processing = true;
    await payload.callback();
    state.processing = false;
  }

  close();
}
</script>
