<template>
  <div class="modal_container">
    <div class="modal_row header">
      <h3 v-if="!state.isGenerationConfirmed">{{ $t("Generate an invoice") }}</h3>
      <h3 v-else>{{ $t("Thank you") }}</h3>
      <a href="#" class="modal_close" @click.prevent="close">
        <Icon icon="modal-close" fill="#000" />
      </a>
    </div>
    <ErrorBanner v-if="!!state.errors" class="error_banner" :message="state.errors?.errorMessage" />
    <p v-if="!state.isGenerationConfirmed">
      {{
        $tc(
          `Please pay attention, the subscribers from the list will be added to the invoice. Subscribers will be automatically notified by SMS or push notification in the {brand} application.`,
          { brand: brandName }
        )
      }}
    </p>
    <p v-else>{{ $t("You can find the generated invoice on the Invoices tab for 5 minutes.") }}</p>
    <div class="modal_row buttons">
      <template v-if="!state.isGenerationConfirmed">
        <Button button-type="light" @click.prevent="close">{{ $t("Review the list") }}</Button>
        <Button :processing="state.processing" @click.prevent="onAccept">{{ $t("Continue") }}</Button>
      </template>
      <Button v-else button-type="light" @click.prevent="close">{{ $t("Ok") }}</Button>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed } from "vue";

import Button from "@/components/buttons/Button.vue";
import Icon from "@/components/icons/Icon.vue";
import ErrorBanner from "../banners/ErrorBanner.vue";

import useModalStore from "@/store/modal";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
// import { ROUTE_NAMES } from "@/router";
// import { useRouter } from "vue-router";
import { BRAND } from "@/config";

const emit = defineEmits(["close"]);

const modalStore = useModalStore();
// const router = useRouter();

const state = reactive({
  processing: false,
  errors: null,

  isGenerationConfirmed: false,
});

const brandName = computed(() => process.env.VUE_APP_BRAND || BRAND);

function close() {
  emit("close");
}

async function onAccept() {
  if (state.processing) {
    return;
  }

  const { payload } = modalStore;

  if (payload && payload.callback) {
    state.processing = true;
    state.errors = null;

    try {
      await payload.callback();
    } catch (xhrError) {
      const { errors, errorMessage } = prepareAxiosErrors(xhrError);
      state.errors = {
        errorMessage,
        ...errors,
      };
    }

    state.processing = false;

    if (!state.errors) {
      state.isGenerationConfirmed = true;
    }
  }
}

//Use when we will have a possibility to redirect to the invoices tab right after the generate invoice button pressed:
// function openInvoiceListView() {
//   router.push({ name: ROUTE_NAMES.invoices.index, query: { company: modalStore.payload.company } });
//   close();
// }
</script>
