import { createApp } from "vue";
import { createPinia } from "pinia";

import { initSentry } from "@/helpers/dev/sentry";
import showDeployInfo from "@/helpers/dev/showDeployInfo";

import App from "./App.vue";
import router from "./router";
import capitalizeDirective from "./directives/capitalize";
import clickOutsideDirective from "./directives/clickOutside";
import hideTextcontentDirective from "./directives/hideTextContent";

import i18n from "@/i18n";

import "@vuepic/vue-datepicker/dist/main.css";
import "@/assets/styles/style.scss";

const piniaStore = createPinia();

const app = createApp(App);

initSentry(app, router);
showDeployInfo();

app
  .use(i18n)
  .use(piniaStore)
  .use(router)
  .directive(capitalizeDirective.name, capitalizeDirective)
  .directive(clickOutsideDirective.name, clickOutsideDirective)
  .directive(hideTextcontentDirective.name, hideTextcontentDirective)
  .mount("#app");
