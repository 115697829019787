const capitalize = (el) => {
  const text = el.innerText;

  if (text) {
    const newText = text.charAt(0).toUpperCase() + text.slice(1);
    el.innerText = newText;
  }
};

export default {
  name: "capitalize",
  mounted: capitalize,
  updated: capitalize,
};
