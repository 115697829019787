<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <g clip-path="url(#a)">
      <path d="M2 21V3a1 1 0 0 1 1-1h9V0H3a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h9v-2H3a1 1 0 0 1-1-1Z" />
      <path
        d="m20.723 9.879-4.586-4.586-1.414 1.414 4.264 4.264L6 11v1.971h13.043l-4.322 4.322 1.414 1.414 4.586-4.586c.563-.562 1.479-1.325 1.48-2.121 0-.796-.916-1.558-1.478-2.121Z"
      />
    </g>
    <defs>
      <clipPath id="a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath>
    </defs>
  </svg>
</template>
