<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      d="M10.6667 15.3333C10.6667 15.7013 10.3687 16 9.99999 16H3.99999C2.16199 16 0.666656 14.5047 0.666656 12.6667V3.33333C0.666656 1.49533 2.16199 0 3.99999 0H7.00999C8.25599 0 9.42799 0.485333 10.31 1.36667L12.6327 3.69067C12.8133 3.87133 12.9813 4.06933 13.1313 4.27933C13.3453 4.57867 13.276 4.99533 12.9767 5.20933C12.6773 5.42267 12.26 5.354 12.0467 5.054C11.9393 4.904 11.8193 4.762 11.6907 4.634L9.36732 2.31C9.15532 2.098 8.91999 1.91867 8.66666 1.77333V4.66667C8.66666 5.034 8.96532 5.33333 9.33332 5.33333H11.3333C11.702 5.33333 12 5.632 12 6C12 6.368 11.702 6.66667 11.3333 6.66667H9.33332C8.23066 6.66667 7.33332 5.76933 7.33332 4.66667V1.34867C7.22666 1.33867 7.11866 1.33333 7.00999 1.33333H3.99999C2.89732 1.33333 1.99999 2.23067 1.99999 3.33333V12.6667C1.99999 13.7693 2.89732 14.6667 3.99999 14.6667H9.99999C10.3687 14.6667 10.6667 14.9653 10.6667 15.3333ZM4.99999 10H8.66666C9.03532 10 9.33332 9.70133 9.33332 9.33333C9.33332 8.96533 9.03532 8.66667 8.66666 8.66667H4.99999C4.08132 8.66667 3.33332 9.41467 3.33332 10.3333V11.6667C3.33332 12.5853 4.08132 13.3333 4.99999 13.3333H8.66666C9.03532 13.3333 9.33332 13.0347 9.33332 12.6667C9.33332 12.2987 9.03532 12 8.66666 12H4.99999C4.81599 12 4.66666 11.8507 4.66666 11.6667V10.3333C4.66666 10.1493 4.81599 10 4.99999 10ZM3.99999 7.33333H5.33332C5.70132 7.33333 5.99999 7.03467 5.99999 6.66667C5.99999 6.29867 5.70132 6 5.33332 6H3.99999C3.63199 6 3.33332 6.29867 3.33332 6.66667C3.33332 7.03467 3.63199 7.33333 3.99999 7.33333ZM3.99999 4.66667H5.33332C5.70132 4.66667 5.99999 4.368 5.99999 4C5.99999 3.632 5.70132 3.33333 5.33332 3.33333H3.99999C3.63199 3.33333 3.33332 3.632 3.33332 4C3.33332 4.368 3.63199 4.66667 3.99999 4.66667Z"
    />
    <path
      d="M15.6997 8.34394L13.6731 13.7262C13.5321 14.109 13.2452 14.4205 12.8753 14.5925C12.5054 14.7645 12.0824 14.7832 11.6988 14.6443C11.3144 14.5045 11.0011 14.2179 10.8278 13.8474C10.6544 13.4769 10.6352 13.0528 10.7742 12.6681L12.8117 7.25809C12.9188 6.9732 13.1079 6.72635 13.355 6.54863C13.6021 6.3709 13.8963 6.27024 14.2005 6.25933C14.5047 6.24841 14.8053 6.32773 15.0645 6.48729C15.3237 6.64685 15.5298 6.87952 15.657 7.156C15.7431 7.34132 15.7911 7.54199 15.7984 7.7462C15.8057 7.9504 15.772 8.154 15.6993 8.34499L15.6997 8.34394ZM13.805 10.0286L13.1214 9.78208L11.8805 13.0767C11.8496 13.1667 11.8554 13.2654 11.8967 13.3512C11.938 13.437 12.0114 13.5031 12.1011 13.5351C12.1917 13.5659 12.2907 13.5604 12.3774 13.5198C12.4641 13.4793 12.5318 13.4068 12.5662 13.3175L13.805 10.0286ZM14.5859 7.64848C14.5559 7.58342 14.5074 7.52868 14.4464 7.49114C14.3854 7.4536 14.3146 7.43494 14.243 7.4375C14.1714 7.44006 14.1021 7.46373 14.044 7.50553C13.9858 7.54733 13.9412 7.6054 13.916 7.67243L13.5374 8.67727L14.2206 8.92486L14.5953 7.92837C14.6122 7.88355 14.6201 7.83583 14.6184 7.78796C14.6167 7.74009 14.6055 7.69303 14.5855 7.64952L14.5859 7.64848Z"
    />
  </svg>
</template>
