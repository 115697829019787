<template>
  <label class="base-checkbox">
    <input type="checkbox" class="base-checkbox_input" :checked="props.value" @change="onChange" />
    <span class="base-checkbox_fake-input"></span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  emits: ["input", "change"],

  setup(props, { emit }) {
    function onChange(event) {
      emit("input", event.target.checked);
      emit("change", event.target.checked);
    }

    return {
      props,

      onChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.base-checkbox {
  display: inline-flex;
  align-items: center;

  &_input {
    display: none;

    &:checked {
      & + .base-checkbox_fake-input {
        &::before {
          border-color: #346aed;
        }

        &::after {
          display: block;
        }
      }
    }
  }

  &_fake-input {
    position: relative;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      border: 2px solid #000000;
      border-radius: 4px;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik00LjIyMjIgNi42MTI2NkwxLjYwOTU0IDRDMS40ODQ1MiAzLjg3NTAyIDEuMzE0OTggMy44MDQ4MSAxLjEzODIgMy44MDQ4MUMwLjk2MTQyOCAzLjgwNDgxIDAuNzkxODg5IDMuODc1MDIgMC42NjY4NzEgNEMwLjU0MTg5IDQuMTI1MDIgMC40NzE2OCA0LjI5NDU2IDAuNDcxNjggNC40NzEzM0MwLjQ3MTY4IDQuNjQ4MTEgMC41NDE4OSA0LjgxNzY1IDAuNjY2ODcxIDQuOTQyNjZMMy4yNzk1NCA3LjU1NTMzQzMuNDAzMzYgNy42NzkyIDMuNTUwMzcgNy43Nzc0NiAzLjcxMjE3IDcuODQ0NUMzLjg3Mzk3IDcuOTExNTQgNC4wNDc0IDcuOTQ2MDUgNC4yMjI1NCA3Ljk0NjA1QzQuMzk3NjggNy45NDYwNSA0LjU3MTEgNy45MTE1NCA0LjczMjkxIDcuODQ0NUM0Ljg5NDcxIDcuNzc3NDYgNS4wNDE3MiA3LjY3OTIgNS4xNjU1NCA3LjU1NTMzTDExLjMzMzUgMS4zODczM0MxMS40NTg1IDEuMjYyMzEgMTEuNTI4NyAxLjA5Mjc3IDExLjUyODcgMC45MTU5OThDMTEuNTI4NyAwLjczOTIyMiAxMS40NTg1IDAuNTY5NjgzIDExLjMzMzUgMC40NDQ2NjRDMTEuMjA4NSAwLjMxOTY4NCAxMS4wMzkgMC4yNDk0NzQgMTAuODYyMiAwLjI0OTQ3NEMxMC42ODU0IDAuMjQ5NDc0IDEwLjUxNTkgMC4zMTk2ODQgMTAuMzkwOSAwLjQ0NDY2NEw0LjIyMjIgNi42MTI2NloiIGZpbGw9IiMzNDZBRUQiLz4KPC9zdmc+Cg==");
      background-repeat: no-repeat;
      background-size: 75%;
      background-position: center;
      display: none;
    }

    &:hover {
      &::before {
        border-color: #346aed;
      }
    }
  }
}
</style>
