<template>
  <div class="language-switcher">
    <button class="language-switcher_button" @click="toggleState">
      <Icon icon="language" fill="#000" />
      <span>{{ currentLanguage }}</span>
      <Icon :icon="arrowIconType" fill="#000" />
    </button>
    <ul class="language-switcher_list" :class="{ opened: state.isOpened }">
      <li v-for="(language, locale) in languages" :key="`language-switcher-${locale}`">
        <a href="#" @click.prevent="changeLanguage(locale)">{{ language }} ({{ locale }})</a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, reactive } from "vue";

import Icon from "@/components/icons/Icon.vue";

import useUserStore, { ACTION_UPDATE_LANGUAGE } from "@/store/user";
import useClickOutside from "@/composables/useClickOutside";
import { LOCALES } from "@/config";

const storeUser = useUserStore();

const { addOutsideClickHandler, removeOutsideClickHandler } = useClickOutside();

const state = reactive({
  isOpened: false,
});

const arrowIconType = computed(() => (state.isOpened ? "arrow-up" : "arrow-down"));
const currentLanguage = computed(() => storeUser.language.toUpperCase());
const languages = computed(() => {
  const supportedLocalesList = LOCALES.SUPPORTED_LOCALES.split(",");
  const languages = supportedLocalesList.reduce(
    (languages, locale) => Object.assign(languages, { [locale.toUpperCase()]: LOCALES.LANGUGAGES[locale] }),
    {}
  );
  return languages;
});

function toggleState(e) {
  state.isOpened = !state.isOpened;

  if (state.isOpened) {
    addOutsideClickHandler(close, e);
  } else {
    removeOutsideClickHandler(close);
  }
}

function close() {
  state.isOpened = false;
}

function changeLanguage(newLanguage) {
  close();
  storeUser[ACTION_UPDATE_LANGUAGE](newLanguage);
}
</script>

<style lang="scss" scoped>
.language-switcher {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &_button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    appearance: none;
    cursor: pointer;
    padding: 0 8px;

    span {
      margin: 0 0.4em;
      font-weight: 500;
      font-size: 16px;
    }
  }

  &_list {
    list-style-type: none;
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    display: none;

    &.opened {
      display: block;
      z-index: 9;
    }

    li {
      white-space: nowrap;
      text-align: right;
      padding: 0.3em 8px;

      a {
        font-size: 16px;
        font-weight: 400;
        color: #000;

        &:hover {
          color: #666;
        }
      }
    }
  }
}
</style>
