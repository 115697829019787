<template>
  <div class="modal_container">
    <div class="modal_row header">
      <h3>{{ $t("Error") }}</h3>
      <a href="#" class="modal_close" @click.prevent="close">
        <Icon icon="modal-close" fill="#000" />
      </a>
    </div>
    <p>{{ $t("Sorry, the functionality is not available at the moment.") }}</p>
    <div class="modal_row buttons">
      <Button @click.prevent="close">{{ $t("Close") }}</Button>
    </div>
  </div>
</template>

<script setup>
import Button from "@/components/buttons/Button.vue";
import Icon from "@/components/icons/Icon.vue";

const emit = defineEmits(["close"]);

function close() {
  emit("close");
}
</script>
