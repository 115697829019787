<template>
  <div class="modal_container">
    <div class="modal_row header">
      <h3>{{ $t("User guide") }}</h3>
      <a href="#" class="modal_close" @click.prevent="close">
        <Icon icon="modal-close" fill="#000" />
      </a>
    </div>
    <p class="paragraph_medium">{{ $t("Active buttons") }}</p>
    <p class="paragraph_light">{{ $t("When you click, an action occurs") }}</p>
    <div class="frame">
      <MonthPicker v-model="selectedDate" :label="$t('Period')" />
      <p>{{ $t("Select period") }}</p>
    </div>
    <div class="frame">
      <Button button-type="light">{{ $t("Download previous") }}</Button>
      <p>{{ $t("Download data from the previous period or a template") }}</p>
    </div>
    <div class="frame">
      <Button button-type="light">{{ $t("Import") }}</Button>
      <p>{{ $t("Import employees") }}</p>
    </div>
    <div class="frame">
      <Button icon="plus_white">{{ $t("Add") }}</Button>
      <p>{{ $t("Add employee") }}</p>
    </div>
    <label class="instruction_accept" for="instruction_accept">
      <input id="instruction_accept" v-model="instructionAccepted" type="checkbox" />
      {{ $t("Do not show guide") }}
    </label>
    <div class="modal_row buttons">
      <Button @click.prevent="close">{{ $t("Close") }}</Button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import Button from "@/components/buttons/Button.vue";
import Icon from "@/components/icons/Icon.vue";
import MonthPicker from "@/components/inputs/datepicker/MonthPicker.vue";

import useStorage from "@/helpers/storage";
import { STORAGE_KEYS } from "@/config";

const emit = defineEmits(["close"]);

const storage = useStorage();

const instructionAccepted = ref(false);

const selectedDate = ref("2403");

function close() {
  if (instructionAccepted.value) {
    storage.save(true, STORAGE_KEYS.instructionsAccepted);
  }

  emit("close");
}
</script>

<style lang="scss" scoped>
.modal_container {
  width: 580px;
}

p {
  font-weight: 400;
  line-height: 1.5em;

  &.paragraph_light {
    font-weight: 500;
  }

  &.paragraph_light {
    font-weight: 300;
    font-size: 16px;
  }
}

.frame {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    width: 45%;
    font-size: 13px;
  }
}

.instruction_accept {
  font-weight: 300;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 1px solid #000000;
    border-radius: 3px;
    margin-right: 1em;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 15%;
      left: 15%;
      width: 70%;
      height: 70%;
      background: #000000;
      border-radius: 3px;
      display: none;
    }

    &:checked {
      &:before {
        display: block;
      }
    }
  }
}
</style>
