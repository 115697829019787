import { isProxy } from "vue";

export default function useClearInputErrors(errorsState, fields = {}) {
  if (!isProxy(errorsState)) {
    const warnMsg = `ErrorsState must be reactive object when passing passed into "useClearInputErrors" composable`;
    console.warn(warnMsg);
    return () => {};
  }

  const fieldsList = Object.keys(fields);

  if (fieldsList.length === 0) {
    const warnMsg = `Input fields must be passed into "useClearInputErrors" composable as second argument`;
    console.warn(warnMsg);
    return () => {};
  }

  return (newState, oldState) => {
    fieldsList.forEach((key) => {
      if (newState[key] !== oldState[key]) {
        const errorKey = fields[key];
        errorsState[errorKey] = null;
      }
    });
  };
}
