<template>
  <ModalContainer v-if="modalStore.isOpen" @close="close">
    <component :is="currentComponent" @close="close" />
  </ModalContainer>
</template>

<script>
const MODAL_COMPONENTS = {
  [MODAL_TYPES.action]: ActionModal,
  [MODAL_TYPES.employeeEdit]: SubscriptionEditModal,
  [MODAL_TYPES.employeesImport]: SubscriptionsImportModal,
  [MODAL_TYPES.error]: ErrorModal,
  [MODAL_TYPES.instructions]: InstructionsModal,
  [MODAL_TYPES.invoice]: GenerateInvoiceModal,
  [MODAL_TYPES.approveChanges]: ApproveSubscriberChangesModal,
};
</script>

<script setup>
import { computed } from "vue";

import ActionModal from "./ActionModal.vue";
import ErrorModal from "./ErrorModal.vue";
import InstructionsModal from "./InstructionsModal.vue";
import GenerateInvoiceModal from "./GenerateInvoiceModal.vue";
import ModalContainer from "./ModalContainer.vue";
import SubscriptionEditModal from "./SubscriptionEditModal.vue";
import SubscriptionsImportModal from "./SubscriptionsImportModal.vue";

import useModalStore, { MODAL_TYPES } from "@/store/modal";
import ApproveSubscriberChangesModal from "./ApproveSubscriberChangesModal.vue";

const modalStore = useModalStore();

const currentComponent = computed(() => MODAL_COMPONENTS[modalStore.type]);

function close() {
  modalStore.closeModal();
}
</script>

<style lang="scss">
.modal_container {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 540px;
  max-height: 100vh;
  overflow: auto;

  .error_banner {
    margin-bottom: 12px;
  }

  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 1.5em;
  }

  .modal_row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.header {
      margin-bottom: 30px;
    }

    &.buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
    }

    &_import-info {
      display: flex;
      justify-content: space-between;
    }

    .col-flex {
      display: flex;
      align-items: center;
    }

    .col-40 {
      width: 40%;
    }

    .col-50 {
      width: 50%;
    }

    &.file_upload {
      width: 100%;
      height: 146px;
      border: 1px dashed rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      justify-content: center;
      flex-direction: column;
      position: relative;
      cursor: pointer;
      margin-top: 24px;

      &:hover {
        label {
          a {
            text-decoration: none;
          }
        }
      }

      p,
      label {
        margin-top: 1em;
        font-size: 13px;
        font-weight: 400;

        a {
          font-weight: 50;
          color: #346aed;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      span {
        color: #888;
      }

      p {
        color: #000;
        font-weight: 500;

        a {
          color: #346aed;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
    }

    p {
      color: #000;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.5em;

      a {
        color: #346aed;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .btn + .btn {
      margin-left: 20px;
    }

    .modal_close {
      line-height: 0;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
