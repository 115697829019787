import { defineStore } from "pinia";

import useStore, { ACTION_DELETE_SUBSCRIPTION } from "@/store";
import router from "@/router";

export const STORE_NAMESPACE = "modal";

export const ACTION_CLOSE_MODAL = "closeModal";
export const ACTION_OPEN_MODAL = "openModal";

export const MODAL_TYPES = {
  action: "action",
  employeeEdit: "employeeEdit",
  employeesImport: "employeesImport",
  error: "error",
  instructions: "instructions",
  invoice: "invoice",
  approveChanges: "approveChanges",
};

export default defineStore(STORE_NAMESPACE, {
  state: () => {
    return {
      isOpen: false,
      type: null,
      mode: null,
      flow: null,
      payload: null,
    };
  },
  actions: {
    [ACTION_CLOSE_MODAL]() {
      this.$reset();
    },
    [ACTION_OPEN_MODAL]({ type, mode, flow }, payload = null) {
      this.type = type;
      this.mode = mode;
      this.flow = flow || null;
      this.isOpen = true;
      this.payload = payload ? Object.assign(payload) : payload;

      if (
        type === MODAL_TYPES.action &&
        mode === "delete" &&
        !payload.callback
      ) {
        const company = router?.currentRoute?.value?.query?.company;

        if (this.payload && company) {
          const { id } = this.payload;
          const store = useStore();

          this.payload.callback = store[ACTION_DELETE_SUBSCRIPTION].bind(null, {
            company,
            subscriptionId: id,
          });
        }
      }
    },
  },
});
