import { captureSentryException } from "@/helpers/dev/sentry";
import useUserStore, { ACTION_LOGOUT } from "@/store/user";

const LOGOUT_STATUSES = [401];

export function defaultErrorInterceptor(error) {
  captureSentryException(error);
  return Promise.reject(error);
}

export function defaultSuccessInterceptor(obj) {
  return obj;
}

export function responseErrorInterceptor(error) {
  captureSentryException(error);

  if (LOGOUT_STATUSES.includes(error?.response?.status)) {
    const storeUser = useUserStore();

    storeUser[ACTION_LOGOUT]();
  }
  return Promise.reject(error);
}

export function responseSuccessInterceptor(response) {
  const { config } = response;

  if (config.returnResponse) {
    return response;
  }

  return response.data.data || response.data;
}

export function requestSuccessInterceptor(config) {
  const storeUser = useUserStore();
  const token = storeUser.token;
  const language = storeUser.language;

  config.headers["Accept-Language"] = language;
  config.headers["X-localization"] = language;

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
}
