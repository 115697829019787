<template>
  <svg width="18" height="18" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_91)">
      <path
        d="M210.73 386.603C216.674 392.55 223.731 397.268 231.499 400.487C239.266 403.706 247.592 405.362 256 405.362C264.408 405.362 272.733 403.706 280.501 400.487C288.269 397.268 295.326 392.55 301.269 386.603L369.77 318.101C373.443 314.039 375.413 308.72 375.273 303.246C375.132 297.772 372.892 292.561 369.015 288.693C365.139 284.825 359.923 282.595 354.449 282.466C348.974 282.337 343.659 284.319 339.605 288L277.184 350.443L277.333 21.3333C277.333 15.6754 275.085 10.2492 271.085 6.24839C267.084 2.24761 261.658 0 256 0V0C250.342 0 244.916 2.24761 240.915 6.24839C236.914 10.2492 234.666 15.6754 234.666 21.3333L234.474 350.037L172.394 288C168.391 284 162.963 281.754 157.304 281.756C151.645 281.758 146.219 284.008 142.218 288.011C138.218 292.014 135.972 297.442 135.974 303.101C135.976 308.76 138.226 314.186 142.229 318.187L210.73 386.603Z"
      />
      <path
        d="M490.667 341.333C485.009 341.333 479.582 343.581 475.582 347.582C471.581 351.583 469.333 357.009 469.333 362.667V448C469.333 453.658 467.086 459.084 463.085 463.085C459.084 467.086 453.658 469.333 448 469.333H64C58.342 469.333 52.9158 467.086 48.9151 463.085C44.9143 459.084 42.6667 453.658 42.6667 448V362.667C42.6667 357.009 40.4191 351.583 36.4183 347.582C32.4175 343.581 26.9913 341.333 21.3333 341.333C15.6754 341.333 10.2492 343.581 6.24839 347.582C2.24761 351.583 0 357.009 0 362.667L0 448C0 464.974 6.74284 481.253 18.7452 493.255C30.7475 505.257 47.0261 512 64 512H448C464.974 512 481.253 505.257 493.255 493.255C505.257 481.253 512 464.974 512 448V362.667C512 357.009 509.752 351.583 505.752 347.582C501.751 343.581 496.325 341.333 490.667 341.333Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_91">
        <rect width="512" height="512" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
