import { STORAGE_KEYS, LOCALES } from "@/config";
import useStorage from "@/helpers/storage";

const storage = useStorage();

export default () => {
  const language = storage.get(STORAGE_KEYS.language);

  if (language) {
    return language;
  }

  const navigatorLanguage = navigator.language.split("-")[0].toLowerCase();
  const supportedLocalesList = LOCALES.SUPPORTED_LOCALES.split(",");

  if (supportedLocalesList.includes(navigatorLanguage)) {
    return navigatorLanguage;
  }

  return LOCALES.LOCALE;
};
