<template>
  <div class="table">
    <div v-if="props.processing" class="table_spinner">
      <Spinner />
    </div>
    <table v-else>
      <thead v-if="showTableHead">
        <tr>
          <td>
            <BaseCheckbox v-if="props.showCheckbox" :value="areAllItemsChecked" @change="onAllCheckboxChange" />
          </td>
          <td
            v-for="(column, i) in props.columns"
            :key="`table_${instance.uid}_header_${i}`"
            v-bind="getHeadCellAttributes(column)"
          >
            <component :is="column.sortable ? 'a' : 'span'" @click.prevent="updateSorting(column)">
              {{ $t(column.header) }}
              <IconSort
                v-if="column.sortable"
                class="sort_direction"
                :active="isSortIconActive(column.key)"
                :direction="getSortIconDirection(column.key)"
              />
            </component>
          </td>
        </tr>
      </thead>
      <tbody v-if="showTableBody">
        <tr
          v-for="item in sortedItemsList"
          :key="`table_${instance.uid}_body_row_${item.id}`"
          :class="getRowClass(item.id, item.status, item.copayment_status)"
        >
          <td>
            <BaseCheckbox
              v-if="props.showCheckbox && isCheckableItem(item.status)"
              :value="isIdInCheckedItems(item.id)"
              @change="onCheckboxChange($event, item.id)"
            />
            <Icon
              v-if="props.attentionItems.includes(item.status) || props.warningItems.includes(item.copayment_status)"
              icon="attention-round2"
            />
            <Icon v-if="props.approvedItems.includes(item.status)" icon="approved" />
          </td>
          <td
            v-for="column in props.columns"
            :key="`table_${instance.uid}_body_row_${item.id}_${column.key}`"
            :title="column.key === 'holder' ? getCellValue(column, item) : ''"
          >
            {{ getCellValue(column, item) }}
          </td>
          <td v-if="showActionsButton" class="actions">
            <ActionsButtonV2
              v-if="props.customizableActions"
              :actions="props.customizeActions(item)"
              @action="onAction($event, item.id)"
              @actions:show="onActionsShow(item.id)"
              @actions:close="onActionsClose(item.id)"
            />
            <ActionsButtonV2
              v-else
              :actions="props.actions"
              @action="onAction($event, item.id)"
              @actions:show="onActionsShow(item.id)"
              @actions:close="onActionsClose(item.id)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, reactive, watchEffect } from "vue";
import isFunction from "lodash/isFunction";

import ActionsButtonV2, { ACTIONS } from "@/components/buttons/ActionsButtonV2.vue";
import BaseCheckbox from "../inputs/BaseCheckbox.vue";
import IconSort from "@/components/icons/IconSort.vue";
import Icon from "@/components/icons/Icon.vue";
import Spinner from "@/components/loaders/Spinner.vue";

import { hideTextContent } from "@/helpers/text/hideTextContent";
import { clone } from "lodash";

const instance = getCurrentInstance();

const emit = defineEmits([...Object.values(ACTIONS), "checkbox-change"]);

const props = defineProps({
  actions: {
    type: Array,
    default: () => [],
  },
  checkableStatuses: {
    type: Array,
    default: () => [],
  },
  checkedItemsList: {
    type: Array,
    default: () => [],
  },
  columns: {
    type: Array,
    required: true,
  },
  country: {
    type: String,
    required: true,
  },
  clickableRows: {
    type: Boolean,
    default: false,
  },
  customizableActions: {
    type: Boolean,
    default: false,
  },
  customizeActions: {
    type: Function,
    default: () => undefined,
  },
  items: {
    type: Array,
    required: true,
  },
  processing: {
    type: Boolean,
    default: false,
  },
  showCheckbox: {
    type: Boolean,
    default: false,
  },
  approvedItems: {
    type: Array,
    default: () => [],
  },
  attentionItems: {
    type: Array,
    default: () => [],
  },
  warningItems: {
    type: Array,
    default: () => [],
  },
});

const checkedItems = computed(() => props.checkedItemsList);

const areAllItemsChecked = computed(
  () =>
    props.checkedItemsList.length &&
    props.checkedItemsList.length === props.items.filter((item) => props.checkableStatuses.includes(item.status)).length
);

const state = reactive({
  openedActions: null,

  sortDirection: "asc",
  sortField: null,
});

const tableHeaders = computed(() => props.columns.map(({ header }) => header));

const showTableHead = computed(() => tableHeaders.value.filter(Boolean).length > 0);
const showTableBody = computed(() => props.items.length > 0);
const showActionsButton = computed(() => props.actions?.length > 0);

const sortedItemsList = computed(() => {
  const { sortDirection, sortField } = state;
  const isSortApplicable = sortDirection && sortField && props.items.length > 0;

  if (isSortApplicable) {
    const sortedList = Array.from(props.items);

    sortedList.sort((a, b) => {
      let valA = a[sortField].toString();
      let valB = b[sortField].toString();

      if (sortDirection === "asc") {
        return valA.localeCompare(valB);
      } else {
        return valB.localeCompare(valA);
      }
    });

    return sortedList;
  }

  return props.items;
});

function isSortIconActive(key) {
  return key === state.sortField;
}

function getSortIconDirection(key) {
  return isSortIconActive(key) ? state.sortDirection : "asc";
}

function getHeadCellAttributes({ width } = {}) {
  const defaultAttrs = {
    width: "auto",
  };

  const attrs = Object.assign({}, defaultAttrs, {
    width,
  });

  return attrs;
}

function getRowClass(itemId, status, copayment_status) {
  return {
    "update-request": props.attentionItems.includes(status),
    unpaid: props.warningItems.includes(copayment_status),
    approved: props.approvedItems.includes(status),
    "row-checked": isIdInCheckedItems(itemId),
    "actions-show": state.openedActions === itemId,
  };
}

function getCellValue(column, item) {
  const { key, value, hidden } = column;

  if (value) {
    if (isFunction(value)) {
      return value(item);
    }

    return hidden ? hideTextContent(item[value], key, props.country) : item[value] ?? "—";
  }

  return hidden ? hideTextContent(item[key], key, props.country) : item[key] ?? "—";
}

function resetSorting() {
  state.sortDirection = "asc";
  state.sortField = null;
}

function updateSorting({ key, sortable } = {}) {
  if (!sortable) {
    return;
  }

  if (key !== state.sortField) {
    state.sortDirection = "asc";
  } else {
    state.sortDirection = state.sortDirection === "asc" ? "desc" : "asc";
  }

  state.sortField = key;
}

function onAction(action, itemId) {
  emit(action, itemId);
}

function onActionsShow(itemId) {
  state.openedActions = itemId;
}

function onActionsClose(itemId) {
  if (state.openedActions === itemId) {
    state.openedActions = null;
  }
}

function isIdInCheckedItems(itemId) {
  return checkedItems.value.includes(itemId);
}

function onCheckboxChange(newValue, itemId) {
  let newCheckedItemsList = clone(checkedItems.value);
  newCheckedItemsList = newCheckedItemsList.filter((item) => item !== itemId);

  if (newValue) {
    newCheckedItemsList.push(itemId);
  }

  emit("checkbox-change", newCheckedItemsList);
}

function onAllCheckboxChange(newValue) {
  let newCheckedItemsList = [];

  if (newValue) {
    props.items.forEach((item) => {
      if (isCheckableItem(item.status)) {
        newCheckedItemsList.push(item.id);
      }
    });
  }

  emit("checkbox-change", newCheckedItemsList);
}

function isCheckableItem(status) {
  return props.checkableStatuses.includes(status);
}

watchEffect(() => resetSorting(props.processing));
</script>

<style lang="scss" scoped>
.table {
  width: 100%;

  .table_spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    thead {
      font-weight: 400;
      font-size: 13px;
      color: #9c9c9c;

      td {
        padding: 0.5em 10px;
        user-select: none;

        &:first-child {
          min-width: 20px;
          padding: 0.5em 0px 0.5em 24px;
        }

        a {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    tbody {
      tr {
        &.approved {
          td {
            opacity: 0.5;
          }
        }

        &:hover,
        &.actions-show {
          td {
            opacity: 1;
            background-color: #f0f0f0;
          }
        }

        &.update-request {
          td {
            background-color: #ffeec7;
          }
        }

        &.unpaid {
          td {
            background-color: #ffecec;
          }
        }

        &.row-checked {
          td {
            opacity: 1;
            background-color: #f0f0f0;
          }
        }

        td {
          padding: 1em 10px;
          font-weight: 400;
          font-size: 16px;
          border-bottom: 1px solid #9c9c9c;

          &:first-child {
            width: 20px;
            padding: 0.5em 0px 0.5em 24px;

            & + td {
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
            }
          }

          &.actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
</style>
