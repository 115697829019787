import { computed } from "vue";

import useUserStore from "@/store/user";

import ROLES from "@/config/roles";

export default function useUserRole() {
  const userStore = useUserStore();

  const USER_ROLE = computed(() => ({
    manager: userStore.position === ROLES.manager,
  }));

  return {
    USER_ROLE,
  };
}
