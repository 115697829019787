<template>
  <div class="modal_background" @click.self="emit('close')">
    <slot></slot>
  </div>
</template>

<script setup>
const emit = defineEmits(["close"]);
</script>

<style lang="scss" scoped>
.modal_background {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
