import isArray from "lodash/isArray";

const DEFAULT_CONFIG = {
  flatResult: false,
};

export default function prepareAxiosErrors(xhrError, userConfig = {}) {
  const responseData = xhrError.response?.data;
  const config = Object.assign({}, DEFAULT_CONFIG, userConfig);

  if (!responseData || !(responseData.message || responseData.messages)) {
    return { errorMessage: xhrError.toString() };
  }

  const { message, messages, errors, ...extra } = responseData;

  const errorData = {
    errorMessage: message || messages,
    errors: {},
    extra,
  };

  if (errors) {
    Object.keys(errors).forEach((key) => {
      errorData.errors[key] = isArray(errors[key])
        ? errors[key][0]
        : errors[key];
    });
  }

  if (config.flatResult) {
    const { errorMessage, errors, extra } = errorData;

    return {
      ...errors,
      errorMessage,
      extra,
    };
  }

  return errorData;
}
