<template>
  <LayoutView :error-message="state.errors.errorMessage">
    <Banner
      v-if="state.actNotReadyAfterInvoicing"
      :message="$t('The act will be available at the end of the month.')"
      type="warning"
    />
    <div v-else class="content">
      <Table
        class="table"
        :actions="TABLE_ACTIONS"
        :columns="TABLE_COLUMNS"
        :items="state.actsList"
        :processing="state.processing"
        @download="onDownload"
        @preview="onPreview"
      />
    </div>
  </LayoutView>
</template>

<script>
import { computed, reactive, watchEffect, unref } from "vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";

import Banner from "@/components/banners/Banner.vue";
import LayoutView from "@/layouts/LayoutView.vue";
import Table from "@/components/tables/Table.vue";

import { ACTIONS } from "@/components/buttons/ActionsButtonV2.vue";
import { ROUTE_NAMES } from "@/router";
import { t } from "@/helpers/i18n/stubs";
import { URLS } from "@/config";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import request, { download } from "@/helpers/request";
import useStore from "@/store";
import { priceFormatter } from "@/helpers/formatters";

const DATA_RECEIVING_ERROR = t("Data receiving error");

const TABLE_COLUMNS = [
  {
    header: t("Act id"),
    key: "id",
    sortable: true,
  },
  {
    header: t("Order id"),
    key: "order_id",
  },
  {
    header: t("Amount"),
    key: "amount",
    sortable: true,
  },
  {
    header: t("Total"),
    key: "total",
    value: ({ total }) => {
      return priceFormatter(total);
    },
  },
];

const TABLE_ACTIONS = [
  {
    title: t("Download"),
    key: ACTIONS.download,
  },
  {
    title: t("Preview"),
    key: ACTIONS.preview,
  },
];

export default {
  components: {
    Banner,
    LayoutView,
    Table,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const state = reactive({
      processing: false,
      errors: {},

      actsList: [],
      actNotReadyAfterInvoicing: false,
    });

    const company = computed(() => route.query.company);

    async function fetchActsList() {
      state.processing = true;
      state.errors = {};
      state.actsList = [];
      state.actNotReadyAfterInvoicing = false;

      const url = URLS.API.acts.list(unref(company), store.selectedDate);

      try {
        const result = await request(url);

        if (result.act_not_ready_after_invoicing) {
          state.actNotReadyAfterInvoicing = true;
        } else {
          state.actsList = result;
        }
      } catch (xhrError) {
        state.errors = {
          errorMessage: DATA_RECEIVING_ERROR,
        };
      }

      state.processing = false;
    }

    async function onDownload(itemId) {
      state.errors = {};

      const url = URLS.API.acts.download(itemId);

      try {
        await download(url);
      } catch (xhrError) {
        state.errors = prepareAxiosErrors(xhrError);
      }
    }

    function onPreview(actId) {
      router.push({
        name: ROUTE_NAMES.acts.preview,
        params: { actId },
        query: route.query,
      });
    }

    const stopSelectedDateWatcher = watchEffect(() => fetchActsList(store.selectedDate));

    onBeforeRouteLeave(() => {
      stopSelectedDateWatcher();
    });

    return {
      TABLE_ACTIONS,
      TABLE_COLUMNS,

      state,

      onDownload,
      onPreview,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 14px;
  display: grid;
  grid-template-columns: 7fr 2fr;
}
</style>
