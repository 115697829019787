<template>
  <div class="view">
    <h2>{{ importStore.filename }}</h2>

    <ErrorBanner
      :message="$t('The file contains errors. Please check and fix the errors and then save the changes.')"
    />

    <div class="controls">
      <MonthInput :model-value="selectedDate" label="Period" disabled />
      <Button
        :disabled="!isSaveButtonEnabled"
        :processing="state.processing"
        button-type="success"
        @click="saveChanges"
      >
        {{ $t("Save changes") }}
      </Button>
      <Button icon="plus_white" @click="openModal(MODAL_TYPES.employeeEdit, 'add')">{{ $t("Add") }}</Button>
    </div>
    <div class="content">
      <div class="subscriptions">
        <div class="subscriptions_controls">
          <span class="name">
            <a href="#" @click="updateSorting('name')">
              {{ $t("Name") }}
              <IconSort
                class="sort_direction"
                :direction="getSortIconDirection('name')"
                :active="isSortIconActive('name')"
              />
            </a>
          </span>
          <span class="level">
            <a href="#" @click="updateSorting('level')">
              {{ $t("Subscription") }}
              <IconSort
                class="sort_direction"
                :direction="getSortIconDirection('level')"
                :active="isSortIconActive('level')"
              />
            </a>
          </span>
          <span class="phone">
            <a href="#" @click="updateSorting('phone')">
              {{ $t("Phone number") }}
              <IconSort
                class="sort_direction"
                :direction="getSortIconDirection('phone')"
                :active="isSortIconActive('phone')"
              />
            </a>
          </span>
          <span class="errors">
            <a href="#" @click="updateSorting('error')">
              {{ $t("Errors") }} ({{ importStore.errorsCounter }})
              <IconSort
                class="sort_direction"
                :direction="getSortIconDirection('error')"
                :active="isSortIconActive('error')"
              />
            </a>
          </span>
        </div>
        <ol class="subscriptions_list">
          <li
            v-for="(person, i) in importStore.sortedSubscriptionsList(state.sortField, state.sortDirection)"
            :key="`subscriber-${state.selectedDate}-${person.id}`"
          >
            <span class="counter">{{ i + 1 }}.</span>
            <span class="name">{{ person.name }}</span>
            <span class="level">{{ person.level }}</span>
            <span class="phone">{{ person.phone }}</span>
            <span class="error">{{ person.error }}</span>
            <span class="actions">
              <ActionsButton
                @edit="openModal(MODAL_TYPES.employeeEdit, 'edit', person)"
                @delete="openModal(MODAL_TYPES.action, 'delete', person)"
              />
            </span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { computed, reactive, watch, onBeforeMount } from "vue";

import useStore, { ACTION_FETCH_SUBSCRIPTIONS } from "@/store";
import useModalStore, { ACTION_OPEN_MODAL, MODAL_TYPES } from "@/store/modal";
import useImportStore from "@/store/import";

import ActionsButton from "@/components/buttons/ActionsButton.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import IconSort from "@/components/icons/IconSort.vue";
import MonthInput from "@/components/inputs/MonthInput.vue";

import URLS from "@/config/urls";
import { ROUTE_NAMES } from "@/router";

import request from "@/helpers/request";

const route = useRoute();
const router = useRouter();
const store = useStore();
const modalStore = useModalStore();
const importStore = useImportStore();

const state = reactive({
  processing: false,

  sortField: null,
  sortDirection: "asc",
});

function updateSorting(field) {
  if (field !== state.sortField) {
    state.sortDirection = "asc";
  } else {
    state.sortDirection = state.sortDirection === "asc" ? "desc" : "asc";
  }

  state.sortField = field;
}

function fetchSubscriptions(newDate, oldDate) {
  if (newDate !== oldDate) {
    let { company } = route.query;

    if (!company) {
      company = store.companies[0].id;

      router.push({ query: { company } });
    }

    store[ACTION_FETCH_SUBSCRIPTIONS](company, state.selectedDate);
  }
}

function openModal(type, mode, payload = {}) {
  const { company: companyId } = route.query;
  const company = store.getCompanyById(companyId);
  const COMMON_MODAL_PAYLOAD = {
    date: state.selectedDate,
    country: company.country,
  };
  const modalPayload = Object.assign(COMMON_MODAL_PAYLOAD, payload);

  if (type === MODAL_TYPES.action) {
    modalPayload.callback = () => {
      const index = importStore.data.findIndex((el) => el.id == payload.id);
      if (index >= 0) {
        importStore.data.splice(index, 1);
      }
    };
  }

  modalStore[ACTION_OPEN_MODAL]({ type, mode, flow: "import" }, modalPayload);
}

const isSortIconActive = (field) => field === state.sortField;
const getSortIconDirection = (field) => (isSortIconActive(field) ? state.sortDirection : "asc");

watch(() => state.selectedDate, fetchSubscriptions);

/**
 * TODO: move this component's functionality into SubscriptionsListView component
 */
const selectedDate = computed(() => importStore.date);
const isSaveButtonEnabled = computed(() => importStore.errorsCounter === 0);

async function saveChanges() {
  if (!isSaveButtonEnabled.value) {
    return;
  }

  state.processing = true;

  const url = URLS.API.subscriptionsUpdateMany(route.query.company, importStore.date);

  try {
    const lastEditableMonth = store.permissions?.allow_months?.slice(-1)[0];

    await request({
      method: "PUT",
      url,
      data: {
        data: importStore.data,
      },
    });

    await router.push({
      name: ROUTE_NAMES.subscriptions.list,
      query: { ...route.query, force_date: lastEditableMonth },
    });

    importStore.$reset();
  } catch (error) {
    const data = error.response.data.data.map((el, i) => ({ ...el, id: i + 1 }));
    importStore.data = data;
  }

  state.processing = false;
}

onBeforeMount(() => {
  if (!importStore.data) {
    router.push({ name: ROUTE_NAMES.subscriptions.list, query: route.query });
  }
});
</script>

<style lang="scss" scoped>
.view {
  background: rgba(255, 255, 255, 0.8);
  padding: 24px;

  h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .controls {
    display: flex;
    align-items: flex-end;
    margin-top: 12px;

    .btn {
      margin-left: 30px;
    }
  }

  .spinner_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
  }

  .content {
    margin-top: 14px;

    .subscriptions {
      padding-bottom: 72px;

      .subscriptions_controls {
        margin-bottom: 6px;
        padding: 0 8px;

        span {
          display: inline-block;
          font-weight: 400;
          font-size: 13px;
          color: #7d7d7d;

          &.name {
            width: 30%;
          }

          &.level {
            width: 15%;
          }

          &.phone {
            width: 20%;
          }

          &.errors {
            width: 30%;
          }

          a {
            &:hover {
              text-decoration: underline;
            }

            .sort_direction {
              &.active {
                color: #000;
              }
            }
          }
        }
      }

      .subscriptions_list {
        li {
          font-weight: 500;
          font-size: 16px;
          line-height: 1.5em;
          padding: 1em 8px;
          display: flex;
          align-items: flex-start;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0);
          position: relative;

          &:hover,
          &.active {
            border-color: rgba(0, 0, 0, 0.3);
          }

          span {
            display: inline-block;

            &.counter {
              width: 5%;
              align-self: flex-start;
              text-align: left;
            }

            &.name {
              width: 25%;
            }

            &.level {
              width: 15%;
            }

            &.phone {
              width: 20%;
            }

            &.error {
              width: 30%;
            }
          }
        }
      }
    }

    .statistics {
      margin-left: 24px;

      h2 {
        font-weight: 400;
        font-size: 16px;
      }

      p {
        line-height: 1.5em;
        font-weight: 400;
        margin-top: 0.4em;

        &.total_records {
          margin-top: 1.5em;
          font-size: 16px;
          color: #888;
        }
      }
    }
  }
}
</style>
