<template>
  <div class="layout-view">
    <LayoutViewHeader :error-message="props.errorMessage" />

    <div v-if="slots.controls" class="layout-view_controls">
      <slot name="controls" />
    </div>

    <slot />
  </div>
</template>

<script setup>
import { useSlots } from "vue";

import LayoutViewHeader from "@/layouts/LayoutViewHeader.vue";

const slots = useSlots();

const props = defineProps({
  errorMessage: {
    type: String,
    required: false,
    default: "",
  },
});
</script>

<style lang="scss">
.layout-view {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 24px;

  &_controls {
    display: flex;
    align-items: flex-end;
    margin: 12px 0;

    .btn {
      margin-left: 30px;
    }
  }
}
</style>
