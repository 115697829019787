<template>
  <div class="page-vouchers" :class="BRAND">
    <LanguageSwitcher class="page-vouchers_language-switcher" />
    <div class="page-vouchers-container">
      <SpinnerBrand v-if="state.fetching" />
      <form v-else action="#" class="page-vouchers_form" @submit.prevent="onFormSubmit">
        <h2 class="page-vouchers_form_title">{{ state.subscription.company }}</h2>
        <Banner class="page-vouchers_form_banner" type="success" :visible="state.isSubscriptionSaved">
          {{ $t("The data is saved.") }}
        </Banner>
        <BaseInput class="page-vouchers_form_input" :model-value="state.subscription.level" disabled />
        <BaseInput
          v-model="state.subscription.name"
          class="page-vouchers_form_input"
          :disabled="state.isVoucherRedeemed"
          :error="state.errors.name"
          :placeholder="$t('Name Surname')"
        />
        <BaseInput
          ref="phoneInputRef"
          v-model="state.subscription.phone"
          class="page-vouchers_form_input"
          validator="phone"
          :disabled="state.isVoucherRedeemed"
          :error="state.errors.phone || state.errors.order_item_phone"
          :placeholder="$t('Phone number')"
        />
        <div class="page-vouchers_form_controls">
          <Button
            type="submit"
            class="page-vouchers_form_controls_button"
            :disabled="state.isVoucherRedeemed"
            :processing="state.saving"
          >
            {{ $t("Save") }}
          </Button>
        </div>
        <p v-if="state.isVoucherRedeemed" class="page-vouchers_info">
          {{ $t("If you want to make changes to the completed data, contact your HR.") }}
        </p>
        <p
          class="page-vouchers_info"
          v-html="
            $tc(
              `Please download {brand} App to start using your subscription. Your plan will be active on the 1st day of the following month. To get additional info, please call {phone_number}`,
              {
                brand: brandName,
                phone_number: `<a href='tel:${supportPhone}'>${supportPhone}</a>`,
              }
            )
          "
        ></p>
        <div class="page-vouchers_download">
          <a :href="DOWNLOAD_LINKS.sportbenefit.google" target="_blank"><img src="/images/badge-google-play.png" /></a>
          <a :href="DOWNLOAD_LINKS.sportbenefit.apple" target="_blank"><img src="/images/badge-app-store.png" /></a>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeMount, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import Banner from "@/components/banners/Banner.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import LanguageSwitcher from "@/components/language/LanguageSwitcher.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";

import { BRAND, DOWNLOAD_LINKS, SERVICE_PHONES } from "@/config";
import { ROUTE_NAMES } from "@/router";
import { t } from "@/helpers/i18n/stubs";
import clearPhone from "@/helpers/converters/clearPhone";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import request from "@/helpers/request";
import URLS from "@/config/urls";

const MESSAGES = {
  requiredField: t("This field is required"),
};

const route = useRoute();
const router = useRouter();

const phoneInputRef = ref(null);

const state = reactive({
  fetching: false,
  saving: false,

  errors: {},

  voucher: null,
  isVoucherRedeemed: false,
  isSubscriptionSaved: false,

  subscription: reactive({
    company: "",
    level: "",
    name: "",
    phone: "",
  }),
});

const brandName = computed(() => process.env.VUE_APP_BRAND || BRAND);
const supportPhone = computed(() => SERVICE_PHONES.lt.support);

async function fetchVoucher() {
  state.fetching = true;

  const url = URLS.API.vouchers.single(state.voucher);

  try {
    const {
      company_name: company,
      subscription_level: level,
      order_item_name: name,
      order_item_phone: phone,
    } = await request(url);

    state.subscription = {
      company,
      level,
      name: name ?? "",
      phone: phone?.toString() || "",
    };
  } catch (xhrError) {
    state.errors = prepareAxiosErrors(xhrError, { flatResult: true });
  }

  state.fetching = false;
}

function onFormSubmit() {
  if (state.saving) {
    return;
  }

  const isValidationError = validateForm();

  if (isValidationError) {
    return;
  }

  updateVoucher();
}

function validateForm() {
  const REQUIRED_FIELDS = ["name", "phone"];
  const validationErrors = {};

  state.errors = {};

  REQUIRED_FIELDS.forEach((field) => {
    if (state.subscription[field].length === 0) {
      validationErrors[field] = MESSAGES.requiredField;
    }
  });

  nextTick(() => {
    state.errors = validationErrors;
  });

  const isValidationError = Object.keys(validationErrors).length > 0;

  return isValidationError || phoneInputRef.value.error;
}

async function updateVoucher() {
  state.saving = true;

  const url = URLS.API.vouchers.update(state.voucher);
  const { name, phone } = state.subscription;
  const data = {
    order_item_name: name,
    order_item_phone: clearPhone(phone),
  };

  try {
    await request({
      url,
      method: "PUT",
      data,
    });

    state.isVoucherRedeemed = true;
    state.isSubscriptionSaved = true;
  } catch (xhrError) {
    state.errors = prepareAxiosErrors(xhrError, { flatResult: true });
  }

  state.saving = false;
}

onBeforeMount(async () => {
  state.voucher = route.query.voucher;

  if (state.voucher) {
    await fetchVoucher();

    const { name, phone } = state.subscription;

    if (name && phone) {
      state.isVoucherRedeemed = true;
    }
  } else {
    router.push({
      name: ROUTE_NAMES.home,
    });
  }
});
</script>

<style lang="scss">
@import "@/assets/styles/mixins";

.page-vouchers {
  position: relative;

  @include vouchers-redeem-backgrounds;

  &-container {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
    background-color: rgba(255, 255, 255, 0.8);
  }

  & &_language-switcher {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &_form {
    width: 400px;
    max-width: 100%;
    padding: 0 10px;

    &_title {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.4em;
    }

    &_banner {
      margin-top: 8px;
    }

    &_input {
      margin: 10px 0;

      @media (max-width: 479px) {
        margin: 4px 0;
      }
    }

    &_controls {
      overflow: hidden;

      &_button {
        float: right;
      }
    }
  }

  &_info {
    font-weight: 400;
    font-size: 13px;
    color: #444545;
    line-height: 2em;
    margin-top: 2em;
    text-align: justify;

    a {
      color: #346aed;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &_download {
    display: block;
    height: auto;
    overflow: hidden;
    margin-top: 18px;

    a {
      float: left;
      height: 40px;
      width: auto;

      & + a {
        margin-left: 12px;
      }

      img {
        height: 100%;
        width: auto;
      }
    }
  }
}
</style>
