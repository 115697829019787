<template>
  <LoadingView v-if="processing" />
  <router-view v-else />
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";

import router, { ROUTE_NAMES } from "@/router";
import useStore, { ACTION_FETCH_INITIAL_DATA, ACTION_FETCH_PERMISSIONS, COMPANIES_REGISTRATION_TYPES } from "@/store";
import useUserStore, { ACTION_LOGIN } from "@/store/user";

import LoadingView from "@/views/LoadingView.vue";

const route = useRoute();
const store = useStore();
const storeUser = useUserStore();

const processing = ref(false);

storeUser.$onAction(({ name, after }) => {
  if (name === ACTION_LOGIN) {
    after(fetchInitialData);
  }
});

async function redirectToSubscriptionsList() {
  if (store.companies && store.companies.length > 0) {
    const { company: routeCompanyId } = route.query;
    const routeCompany = store.companies.find(({ id }) => id == routeCompanyId);

    const company = routeCompany ?? store.companies[0];

    await store[ACTION_FETCH_PERMISSIONS](company.id);

    if (route.name === ROUTE_NAMES.home || !routeCompany) {
      const routeName =
        company.registration_type === COMPANIES_REGISTRATION_TYPES.copayments
          ? ROUTE_NAMES.copayments.pending
          : ROUTE_NAMES.subscriptions.list;

      const nextRoute = {
        name: routeName,
        query: {
          company: company.id,
        },
      };

      await router.push(nextRoute);
    }
  }
}

async function fetchInitialData() {
  processing.value = true;

  if (storeUser.isAuthenticated) {
    await store[ACTION_FETCH_INITIAL_DATA]();
    await redirectToSubscriptionsList();
  }

  processing.value = false;
}

onBeforeMount(() => {
  fetchInitialData();
});
</script>

<style lang="scss">
#app {
  background-color: #fff;
  position: relative;
}
</style>
