<template>
  <LayoutView :error-message="state.error ? $t('Data receiving error') : null">
    <div class="content">
      <Table class="table" :columns="TABLE_COLUMNS" :items="state.auditsList" :processing="state.processing" />
    </div>
  </LayoutView>
</template>

<script>
import { reactive, watch } from "vue";
import { useRoute } from "vue-router";

import LayoutView from "@/layouts/LayoutView.vue";
import Table from "@/components/tables/Table.vue";

import prepareDate from "@/helpers/dates/prepareDate";
import request from "@/helpers/request";
import URLS from "@/config/urls";
import { t } from "@/helpers/i18n/stubs";
import useStore from "@/store";
import { priceFormatter } from "@/helpers/formatters";

const TABLE_COLUMNS = [
  {
    header: t("Date"),
    key: "time",
    sortable: true,
    value: ({ date, time }) => `${date} ${time}`,
    width: "20%",
  },
  {
    header: t("Subscriptions qnt"),
    key: "subscriptions_count",
    width: "20%",
  },
  {
    header: t("Cost"),
    key: "cost",
    width: "20%",
    value: ({ cost }) => {
      return priceFormatter(cost);
    },
  },
  {
    header: t("Full name"),
    key: "user",
    sortable: true,
    width: "40%",
  },
];

export default {
  components: {
    LayoutView,
    Table,
  },

  setup() {
    const route = useRoute();
    const store = useStore();

    const state = reactive({
      processing: false,
      error: false,

      auditsList: [],
    });

    async function getAudit(newDate) {
      if (!newDate) {
        return;
      }

      const date = prepareDate(newDate).getRaw();
      const { company } = route.query;
      const url = URLS.API.audit.list(company, date);

      state.processing = true;
      state.error = false;
      state.auditsList = [];

      try {
        state.auditsList = await request(url);
      } catch (error) {
        state.error = true;
      }

      state.processing = false;
    }

    watch(() => store.selectedDate, getAudit, {
      immediate: true,
    });

    watch(
      () => route.query.company,
      (companyId) => {
        if (companyId) {
          getAudit(store.selectedDate);
        }
      }
    );

    return {
      TABLE_COLUMNS,

      state,
    };
  },
};
</script>

<style lang="scss" scoped>
.view {
  background: rgba(255, 255, 255, 0.7);
  padding: 24px;

  h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .controls {
    display: flex;
    align-items: flex-end;
    margin-top: 12px;

    .btn {
      margin-left: 30px;
    }
  }

  .spinner_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
  }

  .table {
    margin-top: 14px;
  }
}
</style>
