<template>
  <LayoutView :error-message="$t(state.errors.errorMessage)">
    <div class="content">
      <Table
        class="table"
        :actions="TABLE_ACTIONS"
        :columns="TABLE_COLUMNS"
        :items="state.invoicesList"
        :processing="state.processing"
        @download="onDownload"
        @preview="onPreview"
      />
    </div>
  </LayoutView>
</template>

<script>
import { computed, reactive, watchEffect, unref } from "vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";

import LayoutView from "@/layouts/LayoutView.vue";
import Table from "@/components/tables/Table.vue";

import { ACTIONS } from "@/components/buttons/ActionsButtonV2.vue";
import { ROUTE_NAMES } from "@/router";
import { t } from "@/helpers/i18n/stubs";
import { URLS } from "@/config";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import request, { download } from "@/helpers/request";
import useStore, { COMPANIES_REGISTRATION_TYPES } from "@/store";
import { priceFormatter } from "@/helpers/formatters";

const DEFAULT_ERRORS_OBJECT = {
  errorMessage: ":empty:",
};

const DATA_RECEIVING_ERROR = t("Data receiving error");

const TABLE_COLUMNS = [
  {
    header: t("Invoice id"),
    key: "id",
    sortable: true,
  },
  {
    header: t("Order id"),
    key: "order_id",
  },
  {
    header: t("Amount"),
    key: "amount",
    sortable: true,
  },
  {
    header: t("Total"),
    key: "total",
    value: ({ total }) => {
      return priceFormatter(total);
    },
  },
];

const TABLE_ACTIONS = [
  {
    title: t("Download"),
    key: ACTIONS.download,
  },
  {
    title: t("Preview"),
    key: ACTIONS.preview,
  },
];

export default {
  components: {
    LayoutView,
    Table,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const state = reactive({
      processing: false,
      errors: { ...DEFAULT_ERRORS_OBJECT },

      invoicesList: [],
    });

    const companyId = computed(() => route.query.company);

    function getInvoicesUrlByRegistrationType(date) {
      const companiesList = store.companies;
      const currentCompany = companiesList.find((company) => String(company.id) === String(companyId.value));
      const isCopaymentCompany = currentCompany.registration_type === COMPANIES_REGISTRATION_TYPES.copayments;

      return isCopaymentCompany
        ? URLS.API.copayments.invoices.list(unref(companyId))
        : URLS.API.invoices.list(unref(companyId), date);
    }

    function resetStateErrors() {
      state.errors = { ...DEFAULT_ERRORS_OBJECT };
    }

    async function fetchInvoicesList(date) {
      state.processing = true;
      resetStateErrors();
      state.invoicesList = [];

      const url = getInvoicesUrlByRegistrationType(date);

      try {
        state.invoicesList = await request(url);
      } catch (xhrError) {
        state.errors = {
          errorMessage: DATA_RECEIVING_ERROR,
        };
      }

      state.processing = false;
    }

    async function onDownload(itemId) {
      resetStateErrors();

      const url = URLS.API.invoices.download(itemId);

      try {
        await download(url);
      } catch (xhrError) {
        state.errors = prepareAxiosErrors(xhrError);
      }
    }

    function onPreview(invoiceId) {
      router.push({
        name: ROUTE_NAMES.invoices.preview,
        params: { invoiceId },
        query: route.query,
      });
    }

    const stopSelectedDateWatcher = watchEffect(() => fetchInvoicesList(store.selectedDate));

    onBeforeRouteLeave(() => {
      stopSelectedDateWatcher();
    });

    return {
      TABLE_ACTIONS,
      TABLE_COLUMNS,

      state,

      onDownload,
      onPreview,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 14px;
  display: grid;
  grid-template-columns: 7fr 2fr;
}
</style>
