<template>
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.41057 0C5.95592 0 5.51988 0.158035 5.19839 0.43934C4.8769 0.720644 4.69629 1.10218 4.69629 1.5V2.5C4.69629 2.89782 4.8769 3.27936 5.19839 3.56066C5.51988 3.84196 5.95592 4 6.41057 4H9.83915C10.2938 4 10.7298 3.84196 11.0513 3.56066C11.3728 3.27936 11.5534 2.89782 11.5534 2.5V1.5C11.5534 1.10218 11.3728 0.720644 11.0513 0.43934C10.7298 0.158035 10.2938 0 9.83915 0L6.41057 0ZM9.83915 1C9.9907 1 10.136 1.05268 10.2432 1.14645C10.3504 1.24021 10.4106 1.36739 10.4106 1.5V2.5C10.4106 2.63261 10.3504 2.75979 10.2432 2.85355C10.136 2.94732 9.9907 3 9.83915 3H6.41057C6.25902 3 6.11368 2.94732 6.00651 2.85355C5.89935 2.75979 5.83915 2.63261 5.83915 2.5V1.5C5.83915 1.36739 5.89935 1.24021 6.00651 1.14645C6.11368 1.05268 6.25902 1 6.41057 1H9.83915Z"
    />
    <path
      d="M3.55357 1.5H2.41071C1.80451 1.5 1.22312 1.71071 0.79447 2.08579C0.365816 2.46086 0.125 2.96957 0.125 3.5V14C0.125 14.5304 0.365816 15.0391 0.79447 15.4142C1.22312 15.7893 1.80451 16 2.41071 16H13.8393C14.4455 16 15.0269 15.7893 15.4555 15.4142C15.8842 15.0391 16.125 14.5304 16.125 14V3.5C16.125 2.96957 15.8842 2.46086 15.4555 2.08579C15.0269 1.71071 14.4455 1.5 13.8393 1.5H12.6964V2.5C12.6964 2.8283 12.6225 3.15339 12.4789 3.45671C12.3354 3.76002 12.1249 4.03562 11.8596 4.26777C11.5943 4.49991 11.2793 4.68406 10.9327 4.8097C10.586 4.93534 10.2145 5 9.83929 5H6.41071C5.65295 5 4.92623 4.73661 4.39041 4.26777C3.85459 3.79893 3.55357 3.16304 3.55357 2.5V1.5ZM11.3867 8.854L7.95814 11.854C7.90506 11.9006 7.842 11.9375 7.77258 11.9627C7.70316 11.9879 7.62873 12.0009 7.55357 12.0009C7.47841 12.0009 7.40399 11.9879 7.33456 11.9627C7.26514 11.9375 7.20208 11.9006 7.149 11.854L5.43471 10.354C5.32742 10.2601 5.26714 10.1328 5.26714 10C5.26714 9.86722 5.32742 9.73989 5.43471 9.646C5.54201 9.55211 5.68754 9.49937 5.83929 9.49937C5.99103 9.49937 6.13656 9.55211 6.24386 9.646L7.55357 10.793L10.5776 8.146C10.6849 8.05211 10.8304 7.99937 10.9821 7.99937C11.1339 7.99937 11.2794 8.05211 11.3867 8.146C11.494 8.23989 11.5543 8.36722 11.5543 8.5C11.5543 8.63278 11.494 8.76011 11.3867 8.854Z"
    />
  </svg>
</template>
