<template>
  <div class="subscriptions-statistics">
    <h3>{{ $t("Details") }}</h3>
    <ul class="subscriptions-statistics_details">
      <li
        v-for="stat in sortedSubscriptionsStatistics"
        :key="`subscriptions-statistics-${stat.name}`"
        class="subscriptions-statistics_details_item"
      >
        <h4 v-capitalize>{{ stat.name }}</h4>
        <div class="subscriptions-statistics_details_item_row">
          <span>{{ $t("Quantity:") }}</span>
          <span>{{ stat.quantity }}</span>
        </div>
        <div v-if="USER_ROLE.manager" class="subscriptions-statistics_details_item_row">
          <span>{{ $t("Price:") }}</span>
          <span>{{ stat.price }}</span>
        </div>
      </li>
      <li class="subscriptions-statistics_details_item subscriptions-statistics_details_item__total">
        <h4>{{ $t("Total:") }}</h4>
        <div class="subscriptions-statistics_details_item_row">
          <span>{{ $t("Quantity:") }}</span>
          <span>{{ store.subscriptionsStatisticsFull.total.quantity }}</span>
        </div>
        <div v-if="USER_ROLE.manager" class="subscriptions-statistics_details_item_row">
          <span>{{ $t("Price:") }}</span>
          <span>{{ store.subscriptionsStatisticsFull.total.price }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import useStore from "@/store";
import useUserRole from "@/composables/useUserRole";

const route = useRoute();
const store = useStore();
const { USER_ROLE } = useUserRole();

const subscriptionLevelsList = computed(() => {
  const { company: companyId } = route.query;
  const company = store.companies.find(({ id }) => id == companyId);

  if (company) {
    const { country } = company;
    return store.config.levels[country];
  }

  return [];
});

const sortedSubscriptionsStatistics = computed(() => {
  const sortedStatistics = subscriptionLevelsList.value.reduce((list, level) => {
    if (store.subscriptionsStatisticsFull[level]) {
      list.push(store.subscriptionsStatisticsFull[level]);
    }

    return list;
  }, []);
  return sortedStatistics;
});
</script>

<style lang="scss" scoped>
.subscriptions-statistics {
  h3 {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    margin-bottom: 8px;
  }

  &_details {
    list-style-type: none;

    &_item {
      margin-top: 18px;

      h4 {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }

      &_row {
        margin-top: 8px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-column-gap: 24px;

        span {
          font-weight: 300;
          font-size: 16px;
          color: #000000;
        }
      }

      &__total {
        margin-top: 36px;
      }
    }
  }
}
</style>
