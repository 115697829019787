<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <g clip-path="url(#a)">
      <path
        d="M18.66 20.9a.989.989 0 0 0-1.41.09c-.57.65-1.39 1.02-2.25 1.02H5c-1.65 0-3-1.35-3-3V5c0-1.65 1.35-3 3-3h4.51c.16 0 .33 0 .49.02V7c0 1.65 1.35 3 3 3h5.81c.31 0 .6-.14.79-.39.19-.25.25-.56.18-.86a6.977 6.977 0 0 0-1.83-3.22l-3.48-3.48A6.96 6.96 0 0 0 9.52 0H5C2.24 0 0 2.24 0 5v14c0 2.76 2.24 5 5 5h10c1.43 0 2.8-.62 3.75-1.69a.989.989 0 0 0-.09-1.41ZM12 2.66c.38.22.73.49 1.05.81l3.48 3.48c.31.31.58.67.8 1.05h-4.34c-.55 0-1-.45-1-1V2.66H12Z"
      />
      <path
        d="M13.706 18.186a2.976 2.976 0 0 1 0-4.19l1.61-1.61a.996.996 0 1 1 1.41 1.41l-1.29 1.29h7.4c.55 0 1 .45 1 1s-.45 1-1 1h-7.4l1.29 1.29c.39.39.39 1.02 0 1.41-.2.19-.45.29-.71.29-.26 0-.51-.09-.71-.29l-1.6-1.6ZM12.566 12.77a1 1 0 0 0-.707-1.707h-8a1 1 0 0 0 0 2h8a1 1 0 0 0 .707-.293ZM3.86 15.063h5a1 1 0 0 1 0 2h-5a1 1 0 0 1 0-2Z"
      />
    </g>
  </svg>
</template>
