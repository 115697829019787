const API = {
  companies: "/api/hr-portal/v2/companies",
  config: "/api/hr-portal/v2/config",
  tokenRequests: "/api/hr-portal/v2/token-requests",
  tokens: "/api/hr-portal/v2/tokens",
  invoiceDownload: (companyid, date) =>
    `/api/hr-portal/v2/companies/${companyid}/month/${date}/invoices/last`,
  migrate: (companyId, date) =>
    `/api/hr-portal/v2/companies/${companyId}/month/${date}/subscriptions/migrate`,
  permissions: (companyId) =>
    `/api/hr-portal/v2/companies/${companyId}/permissions`,
  subscriptions: (companyId, date) =>
    `/api/hr-portal/v2/companies/${companyId}/month/${date}/subscriptions/json/`,
  subscriptionsCreateOne: (companyId) =>
    `/api/hr-portal/v2/companies/${companyId}/subscriptions/one`,
  subscriptionsDeleteOne: (companyid, subscriptionId) =>
    `/api/hr-portal/v2/companies/${companyid}/subscriptions/one/${subscriptionId}`,
  subscriptionsDownload: (companyId, date) =>
    `/api/hr-portal/v2/companies/${companyId}/month/${date}/subscriptions/tabular`,
  subscriptionsUpdateMany: (companyId, date) =>
    `/api/hr-portal/v2/companies/${companyId}/month/${date}/subscriptions/json`,
  subscriptionsUpdateOne: (companyId, subscriptionId) =>
    `/api/hr-portal/v2/companies/${companyId}/subscriptions/one/${subscriptionId}`,
  subscriptionsUploadTabular: (companyId, date) =>
    `/api/hr-portal/v2/companies/${companyId}/month/${date}/subscriptions/tabular`,
  templateDownload: (companyId) =>
    `/api/hr-portal/v2/companies/${companyId}/template`,
  acts: {
    download: (actId) => `/api/hr-portal/v2/acts/${actId}/download`,
    list: (companyId, date) =>
      `/api/hr-portal/v2/companies/${companyId}/month/${date}/acts/list`,
  },
  audit: {
    list: (companyId, date) =>
      `/api/hr-portal/v2/companies/${companyId}/month/${date}/subscriptions/audit`,
  },
  copayments: {
    activeMembershipsList: (id) =>
      `/api/hr-portal/v2/companies/${id}/order-items/active`,
    pendingMembershipsList: (id) =>
      `/api/hr-portal/v2/companies/${id}/order-items/pending`,
    updateMembershipStatus: (id) =>
      `/api/hr-portal/v2/order-items/${id}/status`,
    canceledMembershipsList: (id) =>
      `/api/hr-portal/v2/companies/${id}/order-items/deactivated`,
    invoices: {
      list: (companyId) => `/api/hr-portal/v2/companies/${companyId}/invoices`,
    },
  },

  invoices: {
    download: (invoiceId) => `/api/hr-portal/v2/invoices/${invoiceId}/download`,
    list: (companyId, date) =>
      `/api/hr-portal/v2/companies/${companyId}/month/${date}/invoices/list`,
    generate: (companyId) => `/api/hr-portal/v2/companies/${companyId}/invoice`,
  },
  vouchers: {
    single: (voucherId) =>
      `/api/integrations/melp/1.0/hr/vouchers/${voucherId}`,
    update: (voucherId) =>
      `/api/integrations/melp/1.0/hr/vouchers/${voucherId}/item`,
  },
  notificationsSettings: `/api/hr-portal/v2/user/notification-settings`,
};

export default {
  API,
};
