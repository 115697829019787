<template>
  <button v-bind="$attrs" class="btn" :class="elementClass" :disabled="props.disabled" :type="props.type">
    <span class="spinner_container">
      <Spinner :theme="spinnerTheme" />
    </span>
    <span class="text_container">
      <template v-if="props.icon">
        <Icon
          v-if="props.icon === BUTTON_ICONS.plus_white"
          class="icon"
          :class="iconClass"
          icon="plus"
          :fill="props.disabled ? '#8ba4fa' : '#fff'"
        />
        <Icon v-if="props.icon === BUTTON_ICONS.actions" class="icon" icon="dots-vertical" />
      </template>
      <slot />
    </span>
    <div v-if="props.tooltipText" class="btn_tooltip">
      {{ $t(`${props.tooltipText}`) }}
    </div>
  </button>
</template>

<script>
export const BUTTON_TYPES = {
  primary: "primary",
  light: "light",
  simple: "simple",
  success: "success",
};

export const BUTTON_ICONS = {
  actions: "actions",
  download: "download",
  plus_white: "plus_white",
};

export const BUTTON_ICON_POSITION = {
  start: "start",
  end: "end",
};
</script>

<script setup>
import { computed } from "vue";

import Spinner, { DEFAULT_SPINNER_THEME, SPINNER_THEMES } from "@/components/loaders/Spinner.vue";
import Icon from "@/components/icons/Icon.vue";

const props = defineProps({
  buttonType: {
    type: String,
    default: BUTTON_TYPES.primary,
    validator: (val) => Object.values(BUTTON_TYPES).includes(val),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: null,
    validator: (val) => Object.values(BUTTON_ICONS).includes(val),
  },
  iconPosition: {
    type: String,
    default: BUTTON_ICON_POSITION.start,
    validator: (val) => Object.values(BUTTON_ICON_POSITION).includes(val),
  },
  processing: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "button",
  },
  tooltipText: {
    type: String,
    default: "",
  },
});

const elementClass = computed(() => {
  const { disabled, processing } = props;

  const classList = {
    [`btn-${props.buttonType}`]: true,
    btn__processing: processing,
    btn__disabled: disabled || processing,
  };

  return classList;
});

const iconClass = computed(() => {
  const { iconPosition } = props;

  if (iconPosition === BUTTON_ICON_POSITION.end) {
    return "icon__end";
  }

  return null;
});

const spinnerTheme = computed(() => {
  let theme;

  switch (props.buttonType) {
    case BUTTON_TYPES.primary:
      theme = SPINNER_THEMES.light;
      break;

    default:
      theme = DEFAULT_SPINNER_THEME;
      break;
  }

  return theme;
});
</script>

<style lang="scss" scoped>
@use "sass:map";

$btn_primary: (
  "bg_color": #346aed,
  "bg_color_hover": #2348a3,
  "bg_color_active": #444545,
  "bg_color_disabled": #346aed,
  "font_color": #fff,
  "font_color_disabled": #8ba4fa,
);

$btn_light: (
  "bg_color": #fffefe,
  "bg_color_hover": #e7e7e7,
  "bg_color_active": #444545,
  "bg_color_disabled": #fffefe,
  "font_color": #4f4f4f,
  "font_color_active": #ffffff,
  "font_color_disabled": #e7e7e7,
  "box-shadow": 0px 4px 16px rgba(128, 128, 128, 0.16),
);

$btn_success: (
  "bg_color": #79c99e,
  "bg_color_hover": #5a9274,
  "bg_color_active": #444545,
  "bg_color_disabled": #fff,
  "font_color": #ffffff,
  "font_color_disabled": #aaaaaa,
  "box-shadow": 0px 4px 16px rgba(0, 0, 0, 0.16),
);

$buttons: (
  "primary": $btn_primary,
  "light": $btn_light,
  "success": $btn_success,
);

@function get-from-map($target, $key, $default-value) {
  @if map.get($target, $key) {
    @return map.get($target, $key);
  }

  @return $default-value;
}

@function get-box-shadow($target) {
  @return get-from-map($target, "box-shadow", none);
}

.btn {
  border-radius: 8px;
  cursor: pointer;
  height: 48px;
  padding: 0 24px;
  position: relative;

  .text_container {
    font-weight: 700;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .icon {
      margin-right: 8px;

      &__end {
        margin-left: 8px;
        margin-right: 0;
        order: 2;
      }
    }
  }

  .spinner_container {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
  }

  .btn_tooltip {
    position: absolute;
    display: none;
    padding: 8px 12px;
    top: 120%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 220px;
    background-color: #e7e7e7;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 400;
    color: black;
    overflow-wrap: break-word;
    z-index: 1;
  }

  &:hover > .btn_tooltip {
    display: block;
  }

  @each $type, $btn in $buttons {
    &-#{$type} {
      $font_color: map.get($btn, "font_color");
      $bg_color: map.get($btn, "bg_color");

      background-color: $bg_color;
      color: $font_color;
      box-shadow: get-box-shadow($btn);

      &:hover {
        background-color: get-from-map($btn, "bg_color_hover", $bg_color);
      }

      &:active {
        background-color: get-from-map($btn, "bg_color_active", $bg_color);
        color: get-from-map($btn, "font_color_active", $font_color);
      }

      &.btn__disabled {
        background-color: get-from-map($btn, "bg_color_disabled", $bg_color);
        color: get-from-map($btn, "font_color_disabled", $font_color);
      }
    }
  }

  &-simple {
    padding: 0 12px;

    .text_container {
      img {
        margin: 0;
      }
    }
  }

  &__disabled {
    cursor: auto;
  }

  &__processing {
    .text_container {
      visibility: hidden;
    }

    .spinner_container {
      display: flex;
    }
  }
}
</style>
