<template>
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_910_1661)">
      <path
        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99076 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27289 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7118 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333Z"
      />
      <path
        d="M15.2341 6.56322C15.0588 6.38768 14.8211 6.28906 14.5732 6.28906C14.3253 6.28906 14.0876 6.38768 13.9122 6.56322L8.65192 11.8332L6.13908 9.34897C5.96552 9.17079 5.72841 9.06896 5.4799 9.06588C5.23139 9.06281 4.99184 9.15875 4.81395 9.33259C4.63605 9.50643 4.53439 9.74393 4.53132 9.99286C4.52825 10.2418 4.62403 10.4817 4.79759 10.6599L7.36278 13.1882C7.52368 13.3619 7.71803 13.5013 7.93411 13.5978C8.1502 13.6944 8.38355 13.7461 8.62013 13.75H8.65098C8.88288 13.7508 9.11261 13.7054 9.32685 13.6165C9.54109 13.5276 9.73557 13.397 9.89899 13.2322L15.2341 7.88727C15.4094 7.71168 15.5078 7.47355 15.5078 7.22525C15.5078 6.97695 15.4094 6.73882 15.2341 6.56322Z"
      />
    </g>
  </svg>
</template>
