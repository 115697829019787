<template>
  <div class="datepicker">
    <span v-if="props.label" class="datepicker_label">
      {{ props.label }}
      <Icon v-if="!props.disabled" icon="arrow-down" />
    </span>
    <VueDatePicker
      :key="componentKey"
      v-model="value"
      v-bind="$attrs"
      auto-apply
      month-picker
      :clearable="false"
      :format="DATEPICKER_FORMAT_RULES"
      :locale="userStore.language"
      :preview-format="() => ''"
      :disabled="props.disabled"
      :hide-input-icon="props.disabled"
      @closed="blur"
    >
      <template v-if="!props.disabled" #input-icon>
        <Icon icon="calendar" width="16px" height="16px" />
      </template>
    </VueDatePicker>
  </div>
</template>

<script setup>
import { computed, nextTick, ref } from "vue";

import Icon from "@/components/icons/Icon.vue";
import VueDatePicker from "@vuepic/vue-datepicker";

import { getMessage, t } from "@/helpers/i18n/stubs";
import useUserStore, { ACTION_UPDATE_LANGUAGE } from "@/store/user";

import "@/assets/styles/datepicker.scss";

const DATEPICKER_MONTHS = [
  t("January"),
  t("February"),
  t("March"),
  t("April"),
  t("May"),
  t("June"),
  t("July"),
  t("August"),
  t("September"),
  t("October"),
  t("November"),
  t("December"),
];

const userStore = useUserStore();

userStore.$onAction(userStoreLanguageWatcher);

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  modelValue: {
    type: String,
    default: "",
  },
});

const componentKey = ref(0);

const value = computed({
  get() {
    const year = parseInt(props.modelValue.toString().slice(0, 2)) + 2000;
    const month = parseInt(props.modelValue.toString().slice(2, 4)) - 1;

    return { month, year };
  },
  set(newVal) {
    const { month, year } = newVal;
    const yearShort = year.toString().slice(2);
    const monthPrepared = (month + 1).toString().padStart(2, "0");
    const value = `${yearShort}${monthPrepared}`;

    emit("update:modelValue", value);
  },
});

function DATEPICKER_FORMAT_RULES(date) {
  if (!date) {
    return "";
  }

  const month = date.getMonth();
  const year = date.getFullYear();

  return `${getMessage(DATEPICKER_MONTHS[month])} ${year}`;
}

function blur() {
  nextTick(() => {
    document.activeElement.blur();
  });
}

function userStoreLanguageWatcher({ name, after }) {
  after(() => {
    if (name === ACTION_UPDATE_LANGUAGE) {
      componentKey.value += 1;
    }
  });
}
</script>

<style lang="scss" scoped>
.datepicker {
  width: 240px;
  max-width: 100%;
  position: relative;

  &_label {
    font-size: 13px;
    color: #7d7d7d;
    line-height: 2em;
  }
}
</style>
