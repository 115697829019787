import { toRaw } from "vue";
import { defineStore } from "pinia";

import { prepareDate } from "@/helpers/dates";
import { URLS } from "@/config";
import request from "@/helpers/request";
import router from "@/router";

export const STORE_NAMESPACE = "main";

const ACTION_ADD_STATISTICS_RECALCULATION = "addStatisticsRecalculation";
const ACTION_RUN_STATISTICS_RECALCULATION = "runStatisticsRecalculation";

export const ACTION_DELETE_SUBSCRIPTION = "deleteSubscription";
export const ACTION_FETCH_INITIAL_DATA = "fetchInitialData";
export const ACTION_FETCH_PERMISSIONS = "fetchPermissions";
export const ACTION_FETCH_SUBSCRIPTIONS = "fetchSubscriptions";
export const ACTION_MIGRATE_SUBSCRIPTIONS = "migrateSubscriptions";
export const ACTION_UPDATE_SUBSCRIPTION = "updateSubscription";
export const ACTION_GENERATE_INVOICE_BY_COMPANY_ID =
  "generateInvoiceByCompanyId";

export const COMPANIES_REGISTRATION_TYPES = {
  base: 0,
  copayments: 1,
};

export default defineStore(STORE_NAMESPACE, {
  state: () => {
    return {
      error: null,

      selectedDate: prepareDate().getNextMonth().getRaw(),

      config: {},
      companies: [],
      levels: {},
      permissions: {},
      permissionsCompanyId: null,

      subscriptions: [],
      subscriptionsMonth: null,

      processingSubscriptions: false,
      processingMigration: false,

      recalculateStatisticsQueue: [],
    };
  },
  getters: {
    coutrySubscriptionLevels: (state) => (countryCode) => {
      return state.config.levels[countryCode];
    },
    getCompanyById: (state) => (companyId) => {
      return state.companies.find(({ id }) => id == companyId);
    },
    sortedSubscriptionsList: (state) => (date, sortField, sortDirection) => {
      const DEFAULT_SORT_FIELD = "id";
      const DEFAULT_SORT_DIRECTION = "asc";

      const field = sortField || DEFAULT_SORT_FIELD;
      const direction = sortDirection || DEFAULT_SORT_DIRECTION;

      let list = Array.from(state.subscriptions).map(toRaw);

      if (list.length > 0) {
        list.sort((a, b) => {
          let valA = a[field].toString();
          let valB = b[field].toString();

          if (direction === "asc") {
            return valA.localeCompare(valB);
          } else {
            return valB.localeCompare(valA);
          }
        });
      }

      return list;
    },
    subscriptionsListLength: (state) => state.subscriptions.length,
    subscriptionsStatisticsFull: (state) => {
      const initialStatistics = {
        total: {
          price: 0,
          quantity: 0,
        },
      };

      const statisticsCounter = (stats, item) => {
        const { level, price = 0 } = item;

        if (!stats[level]) {
          stats[level] = {
            name: level,
            quantity: 0,
            price: 0,
          };
        }

        stats[level].quantity += 1;
        stats[level].price += price;

        stats.total.quantity += 1;
        stats.total.price += price;

        return stats;
      };

      const statistics = state.subscriptions.reduce(
        statisticsCounter,
        initialStatistics
      );

      return statistics;
    },
  },
  actions: {
    async [ACTION_FETCH_INITIAL_DATA]() {
      try {
        const [companies, config] = await Promise.all([
          request(URLS.API.companies),
          request(URLS.API.config),
        ]);

        this.companies = companies;
        this.config = config;
      } catch (error) {
        this.error = error;
      }
    },
    async [ACTION_FETCH_SUBSCRIPTIONS](companyId, date) {
      const requestId = `${companyId}-${date}`;

      if (this.processingSubscriptions === requestId) {
        return;
      }

      this.processingSubscriptions = requestId;
      this.error = null;
      this.subscriptions = [];

      try {
        await this[ACTION_FETCH_PERMISSIONS](companyId);

        const url = URLS.API.subscriptions(companyId, date);
        this.subscriptions = await request(url);
        this.subscriptionsMonth = date;
      } catch (error) {
        this.error = error;
      }

      this.processingSubscriptions = false;
    },
    async [ACTION_FETCH_PERMISSIONS](companyId) {
      const routeCompany = router?.currentRoute?.value?.query?.company;

      if (!companyId) {
        companyId = routeCompany || this.companies[0].id;
      }

      if (this.permissionsCompanyId == companyId) {
        return;
      }

      this.permissions = await request(URLS.API.permissions(companyId));
      this.permissionsCompanyId = companyId;
    },
    async [ACTION_MIGRATE_SUBSCRIPTIONS](companyId, date) {
      this.error = null;
      this.processingMigration = true;

      const url = URLS.API.migrate(companyId, date);
      const method = "PUT";

      try {
        await request({
          url,
          method,
        });

        await this[ACTION_FETCH_SUBSCRIPTIONS](companyId, date);
      } catch (error) {
        this.error = error;
      }

      this.processingMigration = false;
    },
    async [ACTION_UPDATE_SUBSCRIPTION](data) {
      const { company, month, ...updatedSubscription } = data;
      const isEditMode = updatedSubscription.id !== null;

      let url;
      let method;

      if (isEditMode) {
        method = "PUT";
        url = URLS.API.subscriptionsUpdateOne(company, updatedSubscription.id);
      } else {
        method = "POST";
        url = URLS.API.subscriptionsCreateOne(company);
      }

      const requestConfig = {
        data: {
          ...updatedSubscription,
          month,
        },
        method,
        url,
      };

      const subscription = await request(requestConfig);

      if (!this.subscriptions) {
        this.subscriptions = [];
      }

      const subscriptionsIndexInList = this.subscriptions.findIndex(
        (el) => subscription.id === el.id
      );

      if (subscriptionsIndexInList >= 0) {
        this.subscriptions[subscriptionsIndexInList] = subscription;
      } else {
        this.subscriptions.push(subscription);
      }

      this[ACTION_ADD_STATISTICS_RECALCULATION]({ company, month });

      return subscription;
    },
    async [ACTION_DELETE_SUBSCRIPTION]({ company, subscriptionId } = {}) {
      if (company && subscriptionId) {
        const method = "DELETE";
        const url = URLS.API.subscriptionsDeleteOne(company, subscriptionId);

        await request({ method, url });

        const subscriptionsIndexInList = this.subscriptions.findIndex(
          (el) => subscriptionId === el.id
        );

        if (subscriptionsIndexInList >= 0) {
          this.subscriptions.splice(subscriptionsIndexInList, 1);
        }
      }
    },
    [ACTION_ADD_STATISTICS_RECALCULATION]({ company, month }) {
      const id = `${company}-${month}`;

      const isRecalculateQueued = this.recalculateStatisticsQueue.find(
        (recalculateItem) => recalculateItem.id === id
      );

      if (!isRecalculateQueued) {
        const recalculateItem = {
          id,
          company,
          month,
        };
        this.recalculateStatisticsQueue.push(recalculateItem);

        this[ACTION_RUN_STATISTICS_RECALCULATION](recalculateItem);
      }
    },
    [ACTION_RUN_STATISTICS_RECALCULATION]({ id, company, month }) {
      const updateSubscriptions =
        company == this.permissionsCompanyId &&
        month == this.subscriptionsMonth;

      this.recalculateStatisticsQueue = this.recalculateStatisticsQueue.filter(
        (recalculateItem) => recalculateItem.id !== id
      );

      if (updateSubscriptions) {
        this[ACTION_FETCH_SUBSCRIPTIONS](company, month);
      }
    },
    async [ACTION_GENERATE_INVOICE_BY_COMPANY_ID](companyId) {
      const url = URLS.API.invoices.generate(companyId);
      const method = "POST";

      await request({ url, method });
    },
  },
});
