import PHONES_CONFIG from "@/config/phones";
import { hideSymbols } from "@/directives/hideTextContent";

//TODO: Delete this functional after using hideTextContent directive in CopaymentsTable component

export function hideTextContent(originalString, mode, country) {
  let updatedText = "";

  if (originalString === null) {
    return "—";
  }

  const preparedString = String(originalString);

  if (mode === "phone") {
    const phonePrefixLength = PHONES_CONFIG.COUNTRIES[country].code.length;

    const phonePrefix = preparedString.slice(0, phonePrefixLength);
    const phoneOriginal = preparedString.slice(phonePrefixLength);
    const phoneHidden = hideSymbols(phoneOriginal);

    updatedText = phonePrefix + phoneHidden;
  } else {
    const names = preparedString.split(" ").filter(Boolean);
    const updatedNames = names.map((str) => hideSymbols(str));

    updatedText = updatedNames.join(" ");
  }

  return updatedText;
}
