<template>
  <a href="#" class="sidebar-menu_item" @click.prevent="toggleSubmenuState">
    <Icon :icon="companyStatusIcon" class="sidebar-menu_item_icon" fill="#000" />
    <p :title="props.company.name">{{ props.company.name }}</p>
  </a>

  <nav class="sidebar-submenu" :class="{ opened: isOpened }">
    <template v-if="company.registration_type === COMPANIES_REGISTRATION_TYPES.copayments">
      <div v-if="company.copayment_registration_url" class="subscriber-link">
        <p>{{ $t("Link for member sign-up") }}</p>
        <ClipboardCopyButton class="subscriber-link_copy" :value="company.copayment_registration_url" fill="#2348a3" />
      </div>

      <router-link
        :to="{ name: ROUTE_NAMES.copayments.active, query: { company: props.company.id } }"
        :class="companyListItemClass"
      >
        <Icon icon="dumbbell" class="sidebar-menu_item_icon" fill="#000" />
        {{ $t("Active members") }}
      </router-link>
      <router-link
        :to="{ name: ROUTE_NAMES.copayments.pending, query: { company: props.company.id } }"
        :class="companyListItemClass"
      >
        <Icon icon="subscribers" class="sidebar-menu_item_icon" fill="#000" />
        {{ $t("Pending requests") }}
      </router-link>
      <router-link
        :to="{ name: ROUTE_NAMES.copayments.cancelled, query: { company: props.company.id } }"
        :class="companyListItemClass"
      >
        <Icon icon="cross-small" class="sidebar-menu_item_icon" fill="#000" />
        {{ $t("Cancelled") }}
      </router-link>
      <router-link
        :class="companyListItemClass"
        :to="{ name: ROUTE_NAMES.invoices.index, query: { company: props.company.id } }"
      >
        <Icon icon="invoice" class="sidebar-menu_item_icon" fill="#000" />
        {{ $t("Invoices") }}
      </router-link>
      <router-link
        :class="companyListItemClass"
        :to="{ name: ROUTE_NAMES.acts.index, query: { company: props.company.id } }"
      >
        <Icon icon="act" class="sidebar-menu_item_icon" fill="#000" />
        {{ $t("Acts") }}
      </router-link>
      <router-link
        :to="{ name: ROUTE_NAMES.subscriptions.audit, query: { company: props.company.id } }"
        :class="companyListItemClass"
      >
        <Icon icon="audit" class="sidebar-menu_item_icon" fill="#000" />
        {{ $t("Audit") }}
      </router-link>
    </template>

    <template v-if="company.registration_type === COMPANIES_REGISTRATION_TYPES.base">
      <router-link
        :to="{ name: ROUTE_NAMES.subscriptions.list, query: { company: props.company.id } }"
        :class="companyListItemClass"
      >
        <Icon icon="employees" class="sidebar-menu_item_icon" fill="#000" />
        {{ $t("Subscribers") }}
      </router-link>
      <router-link
        :class="companyListItemClass"
        :to="{ name: ROUTE_NAMES.invoices.index, query: { company: props.company.id } }"
      >
        <Icon icon="invoice-filled-icon" class="sidebar-menu_item_icon" fill="#000" />
        {{ $t("Invoices") }}
      </router-link>
      <router-link
        :class="companyListItemClass"
        :to="{ name: ROUTE_NAMES.acts.index, query: { company: props.company.id } }"
      >
        <Icon icon="act-filled-icon" class="sidebar-menu_item_icon" fill="#000" />
        {{ $t("Acts") }}
      </router-link>
      <router-link
        :to="{ name: ROUTE_NAMES.subscriptions.audit, query: { company: props.company.id } }"
        :class="companyListItemClass"
      >
        <Icon icon="audit-filled-icon" class="sidebar-menu_item_icon" fill="#000" />
        {{ $t("Audit") }}
      </router-link>
    </template>
  </nav>
</template>

<script setup>
import { computed, ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";

import Icon from "@/components/icons/Icon.vue";

import { ROUTE_NAMES } from "@/router";
import { COMPANIES_REGISTRATION_TYPES } from "@/store";
import ClipboardCopyButton from "../buttons/ClipboardCopyButton.vue";

const COMPANY_STATUS_DEFAULT_ICON = "building";
const COMPANY_STATUSES = [
  "company-status-no_order",
  "company-status-paid",
  "company-status-pay_waiting",
  "company-status-subscriptions_added",
];

const props = defineProps({
  company: {
    type: Object,
    required: true,
  },
});

const route = useRoute();

const isOpened = ref(false);

const companyStatusIcon = computed(() => {
  const icon = COMPANY_STATUSES[props.company.status];
  return icon || COMPANY_STATUS_DEFAULT_ICON;
});

const companyListItemClass = computed(() => {
  const classList = ["sidebar-menu_item"];

  if (route.query.company == props.company.id) {
    classList.push("sidebar-menu_item__current-company");
  }

  return classList;
});

function toggleSubmenuState(newState) {
  if (["open", "close"].includes(newState)) {
    isOpened.value = newState === "close";
  } else {
    isOpened.value = !isOpened.value;
  }
}

onBeforeMount(() => {
  const companyId = props.company.id;
  const { company } = route.query;

  if (companyId == company) {
    isOpened.value = true;
  }
});
</script>

<style lang="scss" scoped>
.sidebar-menu,
.sidebar-submenu {
  .subscriber-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 0 24px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    &_copy {
      display: block;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  &_item {
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 24px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover,
    &__active {
      background-color: #ffb309;
    }

    &_icon {
      margin-right: 15px;
      flex-shrink: 0;
    }
  }
}

.sidebar-submenu {
  display: none;

  &.opened {
    display: block;
  }

  .sidebar-menu_item {
    padding-left: 48px;

    &__current-company {
      &.router-link-active {
        background-color: #ffd26c;
      }
    }
  }
}
</style>
