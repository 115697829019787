<template>
  <label class="base-radio">
    <input type="radio" class="base-radio_input" :checked="isChecked" :value="props.value" @change="onChange" />
    <span class="base-radio_fake-input"></span>
    <p class="base-radio_label"><slot></slot></p>
  </label>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      required: true,
    },
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const isChecked = computed(() => props.modelValue === props.value);

    function onChange() {
      emit("update:modelValue", props.value);
    }
    return {
      props,
      isChecked,

      onChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.base-radio {
  display: inline-flex;
  width: fit-content;
  align-items: center;
  cursor: pointer;

  &_input {
    display: none;

    &:checked {
      & + .base-radio_fake-input {
        &::before {
          border-color: #346aed;
        }

        &::after {
          display: block;
        }
      }
      & ~ .base-radio_label {
        color: #444545;
      }
    }
  }

  &_fake-input {
    position: relative;
    width: 16px;
    height: 16px;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      border: 2px solid #999999;
      border-radius: 50%;
    }

    &::after {
      content: "";
      position: absolute;
      right: 3px;
      left: 3px;
      top: 3px;
      bottom: 3px;
      background-color: #346aed;
      border-radius: 50%;
      display: none;
    }
  }

  &_label {
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #999999;
  }

  &:hover {
    .base-radio_fake-input {
      &::before {
        border-color: #346aed;
      }
    }

    .base-radio_label {
      color: #444545;
    }
  }
}
</style>
