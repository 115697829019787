<template>
  <div class="layout-view-header">
    <div class="layout-view-header_top">
      <h2>{{ companyName }}</h2>
      <LanguageSwitcher class="language-switcher" />
    </div>
    <Banner class="layout-view-header_banner" type="error" :message="props.errorMessage" />
    <slot />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import Banner from "@/components/banners/Banner.vue";
import LanguageSwitcher from "@/components/language/LanguageSwitcher.vue";

import useStore from "@/store";

const route = useRoute();
const store = useStore();

const props = defineProps({
  errorMessage: {
    type: String,
    required: false,
    default: "",
  },
});

const companyName = computed(() => {
  const companyId = route.query.company;
  const company = store.companies.find(({ id }) => companyId == id);
  return company?.name;
});
</script>

<style lang="scss" scoped>
.layout-view-header {
  &_top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2em;
      padding: 0.2em 0;
    }

    .language-switcher {
      flex-shrink: 0;
      margin-left: 24px;
    }
  }

  &_banner {
    margin: 12px 0;
  }
}
</style>
