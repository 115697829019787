import createElement from "@/helpers/document/createElement";
import openBlobOtherWindow from "@/helpers/request/openBlobOtherWindow";

export default function (response) {
  const downloadLink = createElement("a");
  const file = new Blob([response.data], { type: response.data.type });
  const contentDisposition = response.headers["content-disposition"];

  file.name = contentDisposition.split("=")[1].replaceAll('"', "");

  openBlobOtherWindow(file, file.name, downloadLink);

  downloadLink.remove();
}
