<template>
  <div class="input_container">
    <span v-if="props.label" class="input_label">
      {{ $t(props.label) }}
      <Icon v-if="!props.disabled" icon="arrow-down" />
    </span>
    <input
      id="month_input"
      ref="inputRef"
      v-model="inputValue"
      placeholder="YYYY-MM"
      type="month"
      :class="{ error: errorState }"
      :disabled="props.disabled"
      v-bind="$attrs"
    />
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";

import Icon from "@/components/icons/Icon.vue";

import { convertForInput, convertFromInput } from "@/helpers/dates/monthInputConverter";
import { validateMonthInput } from "@/helpers/validators/date";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  modelValue: {
    type: String,
    default: "",
  },
});

const inputRef = ref(null);

const inputValue = ref("");
const errorState = ref(false);

function onValueChange(val) {
  const isDateValid = validateMonthInput(val);

  if (isDateValid) {
    errorState.value = false;
    inputRef.value.blur();
    emit("update:modelValue", convertFromInput(val));
  } else {
    errorState.value = true;
  }
}

onMounted(() => {
  if (props.modelValue) {
    inputValue.value = convertForInput(props.modelValue);
  }
});

watch(inputValue, onValueChange);
</script>

<style lang="scss" scoped>
.input_container {
  width: 240px;
  max-width: 100%;
  position: relative;

  .input_label {
    font-size: 13px;
    color: #7d7d7d;
    line-height: 2em;
  }

  input {
    background: #fff;
    width: 100%;
    height: 48px;
    padding: 0 24px;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    border: 1px solid rgba(0, 0, 0, 0);

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }

    &.error {
      border-color: #d63230;
    }
  }
}
</style>
