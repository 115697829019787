const PHONES_COMMON_REGEXP = /^[+]?[0-9() -]+$/;

const AM_PHONE_CODE = "374";
const AM_PHONE_DIGITS_LENGTH = 11;
const AM_PHONE_MASK_REGEXP = /(\+)?((?:374)\d{8})/;

const BY_PHONE_CODE = "375";
const BY_PHONE_DIGITS_LENGTH = 12;
const BY_PHONE_MASK_REGEXP = /(\+)?((?:375)(?:25|29|33|44)\d{7})/;

const CY_PHONE_CODE = "357";
const CY_PHONE_DIGITS_LENGTH = 11;
const CY_PHONE_MASK_REGEXP = /(\+)?((?:357)9\d{7})/;

const LT_PHONE_CODE = "370";
const LT_PHONE_DIGITS_LENGTH = 11;
const LT_PHONE_MASK_REGEXP = /(\+)?((?:370)\d{8})/;

const RU_PHONE_CODE = "7";
const RU_PHONE_DIGITS_LENGTH = 11;
const RU_PHONE_MASK_REGEXP = /(\+)?((?:79)\d{9})/;

const UA_PHONE_CODE = "380";
const UA_PHONE_DIGITS_LENGTH = 12;
const UA_PHONE_MASK_REGEXP = /(\+)?((?:380)(?:[5-9][0-9])\d{7})/;

export default {
  PHONES_COMMON_REGEXP,
  COUNTRIES: {
    am: {
      code: AM_PHONE_CODE,
      length: AM_PHONE_DIGITS_LENGTH,
      regexp: AM_PHONE_MASK_REGEXP,
    },
    by: {
      code: BY_PHONE_CODE,
      length: BY_PHONE_DIGITS_LENGTH,
      regexp: BY_PHONE_MASK_REGEXP,
    },
    cy: {
      code: CY_PHONE_CODE,
      length: CY_PHONE_DIGITS_LENGTH,
      regexp: CY_PHONE_MASK_REGEXP,
    },
    lt: {
      code: LT_PHONE_CODE,
      length: LT_PHONE_DIGITS_LENGTH,
      regexp: LT_PHONE_MASK_REGEXP,
    },
    ru: {
      code: RU_PHONE_CODE,
      length: RU_PHONE_DIGITS_LENGTH,
      regexp: RU_PHONE_MASK_REGEXP,
    },
    ua: {
      code: UA_PHONE_CODE,
      length: UA_PHONE_DIGITS_LENGTH,
      regexp: UA_PHONE_MASK_REGEXP,
    },
  },
};
