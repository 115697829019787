<template>
  <div class="outside">
    <LanguageSwitcher class="language" />
    <div class="container">
      <form action="#" @submit="submitForm">
        <div class="logo" :class="BRAND"></div>
        <h2>{{ $t("Login") }}</h2>
        <BaseInput
          :ref="(el) => (refs.phone = el)"
          v-model="state.phone"
          autofocus
          name="phone"
          :placeholder="$t('Phone number')"
          validator="phone"
          :error="state.errors.phone"
          :disabled="showCodeInput"
        />
        <template v-if="showCodeInput">
          <BaseInput
            :ref="(el) => (refs.code = el)"
            v-model="state.code"
            autocomplete="off"
            autofocus
            maxlength="4"
            name="code"
            :placeholder="$t('SMS code')"
            validator="sms_code"
            :error="state.errors.sms_code"
            @keyup.enter="submitForm"
          />
          <p>{{ $t("Do not receive SMS?") }}</p>
          <p v-if="counter === 0">{{ $t("Request resend code.") }}</p>
          <p v-else>{{ $tc("Request new SMS code after {n} seconds.", counter) }}</p>
        </template>

        <div v-if="showAgreement" class="agreement">
          <BaseCheckbox :value="state.agreement" @change="onAgreementChange" />
          <p>
            {{ $t("I agree with the ") }}
            <a :href="POLICY_LINKS[BRAND]"> {{ $t("Privacy Policy Statement") }}</a>
          </p>
        </div>

        <div class="buttons_container">
          <Button
            v-if="showCodeInput"
            button-type="light"
            :disabled="resendCodeButtonDisabled"
            :processing="state.resendCodeProcessing"
            @click.prevent="resendCode"
          >
            {{ $t("Resend") }}
          </Button>
          <Button :disabled="!isSubmitButtonEnabled" :processing="state.processing" type="submit">
            {{ $t("Login") }}
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from "vue";

import Button from "@/components/buttons/Button.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import LanguageSwitcher from "@/components/language/LanguageSwitcher.vue";

import useUserStore, { ACTION_LOGIN } from "@/store/user";
import { useTimer, useClearInputErrors } from "@/composables";
import request from "@/helpers/request";
import prepareErrors from "@/helpers/prepareErrors";
import clearPhone from "@/helpers/converters/clearPhone";
import { BRAND, POLICY_LINKS, URLS, VALIDATORS_CONFIG } from "@/config";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";

const LOGIN_STEPS = {
  phone: Symbol("phone"),
  code: Symbol("code"),
};

const storeUser = useUserStore();
const { counter, startTimer } = useTimer(60);

const refs = reactive({
  code: ref(null),
  phone: ref(null),
});
const state = reactive({
  code: "",
  errors: {},
  phone: "",
  processing: false,
  resendCodeProcessing: false,
  step: LOGIN_STEPS.phone,

  agreement: false,
});

const showAgreement = computed(() => state.step === LOGIN_STEPS.phone);
const showCodeInput = computed(() => state.step === LOGIN_STEPS.code);
const resendCodeButtonDisabled = computed(() => counter.value > 0);
const isSubmitButtonEnabled = computed(() => {
  const isCodeEntered = showCodeInput.value && state.code.length === VALIDATORS_CONFIG.SMS_CODE_LENGTH;
  return (!showCodeInput.value && state.agreement) || isCodeEntered;
});

async function resendCode() {
  if (resendCodeButtonDisabled.value) {
    return;
  }

  const data = {
    phone: clearPhone(state.phone),
  };

  await submitData({ data, resendCode: true });

  startTimer();
}

function submitForm(e) {
  e.preventDefault();

  if (state.processing) {
    return;
  }

  const errors = validateForm();

  if (errors) {
    state.errors = errors;
    return;
  } else {
    state.errors = {};
  }

  const data = {
    phone: clearPhone(state.phone),
  };

  if (showCodeInput.value) {
    data.sms_code = state.code;
  }

  submitData({ data });
}

async function submitData({ data, resendCode } = {}) {
  const processingKey = resendCode ? "resendCodeProcessing" : "processing";

  state[processingKey] = true;

  let url = URLS.API.tokenRequests;

  if (!resendCode && showCodeInput.value) {
    url = URLS.API.tokens;
  }

  try {
    const result = await request.post(url, data);
    onResponseSuccess(result);
  } catch (error) {
    onResponseError(error);
  }

  state[processingKey] = false;
}

function validateForm() {
  if (refs.phone.error) {
    return { phone: refs.phone.error };
  }

  if (state.phone.length === 0) {
    return { phone: "required_field" };
  }

  if (showCodeInput.value && state.code.length === 0) {
    return { sms_code: "required_field" };
  }

  return null;
}

function onResponseError(err) {
  const errors = prepareErrors(err);

  state.errors = errors;
}

function onResponseSuccess(data) {
  if (state.step === LOGIN_STEPS.phone) {
    state.step = LOGIN_STEPS.code;
    startTimer();
    return;
  }

  if (showCodeInput.value && data) {
    storeUser[ACTION_LOGIN](data);
  }
}

function onAgreementChange(value) {
  state.agreement = value;
}

watch(
  () => ({
    code: state.code,
    phone: state.phone,
  }),
  useClearInputErrors(state.errors, {
    code: "sms_code",
    phone: "phone",
  })
);
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.outside {
  position: relative;
  background-color: #fff;
  padding: 48px;
  min-height: 100vh;
  display: flex;
  align-items: stretch;

  .language {
    position: absolute;
    top: 24px;
    right: 48px;
    transform: translateY(-50%);
  }

  .container {
    background-color: #ffbe3f;
    background-image: url("../assets/images/bg_login_girl.png");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    border-radius: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 150px 0 100px 100px;

    form {
      position: relative;
      width: 480px;
      padding: 50px;
      background: #fff;
      border-radius: 8px;

      .logo {
        position: absolute;
        bottom: calc(100% + 40px);
        left: 0;
        width: 210px;
        height: 64px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;

        @include logo-full-backgrounds;
      }

      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 1em;
        padding: 0.2em 0;
        margin-bottom: 28px;
      }

      p {
        font-size: 13px;
        line-height: 1em;
        margin-top: 1em;
      }

      .agreement {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 12px;
        font-weight: 300;
        line-height: 24px;
        color: #000000;

        p {
          margin-top: 0;
          font-size: 11px;

          a {
            font-weight: 400;
            color: #2348a3;
            text-decoration: underline;
          }
        }
      }

      .buttons_container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;

        .btn + .btn {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
