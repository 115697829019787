import { defineStore } from "pinia";
import { MODAL_TYPES } from "@/store/modal";
import LOCALES from "@/config/locales";
import URLS from "@/config/urls";
import request from "@/helpers/request";
import { t } from "@/helpers/i18n/stubs";

const DATE = new Date();

export function getLastDayOfTheMonthToLocalFormat(date) {
  const lastDayOfTheMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  );

  //Сonverts last day of the mounth to DD.MM.YYYY format

  const preparedLastDayOfTheMonth = lastDayOfTheMonth.toLocaleDateString(
    LOCALES.LOCALE,
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }
  );

  return preparedLastDayOfTheMonth;
}

export const ACTIONS_MODES = {
  base: "base",
  modal: "modal",
};

export const COPAYMENTS_STATUSES = {
  REQUEST_ACTIVATION: 0,
  APPROVED: 1,
  UPGRADE_DECLINED: 2,
  REQUEST_UPGRADE: 3,
  UNSUBSCRIBED: 4,
  DOWNGRADED: 5,
  DECLINED: 6,
  ACTIVATED: 7,
  DELETED: 8,
};

export const FRONTEND_COPAYMENTS_STATUSES = {
  ACTIVE_UNTIL_NEXT_MONTH: 0,
};

export const PAYMENT_STATUSES = {
  NONE: 0,
  WAIT_BANK_CARD: 1,
  BANK_CARD_ADDED: 2,
  PENDING_PAYMENT: 3,
  PAID: 4,
  UNPAID: 5,
};

export const COPAYMENTS_STATUSES_TEXT = {
  [`${COPAYMENTS_STATUSES.REQUEST_ACTIVATION}`]: t("New"),
  [`${COPAYMENTS_STATUSES.APPROVED}`]: t("Approved"),
  [`${COPAYMENTS_STATUSES.UPGRADE_DECLINED}`]: t("Update declined"),
  [`${COPAYMENTS_STATUSES.REQUEST_UPGRADE}`]: t("Update"),
  [`${COPAYMENTS_STATUSES.UNSUBSCRIBED}`]: t("Unsubscribed"),
  [`${COPAYMENTS_STATUSES.DOWNGRADED}`]: t("Downgraded"),
  [`${COPAYMENTS_STATUSES.DECLINED}`]: t("Declined"),
  [`${COPAYMENTS_STATUSES.ACTIVATED}`]: t("Active"),
  [`${COPAYMENTS_STATUSES.UNPAID}`]: t("Unpaid"),
  [`${COPAYMENTS_STATUSES.DELETED}`]: t("Deleted"),
};

export const FRONTEND_COPAYMENTS_STATUSES_TEXT = {
  [`${FRONTEND_COPAYMENTS_STATUSES.ACTIVE_UNTIL_NEXT_MONTH}`]: t(
    `Active until ${getLastDayOfTheMonthToLocalFormat(DATE)}`
  ),
};

export const PAYMENT_STATUSES_TEXT = {
  [`${PAYMENT_STATUSES.WAIT_BANK_CARD}`]: t("Wait bank card"),
  [`${PAYMENT_STATUSES.BANK_CARD_ADDED}`]: t("Bank card added"),
  [`${PAYMENT_STATUSES.PENDING_PAYMENT}`]: t("Pending payment"),
  [`${PAYMENT_STATUSES.PAID}`]: t("Paid"),
  [`${PAYMENT_STATUSES.UNPAID}`]: t("Unpaid"),
};

export const LIST_NAMES = {
  active: "activeMembershipsList",
  pending: "pendingMembershipsList",
  canceled: "canceledMembershipsList",
};

export const useCopaymentsStore = defineStore("CopaymentsStore", {
  state: () => {
    return {
      [LIST_NAMES.active]: [],
      [LIST_NAMES.pending]: [],
      [LIST_NAMES.canceled]: [],

      processing: false,

      errors: {
        [LIST_NAMES.active]: null,
        [LIST_NAMES.pending]: null,
        [LIST_NAMES.canceled]: null,
        [MODAL_TYPES.approveChanges]: null,
      },
      errorMessage: "",
    };
  },
  actions: {
    async requestPendingMembershipsList(companyId) {
      this.errors[LIST_NAMES.pending] = null;
      this.errorMessage = "";

      try {
        const url = URLS.API.copayments.pendingMembershipsList(companyId);
        this[LIST_NAMES.pending] = await request(url);
      } catch (error) {
        this.errors[LIST_NAMES.pending] = error;
        this.errorMessage = error.response.data.message;
      }
    },
    async requestActiveMembershipsList(companyId) {
      this.errors[LIST_NAMES.active] = null;
      this.errorMessage = "";

      try {
        const url = URLS.API.copayments.activeMembershipsList(companyId);
        this[LIST_NAMES.active] = await request(url);
      } catch (error) {
        this.errors[LIST_NAMES.active] = error;
        this.errorMessage = error.response.data.message;
      }
    },
    async requestCanceledMembershipsList(companyId) {
      this.errors[LIST_NAMES.canceled] = null;
      this.errorMessage = "";

      try {
        const url = URLS.API.copayments.canceledMembershipsList(companyId);
        this[LIST_NAMES.canceled] = await request(url);
      } catch (error) {
        this.errors[LIST_NAMES.canceled] = error;
        this.errorMessage = error.response.data.message;
      }
    },
    async updateMembershipInfo(body, id, listName, mode = ACTIONS_MODES.base) {
      if (this.processing || listName === LIST_NAMES.canceled) {
        return;
      }

      this.processing = true;

      if (mode === ACTIONS_MODES.modal) {
        this.errors[MODAL_TYPES.approveChanges] = null;
      } else {
        this.errors[listName] = null;
      }
      this.errorMessage = "";

      try {
        const url = URLS.API.copayments.updateMembershipStatus(id);
        const updatedMembership = await request({
          data: body,
          method: "POST",
          url,
        });

        const membershipIndex = this[listName].findIndex(
          (membership) => membership.id === id
        );

        if (
          updatedMembership.status === COPAYMENTS_STATUSES.DECLINED &&
          listName !== LIST_NAMES.active
        ) {
          this[listName].splice(membershipIndex, 1);
        } else {
          this[listName].splice(membershipIndex, 1, updatedMembership);
        }
      } catch (error) {
        if (mode === ACTIONS_MODES.modal) {
          this.errors[MODAL_TYPES.approveChanges] = error;
        } else {
          this.errors[listName] = error;
        }

        this.errorMessage = error.response.data.message;
      } finally {
        this.processing = false;
      }
    },
    async multipleUpdateMembershipInfo(body, idArray, listName) {
      this.errors[listName] = null;
      this.errorMessage = "";

      for (const id of idArray) {
        if (this.errors[listName]) {
          return;
        }

        await this.updateMembershipInfo(body, id, listName);
      }
    },
  },
});
