function clickHandler(el, binding, e) {
  if (e.target !== el && !el.contains(e.target)) {
    if (binding.value) {
      binding.value(e);
    }
  }
}

export default {
  name: "click-outside",
  mounted: function (el, binding) {
    el.clickOutsideHandler = clickHandler.bind(null, el, binding);
    document.addEventListener("click", el.clickOutsideHandler);
  },
  beforeUnmount: function (el) {
    if (el.clickOutsideHandler) {
      document.removeEventListener("click", el.clickOutsideHandler);
    }
  },
};
