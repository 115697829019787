import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import {
  DEPLOYMENT_ENVIRONMENT,
  DEPLOYMENT_RELEASE,
  SENTRY_DSN,
} from "@/config";

export function initSentry(app, router) {
  if (SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      environment: DEPLOYMENT_ENVIRONMENT,
      release: DEPLOYMENT_RELEASE,
      sampleRate: 0.1,
      tracesSampleRate: 0.1,
      trackComponents: true,
    });
  }
}

export function captureSentryException(error) {
  Sentry.captureException(error);
}
