import { DEPLOYMENT_RELEASE } from "@/config";

const SHOW_DEPLOY_INFO_QUERY_KEY = "show-deploy-info";

export default () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParams = Object.fromEntries(urlSearchParams.entries());

  if (queryParams[SHOW_DEPLOY_INFO_QUERY_KEY]) {
    const message = {};

    Object.keys(process.env).forEach((key) => {
      if (key.startsWith("VUE_APP")) {
        message[key] = process.env[key];
      }
    });

    message.DEPLOYMENT_RELEASE = DEPLOYMENT_RELEASE;

    delete queryParams[SHOW_DEPLOY_INFO_QUERY_KEY];

    const path = window.location.pathname;
    const newUrlSearchParams = new URLSearchParams(queryParams);
    const newUrl = `${path}?${newUrlSearchParams.toString()}`;

    window.location.replace(newUrl);

    alert(JSON.stringify(message, null, 2));
  }
};
