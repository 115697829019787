<template>
  <LayoutView :error-message="state.error">
    <div class="controls">
      <h3>{{ $t("Act") }} #{{ actId }}</h3>
      <Button v-if="!state.processing && !state.error" button-type="light" @click="downloadAct">
        {{ $t("Download") }}
        <Icon class="btn-icon" icon="download" color="#000" />
      </Button>
    </div>

    <template v-if="!state.error">
      <SpinnerBrand v-if="state.processing" centered />
      <div v-else class="pdf-container">
        <iframe :src="state.fileSrc" type="application/pdf"></iframe>
      </div>
    </template>
  </LayoutView>
</template>

<script setup>
import { computed, reactive } from "@vue/reactivity";
import { useRoute } from "vue-router";

import Button from "@/components/buttons/Button.vue";
import Icon from "@/components/icons/Icon.vue";
import LayoutView from "@/layouts/LayoutView.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";

import URLS from "@/config/urls";
import request from "@/helpers/request";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import downloadBlob from "@/helpers/downloadBlob";

const route = useRoute();

const state = reactive({
  processing: true,
  error: null,

  fileResponse: null,
  fileSrc: null,
});

const actId = computed(() => route.params.actId);

async function fetchAct() {
  state.error = null;
  state.processing = true;

  const url = URLS.API.acts.download(actId.value);

  try {
    state.fileResponse = await request(url, {
      responseType: "blob",
      returnResponse: true,
    });

    state.fileSrc = window.URL.createObjectURL(state.fileResponse.data);
  } catch (xhrError) {
    const { errorMessage } = prepareAxiosErrors(xhrError);
    state.error = errorMessage;
  }

  state.processing = false;
}

function downloadAct() {
  downloadBlob(state.fileResponse);
}

fetchAct();
</script>

<style lang="scss" scoped>
.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-weight: 700;
    font-size: 15px;
  }

  .btn-icon {
    margin-left: 36px;
  }
}

.pdf-container {
  width: 100%;
  height: 1024px;
  margin-top: 20px;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
