<template>
  <div v-click-outside="closeMenu" class="button_container" :class="{ button_container__opened: state.isOpened }">
    <button v-if="props.actions" type="button" @click="toggleState">
      <Icon icon="dots-vertical" fill="#000" />
    </button>
    <ul class="actions-menu">
      <li v-for="action in props.actions" :key="`action_${action.key}`">
        <a
          href="#"
          @mouseenter="onMouseEnter(action.key)"
          @mouseleave="onMouseLeave(action.key)"
          @click.prevent="triggerEvent(action)"
        >
          {{ $t(action.title) }}
          <Icon class="icon" :icon="getActionIcon(action)" :color="getIconColor(action)" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export const ACTIONS = {
  download: "download",
  preview: "preview",
  approve: "approve",
  decline: "decline",
  restore: "restore",
  delete: "delete",
  cancel: "cancel",
  update: "update",
};

const ACTION_EVENT = "action";

const DEFAULT_ACTION_ICONS = {
  [ACTIONS.download]: "download",
  [ACTIONS.preview]: "preview",
};

const DEFAULT_ACTION_ICONS_COLORS = {
  download: "#346AED",
  preview: "#79C99E",
  approve: "#79C99E",
  decline: "#D63230",
  restore: "#79C99E",
  delete: "#D63230",
  update: "#79C99E",
};
</script>

<script setup>
import { reactive } from "vue";

import Icon, { DEFAULT_ICON_FILL_COLOR } from "@/components/icons/Icon.vue";

const emit = defineEmits([ACTION_EVENT, "actions:show", "actions:close"]);

const props = defineProps({
  actions: {
    type: Array,
    required: true,
  },
});

const actionsKeysList = props.actions?.map((action) => action.key);
const defaultHoverState = actionsKeysList?.reduce((hover, key) => Object.assign({}, hover, { [key]: false }), {});

const state = reactive({
  isOpened: false,
  hover: defaultHoverState,
});

function closeMenu() {
  toggleState("close");
}

function getIconColor(action) {
  if (state.hover[action.key]) {
    return DEFAULT_ACTION_ICONS_COLORS[action.key];
  }
  return DEFAULT_ICON_FILL_COLOR;
}

function onMouseEnter(key) {
  state.hover[key] = true;
}

function onMouseLeave(key) {
  state.hover[key] = false;
}

function toggleState(newState) {
  state.isOpened = newState === "close" ? false : !state.isOpened;

  if (state.isOpened) {
    emit("actions:show");
  } else {
    emit("actions:close");
  }
}

function triggerEvent(action) {
  emit(ACTION_EVENT, action.key);
  toggleState("close");
}

function getActionIcon(action) {
  return action.icon || DEFAULT_ACTION_ICONS[action.key];
}
</script>

<style lang="scss" scoped>
.button_container {
  width: 24px;
  height: 24px;
  position: relative;

  button {
    background: none;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .actions-menu {
    position: absolute;
    top: calc(100% + 12px);
    right: 0;
    list-style-type: none;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
    display: none;
    z-index: 1;

    li {
      display: flex;
      align-items: center;
      min-width: 238px;
      min-height: 48px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;

      &:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:hover {
        background-color: #f0f0f0;

        a {
          color: #000;
        }
      }

      a {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #666;
        cursor: pointer;
        white-space: nowrap;

        .icon {
          margin-left: 24px;
        }
      }
    }
  }

  &__opened {
    button {
      opacity: 1;
    }

    .actions-menu {
      display: block;
    }
  }
}
</style>
