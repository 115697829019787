const state = {
  inited: false,
  handlers: [],
};

function addOutsideClickHandler(handler, e) {
  e?.stopPropagation();
  documentClickHandler();

  state.handlers.push(handler);
}

function removeOutsideClickHandler(handler) {
  state.handlers = state.handlers.filter((h) => h !== handler);
}

function documentClickHandler() {
  state.handlers.forEach((handler) => {
    handler();
    removeOutsideClickHandler(handler);
  });
}

export default function useClickOutside() {
  if (!state.inited) {
    state.inited = true;
    document.addEventListener("click", documentClickHandler);
  }

  return {
    addOutsideClickHandler,
    removeOutsideClickHandler,
  };
}
