import createAxios from "./createAxios";
import {
  defaultErrorInterceptor,
  requestSuccessInterceptor,
} from "./interceptors";

const uploadClient = createAxios({
  headers: { "Content-Type": "multipart/form-data" },
});

uploadClient.interceptors.request.use(
  requestSuccessInterceptor,
  defaultErrorInterceptor
);

export default uploadClient;
