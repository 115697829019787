<template>
  <div class="info-banner" :class="elementClass">
    <Icon icon="danger" fill="#d63230" class="info-banner_icon" />
    <p class="info-banner_message">{{ props.message }}</p>
  </div>
</template>

<script setup>
import { computed } from "vue";

import Icon from "@/components/icons/Icon.vue";

const BASE_CLASS = "info-banner";

const props = defineProps({
  message: {
    type: String,
    default: "",
  },
  visible: {
    type: Boolean,
    default: true,
  },
});

const elementClass = computed(() => ({
  [`${BASE_CLASS}__visible`]: props.visible && !!props.message?.length,
}));
</script>

<style lang="scss" scoped>
.info-banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff6f5;
  border-radius: 8px;
  padding: 10px 12px;
  visibility: hidden;
  min-height: 44px;

  &__visible {
    visibility: visible;
  }

  &_message {
    color: #d63230;
    font-weight: 500;
    font-size: 16px;
    margin-left: 7px;
  }

  &_icon {
    flex-shrink: 0;
  }
}
</style>
