import createAxios from "./createAxios";
import {
  defaultErrorInterceptor,
  requestSuccessInterceptor,
  responseErrorInterceptor,
  responseSuccessInterceptor,
} from "./interceptors";

const axiosClient = createAxios();

axiosClient.interceptors.request.use(
  requestSuccessInterceptor,
  defaultErrorInterceptor
);

axiosClient.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor
);

export default axiosClient;

export { default as download } from "./download";
export { default as upload } from "./upload";
