<template>
  <svg width="18" height="18" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M405.333 42.6667H384V21.3333C384 15.6754 381.752 10.2492 377.752 6.24839C373.751 2.24761 368.325 0 362.667 0C357.009 0 351.582 2.24761 347.582 6.24839C343.581 10.2492 341.333 15.6754 341.333 21.3333V42.6667H170.667V21.3333C170.667 15.6754 168.419 10.2492 164.418 6.24839C160.417 2.24761 154.991 0 149.333 0C143.675 0 138.249 2.24761 134.248 6.24839C130.248 10.2492 128 15.6754 128 21.3333V42.6667H106.667C78.3873 42.7005 51.2759 53.9495 31.2794 73.946C11.2828 93.9426 0.0338743 121.054 0 149.333L0 405.333C0.0338743 433.613 11.2828 460.724 31.2794 480.721C51.2759 500.717 78.3873 511.966 106.667 512H405.333C433.613 511.966 460.724 500.717 480.721 480.721C500.717 460.724 511.966 433.613 512 405.333V149.333C511.966 121.054 500.717 93.9426 480.721 73.946C460.724 53.9495 433.613 42.7005 405.333 42.6667ZM42.6667 149.333C42.6667 132.359 49.4095 116.081 61.4118 104.079C73.4142 92.0762 89.6928 85.3333 106.667 85.3333H405.333C422.307 85.3333 438.586 92.0762 450.588 104.079C462.591 116.081 469.333 132.359 469.333 149.333V170.667H42.6667V149.333ZM405.333 469.333H106.667C89.6928 469.333 73.4142 462.591 61.4118 450.588C49.4095 438.586 42.6667 422.307 42.6667 405.333V213.333H469.333V405.333C469.333 422.307 462.591 438.586 450.588 450.588C438.586 462.591 422.307 469.333 405.333 469.333Z"
        fill="black"
      />
      <path
        d="M256 352C273.673 352 288 337.673 288 320C288 302.327 273.673 288 256 288C238.327 288 224 302.327 224 320C224 337.673 238.327 352 256 352Z"
        fill="black"
      />
      <path
        d="M149.333 352C167.007 352 181.333 337.673 181.333 320C181.333 302.327 167.007 288 149.333 288C131.66 288 117.333 302.327 117.333 320C117.333 337.673 131.66 352 149.333 352Z"
        fill="black"
      />
      <path
        d="M362.667 352C380.34 352 394.667 337.673 394.667 320C394.667 302.327 380.34 288 362.667 288C344.993 288 330.667 302.327 330.667 320C330.667 337.673 344.993 352 362.667 352Z"
        fill="black"
      />
    </g>
  </svg>
</template>
