<template>
  <div class="spinner" :class="`spinner__${props.theme}`">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</template>

<script>
export const SPINNER_THEMES = {
  dark: "dark",
  light: "light",
};

export const DEFAULT_SPINNER_THEME = SPINNER_THEMES.light;
</script>

<script setup>
const props = defineProps({
  theme: {
    type: String,
    default: SPINNER_THEMES.dark,
    validator: (val) => Object.values(SPINNER_THEMES).includes(val),
  },
});
</script>

<style lang="scss" scoped>
.spinner {
  width: 70px;
  text-align: center;

  & > div {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    margin: 0 5px;
  }

  &__dark {
    & > div {
      background-color: #000;
    }
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
