<template>
  <div class="modal_container">
    <div class="modal_row header">
      <h3>{{ $t("Import employees") }}</h3>
      <a href="#" class="modal_close" @click.prevent="close">
        <Icon icon="modal-close" fill="#000" />
      </a>
    </div>
    <div class="modal_row modal_row_import-info">
      <div class="col-flex col-40">
        <MonthPicker v-model="modalStorePayloadDate" :disabled="true" :label="$t('Period')" />
      </div>
      <div class="col-flex col-50">
        <p>
          {{ $t("Download file in cvs, xls, xlsx format according to the") }}
          <a href="#" @click.prevent="downloadTemplate">{{ $t("template") }}</a>
        </p>
      </div>
    </div>
    <div class="modal_row file_upload">
      <FileDocIcon :fill="fileIconColor" />
      <p v-if="state.file">{{ selectedFileName }}</p>
      <label
        v-else
        for="fileInput"
        v-html="$t('{0}Select file{1} or grag it in this area', [`<a href='#'>`, '</a>'])"
      ></label>
      <input id="fileInput" type="file" @input="onFileSelect" />
    </div>
    <div class="modal_row buttons">
      <Button button-type="light" @click.prevent="close">{{ $t("Cancel") }}</Button>
      <Button :processing="state.processing" :disabled="!state.file" @click="uploadFile">{{ $t("Import") }}</Button>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";

import Button from "@/components/buttons/Button.vue";
import FileDocIcon from "@/components/icons/FileDocIcon.vue";
import Icon from "@/components/icons/Icon.vue";
import MonthPicker from "../inputs/datepicker/MonthPicker.vue";

import useStore, { ACTION_FETCH_SUBSCRIPTIONS } from "@/store";
import useModalStore from "@/store/modal";
import useImportStore from "@/store/import";

import { download, upload } from "@/helpers/request";

import URLS from "@/config/urls";
import { ROUTE_NAMES } from "@/router";

const route = useRoute();
const router = useRouter();
const store = useStore();
const importStore = useImportStore();
const modalStore = useModalStore();

const emit = defineEmits(["close"]);

const state = reactive({
  processing: false,
  file: null,
});

const fileIconColor = computed(() => (state.file ? "#000" : "#888"));
const selectedFileName = computed(() => state.file?.name);

const modalStorePayloadDate = computed(() => modalStore.payload?.date);

function close() {
  emit("close");
}

function onFileSelect(e) {
  state.file = e.target.files[0];
}

async function downloadTemplate() {
  const { company } = route.query;
  const url = URLS.API.templateDownload(company);

  download(url);
}

async function uploadFile() {
  if (!state.file || state.processing) {
    return;
  }

  state.processing = true;

  const { company } = route.query;
  const date = modalStorePayloadDate.value;
  const url = URLS.API.subscriptionsUploadTabular(company, date);
  const data = new FormData();

  data.append("tabular_file", state.file);

  try {
    await upload({
      data,
      method: "POST",
      url,
    });
    await store[ACTION_FETCH_SUBSCRIPTIONS](company, date, true);
  } catch (error) {
    const data = error.response.data.data.map((el, i) => ({ ...el, id: i + 1 }));

    importStore.filename = selectedFileName;
    importStore.data = data;
    importStore.date = modalStore.payload.date;

    router.push({ name: ROUTE_NAMES.subscriptions.import, query: route.query });
  }

  state.processing = false;
  close();
}
</script>
