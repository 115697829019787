<template>
  <div class="view-top">
    <h2>{{ companyName }}</h2>
    <LanguageSwitcher class="language-switcher" />
  </div>
  <template v-if="!USER_ROLE.manager">
    <h3 v-if="isEditablePeriodTemporarilyChanged">
      {{ $t("Access to employee editing is available from the {0} to the {1} of the month.", updatePeriod) }}
    </h3>
    <h3 v-else>
      {{
        $t(
          "Access to employee editing is temporarily available from the {0} to the {1} of the current month.",
          updatePeriod
        )
      }}
    </h3>
  </template>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import isEqual from "lodash/isEqual";

import LanguageSwitcher from "@/components/language/LanguageSwitcher.vue";

import useStore from "@/store";
import useUserRole from "@/composables/useUserRole";

const route = useRoute();
const store = useStore();
const { USER_ROLE } = useUserRole();

const sortNumbersFunction = (a, b) => a - b;

const companyName = computed(() => {
  const companyId = route.query.company;
  const company = store.companies.find(({ id }) => companyId == id);
  return company?.name;
});
const updatePeriod = computed(() => {
  const updatePeriod = Array.from(store.permissions.update_period).map(Number);
  updatePeriod.sort(sortNumbersFunction);
  return updatePeriod;
});
const isEditablePeriodTemporarilyChanged = computed(() => {
  const defaultPeriod = Array.from(store.permissions.default_period).map(Number);
  defaultPeriod.sort(sortNumbersFunction);

  return isEqual(defaultPeriod, updatePeriod.value);
});
</script>

<style lang="scss" scoped>
.view-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .language-switcher {
    flex-shrink: 0;
    margin-left: 24px;
  }
}

h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2em;
  padding: 0.2em 0;
}

h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2em;
  padding: 0.2em 0;
}
</style>
