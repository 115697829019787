import { dateFormatter } from "@/helpers/formatters";

/**
 * Takes date in "YYMM" format and allows work with it more comfortable
 * @param {String} date
 */
export default function prepareDate(date) {
  let month;
  let year;

  if (date) {
    month = Number(date.slice(2));
    year = Number(date.slice(0, 2));
  } else {
    const date = new Date();
    const currentYear = date.getFullYear().toString().slice(2);
    const currentMonth = date.getMonth() + 1;

    year = Number(currentYear);
    month = Number(currentMonth);
  }

  const getRaw = (y = year, m = month) =>
    `${dateFormatter(y)}${dateFormatter(m)}`;

  const getPrevMonth = () => {
    let prevMonth = month - 1;
    let prevYear = year;

    if (month <= 1) {
      prevMonth = 12;
      prevYear -= 1;
    }

    return prepareDate(getRaw(prevYear, prevMonth));
  };

  const getNextMonth = () => {
    let nextMonth = month + 1;
    let nextYear = year;

    if (month >= 12) {
      nextMonth = 1;
      nextYear += 1;
    }

    return prepareDate(getRaw(nextYear, nextMonth));
  };

  return {
    month,
    year,
    getNextMonth,
    getPrevMonth,
    getRaw,
  };
}
