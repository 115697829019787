<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
    <g fill="#444545" clip-path="url(#a)">
      <path
        d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 14.667A6.666 6.666 0 1 1 14.667 8 6.674 6.674 0 0 1 8 14.667Z"
      />
      <path
        d="M8 3.333A.667.667 0 0 0 7.333 4v5.333a.667.667 0 1 0 1.334 0V4A.667.667 0 0 0 8 3.333ZM8.667 12a.667.667 0 1 0-1.334 0 .667.667 0 0 0 1.334 0Z"
      />
    </g>
    <defs>
      <clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z" /></clipPath>
    </defs>
  </svg>
</template>
