<template>
  <div class="sidebar-search" :class="elementClass">
    <div class="sidebar-search_input">
      <Icon class="sidebar-search_input_icon" icon="search" fill="#000" />
      <input v-model="value" type="text" :placeholder="$t('Search')" @blur="onBlur" @focus="onFocus" />
    </div>
    <p v-if="props.message" class="sidebar-search_message">{{ props.message }}</p>
  </div>
</template>

<script setup>
import { computed, reactive } from "vue";

import Icon from "@/components/icons/Icon.vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  message: {
    type: String,
    default: null,
  },
  modelValue: {
    type: String,
    default: null,
  },
});

const state = reactive({
  focused: false,
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newVal) {
    emit("update:modelValue", newVal);
  },
});

const elementClass = computed(() => ({
  "sidebar-search__focused": state.focused,
}));

function onBlur() {
  state.focused = false;
}

function onFocus() {
  state.focused = true;
}
</script>

<style lang="scss" scoped>
.sidebar-search {
  margin-top: 34px;
  padding: 0 24px;
  opacity: 0.3;

  &_input {
    position: relative;

    &_icon {
      position: absolute;
      top: 50%;
      left: 18px;
      transform: translate(-8px, -8px);
    }

    input {
      background: none;
      width: 100%;
      height: 36px;
      border-bottom: 1px solid #000;
      padding: 0 8px 0 44px;
    }
  }

  &_message {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5em;
    padding: 0.3em 0;
    // color: rgba(0, 0, 0, 0.2);
    color: #333;
  }

  &__focused {
    opacity: 1;
  }
}
</style>
