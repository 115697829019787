<template>
  <div class="view">
    <SubscriptionsViewHeaderInfo />
    <Banner type="error" :message="$t(errorBannerMessage, { deactivationReason })" />
    <Banner v-if="isSubscriptionNameChanged" type="warning" :message="$t('Employee details have been changed.')" />
    <Banner v-if="showMigrateSubscriptionsMessage" class="banner" type="primary">
      {{ $t("The list of subscriptions is empty.") }}
      <a href="#" @click.prevent="copyPreviousSubscriptionsList">
        {{ $t("Copy last month's data?") }}
      </a>
    </Banner>
    <div class="controls">
      <BaseInput
        v-model="state.searchString"
        class="controls_search"
        icon="search"
        :placeholder="$t('Employee search')"
        hide-error-block
      />
      <div class="controls_buttons">
        <Button button-type="light" :tooltip-text="TOOLTIPS_TEXTS.download" @click="downloadPreviousSubscriptions">
          <Icon icon="download" width="24" height="24" color="#444545" />
        </Button>
        <Button
          :disabled="!isEditable"
          :tooltip-text="TOOLTIPS_TEXTS.import"
          button-type="light"
          @click="openModal(MODAL_TYPES.employeesImport)"
        >
          <Icon icon="import-list" :color="!isEditable ? '#7D7D7D' : '#444545'" />
        </Button>
        <Button
          button-type="light"
          :disabled="!isEditable"
          :tooltip-text="TOOLTIPS_TEXTS.add"
          @click="openModal(MODAL_TYPES.employeeEdit, 'add')"
        >
          <Icon icon="add-user" :color="!isEditable ? '#7D7D7D' : '#444545'" />
        </Button>
        <Button
          :disabled="!isInvoiceGenerationAllowed"
          :tooltip-text="generateInvoiceButtonTooltipText"
          @click="openGenerateInvoiceModal"
        >
          <Icon icon="invoice" :color="!isInvoiceGenerationAllowed ? '#8ba4fa' : 'white'" width="24" height="24" />
          {{ $t("Generate an invoice") }}
        </Button>
      </div>
    </div>
    <div v-if="store.processingSubscriptions" class="spinner_container">
      <Spinner />
    </div>
    <div v-else class="content">
      <div class="subscriptions">
        <div class="subscriptions_controls">
          <span class="name">
            <a href="#" @click.prevent="updateSorting('name')">
              {{ $t("Name") }}
              <IconSort
                class="sort_direction"
                :direction="getSortIconDirection('name')"
                :active="isSortIconActive('name')"
              />
            </a>
          </span>
          <span class="level">
            <a href="#" @click.prevent="updateSorting('level')">
              {{ $t("Subscription") }}
              <IconSort
                class="sort_direction"
                :direction="getSortIconDirection('level')"
                :active="isSortIconActive('level')"
              />
            </a>
          </span>
          <span class="phone">
            <a href="#" @click.prevent="updateSorting('phone')">
              {{ $t("Phone number") }}
              <IconSort
                class="sort_direction"
                :direction="getSortIconDirection('phone')"
                :active="isSortIconActive('phone')"
              />
            </a>
          </span>
        </div>
        <ol class="subscriptions_list">
          <li v-for="(person, i) in filteredSubscriptionsList" :key="`subscriber-${store.selectedDate}-${person.id}`">
            <span class="counter">{{ i + 1 }}.</span>
            <span class="name">
              <span
                v-if="person.prev_name"
                class="icon-attention"
                :tooltip="$t(`Previous name is: '{0}'`, [person.prev_name])"
                tooltip-flow="up"
              >
                <Icon icon="attention-round" />
              </span>
              <span>
                {{ person.name }}
              </span>
            </span>
            <span class="level">{{ person.level }}</span>
            <span v-hide-textcontent:phone="getHideTextcontentParams(person)" class="phone">{{ person.phone }}</span>
            <span class="copy">
              <ClipboardCopyButton
                class="copy-personal-info"
                :value="[person.name, person.phone].join(', ')"
                @click="makePersonalDataVisible(person)"
              />
            </span>
            <span class="actions">
              <ActionsButton
                v-if="isEditable"
                @edit="openModal(MODAL_TYPES.employeeEdit, 'edit', person)"
                @delete="openModal(MODAL_TYPES.action, 'delete', person)"
              />
            </span>
          </li>
        </ol>
      </div>
      <SubscriptionsStatistics />
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, computed, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import useStore, {
  ACTION_FETCH_SUBSCRIPTIONS,
  ACTION_MIGRATE_SUBSCRIPTIONS,
  ACTION_GENERATE_INVOICE_BY_COMPANY_ID,
} from "@/store";
import useModalStore, { ACTION_OPEN_MODAL, MODAL_TYPES } from "@/store/modal";
import useStorage from "@/helpers/storage";

import ActionsButton from "@/components/buttons/ActionsButton.vue";
import Banner from "@/components/banners/Banner.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import ClipboardCopyButton from "@/components/buttons/ClipboardCopyButton.vue";
import Icon from "@/components/icons/Icon.vue";
import IconSort from "@/components/icons/IconSort.vue";
import Spinner from "@/components/loaders/Spinner.vue";
import SubscriptionsStatistics from "./components/SubscriptionsStatistics.vue";
import SubscriptionsViewHeaderInfo from "./components/SubscriptionsViewHeaderInfo";

import URLS from "@/config/urls";
import prepareDate from "@/helpers/dates/prepareDate";
import download from "@/helpers/request/download";
import { EMPTY_MESSAGE, t } from "@/helpers/i18n/stubs";
import { STORAGE_KEYS } from "@/config";

const MESSAGES = {
  dataReceivingError: t("Data receiving error"),
  deactivatedCompany: t(
    "Your company currently is disabled. You can not add or edit subscribers. Please, contact with your manager."
  ),
  deactivatedCompanyReason: t(
    'Your company currently is disabled for the reason: "{deactivationReason}". You can not add or edit subscribers. Please, contact with your manager.'
  ),
};

const TOOLTIPS_TEXTS = {
  minOrderItems: t("The number of employees should not be below the minimum."),
  nextMonth: t("The invoice generation is possible only for the next month."),
  download: t("Download the list for the previous month."),
  import: t("Upload the employee list."),
  add: t("Add an employee."),
};

const route = useRoute();
const router = useRouter();
const store = useStore();
const modalStore = useModalStore();
const storage = useStorage();

const state = reactive({
  sortField: "id",
  sortDirection: "asc",
  searchString: "",
});

const isCompanyActive = computed(() => store.permissions?.activated ?? true);
const deactivationReason = computed(() => store.permissions?.deactivation_cause);
const allowMigrateMonth = computed(() => store.permissions?.allow_migrate_month);

const currentCompany = computed(() => store.getCompanyById(route.query.company));
const currentCompanyCountry = computed(() => currentCompany.value.country);

const showMigrateSubscriptionsMessage = computed(() => {
  const isSubscriptionsEmptyListLoaded = !store.processingSubscriptions && store.subscriptions.length === 0;
  const isMigrationMonthSelected = allowMigrateMonth.value === prepareDate(store.selectedDate).getRaw();
  return isSubscriptionsEmptyListLoaded && isMigrationMonthSelected;
});

const isSubscriptionNameChanged = computed(() => store.subscriptions.some(({ prev_name }) => !!prev_name));

const isEditable = computed(() => {
  const isMonthEditable = !!store.permissions?.allow_months?.includes(store.selectedDate);
  return isCompanyActive.value && isMonthEditable;
});

const errorBannerMessage = computed(() => {
  if (store.error) {
    return MESSAGES.dataReceivingError;
  }

  const deactivatedCompanyMessage =
    deactivationReason.value && deactivationReason.value.length > 0
      ? MESSAGES.deactivatedCompanyReason
      : MESSAGES.deactivatedCompany;

  return isCompanyActive.value ? EMPTY_MESSAGE : deactivatedCompanyMessage;
});

const sortedSubscriptionsList = computed(() =>
  store.sortedSubscriptionsList(store.selectedDate, state.sortField, state.sortDirection)
);

const isMonthEnableInvoiceGeneration = computed(
  () => !!store.permissions?.allow_months_to_generate_invoice?.includes(store.selectedDate)
);
const isMinItemsInList = computed(() => sortedSubscriptionsList.value.length >= currentCompany.value.min_order_items);

const isInvoiceGenerationAllowed = computed(
  () => isCompanyActive.value && isMonthEnableInvoiceGeneration.value && isMinItemsInList.value
);

const generateInvoiceButtonTooltipText = computed(() => {
  if (!isMonthEnableInvoiceGeneration.value) {
    return TOOLTIPS_TEXTS.nextMonth;
  } else if (!isMinItemsInList.value) {
    return TOOLTIPS_TEXTS.minOrderItems;
  }

  return "";
});

const filteredSubscriptionsList = computed(() =>
  sortedSubscriptionsList.value.filter(({ name, phone }) => {
    const searchData = [name, phone].join(" ").toLowerCase();
    return searchData.includes(state.searchString.toLowerCase());
  })
);

function updateSorting(field) {
  if (field !== state.sortField) {
    state.sortDirection = "asc";
  } else {
    state.sortDirection = state.sortDirection === "asc" ? "desc" : "asc";
  }

  state.sortField = field;
}

async function fetchSubscriptions(newDate, oldDate) {
  if (newDate !== oldDate) {
    let { company } = route.query;

    if (!company) {
      company = store.companies[0].id;

      router.push({ query: { company } });
    }

    await store[ACTION_FETCH_SUBSCRIPTIONS](company, store.selectedDate);
  }
}

function downloadPreviousSubscriptions() {
  const { company } = route.query;
  const currentDate = prepareDate(store.selectedDate);
  const previousDate = currentDate.getPrevMonth().getRaw();
  const url = URLS.API.subscriptionsDownload(company, previousDate);

  const downloadErrorCallback = {
    fn: openModal,
    args: [MODAL_TYPES.error],
  };

  download(url, downloadErrorCallback);
}

async function generateInvoice(companyId) {
  await store[ACTION_GENERATE_INVOICE_BY_COMPANY_ID](companyId);
}

function openGenerateInvoiceModal() {
  const { company } = route.query;
  const modalPayload = {
    callback: generateInvoice.bind(null, company),
    company,
  };

  openModal(MODAL_TYPES.invoice, "add", modalPayload);
}

function openModal(type, mode, payload = null) {
  const COMMON_MODAL_PAYLOAD = {
    date: store.selectedDate,
    country: currentCompanyCountry.value,
  };

  const modalPayload = Object.assign(COMMON_MODAL_PAYLOAD, payload);
  modalStore[ACTION_OPEN_MODAL]({ type, mode }, modalPayload);
}

async function copyPreviousSubscriptionsList() {
  const { company } = route.query;
  const currentDate = prepareDate(store.selectedDate).getRaw();

  await store[ACTION_MIGRATE_SUBSCRIPTIONS](company, currentDate);
}

function makePersonalDataVisible({ id }) {
  const updatedSubscriptionsList = Array.from(store.subscriptions);
  const person = updatedSubscriptionsList.find((person) => person.id === id);

  person.__showPersonalData = true;

  store.subscriptions = updatedSubscriptionsList;
}

const isSortIconActive = (field) => field === state.sortField;
const getSortIconDirection = (field) => (isSortIconActive(field) ? state.sortDirection : "asc");

const getHideTextcontentParams = (person) => ({
  country: currentCompanyCountry.value,
  visible: person.__showPersonalData,
});

function showInstructions() {
  const isInstructionsAccepted = storage.get(STORAGE_KEYS.instructionsAccepted);

  if (!isInstructionsAccepted) {
    modalStore[ACTION_OPEN_MODAL]({ type: MODAL_TYPES.instructions });
  }
}

onBeforeMount(() => {
  const { force_date, ...clearedQuery } = route.query;

  if (force_date) {
    store.selectedDate = force_date;

    router.push({ query: clearedQuery });
  }

  showInstructions();
});

watch(
  () => store.selectedDate,
  (newVal, oldVal) => {
    fetchSubscriptions(newVal, oldVal);
  },
  { immediate: true }
);

watch(
  () => route.query.company,
  (companyId) => {
    if (companyId) {
      fetchSubscriptions(store.selectedDate, null);
    }
  }
);
</script>

<style lang="scss" scoped>
.view {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 24px;

  .banner {
    margin-top: 8px;

    a {
      color: #346aed;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .controls {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 12px;

    &_search {
      width: auto;
      max-width: 308px;
      flex-grow: 1;
    }

    &_buttons {
      display: flex;
      align-items: flex-end;
      gap: 30px;
    }
  }

  .spinner_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
  }

  .content {
    margin-top: 14px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 24px;

    .subscriptions {
      padding-bottom: 72px;

      .subscriptions_controls {
        margin-bottom: 6px;
        padding: 0 8px;

        span {
          display: inline-block;
          font-weight: 400;
          font-size: 13px;
          color: #7d7d7d;

          &.name {
            width: 55%;
          }

          &.level {
            width: 15%;
          }

          &.phone {
            width: 25%;
          }

          a {
            &:hover {
              text-decoration: underline;
            }

            .sort_direction {
              &.active {
                color: #000;
              }
            }
          }
        }
      }

      .subscriptions_list {
        li {
          font-weight: 500;
          font-size: 16px;
          line-height: 1.5em;
          padding: 1em 8px;
          display: flex;
          align-items: center;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0);
          position: relative;

          &:hover,
          &.active {
            border-color: rgba(0, 0, 0, 0.3);
          }

          span {
            display: inline-block;

            &.counter {
              width: 5%;
              align-self: flex-start;
              text-align: left;
            }

            &.name {
              width: 45%;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              .icon-attention {
                margin-right: 5px;

                svg {
                  float: left;
                }
              }
            }

            &.level {
              width: 15%;
            }

            &.phone {
              width: 25%;
            }

            &.copy {
              width: 5%;

              .copy-personal-info {
                display: block;
                width: 20px;
                height: 20px;
                cursor: pointer;
                opacity: 0.6;

                &:hover {
                  opacity: 1;
                }
              }
            }

            &.actions {
              width: 5%;
            }
          }
        }
      }
    }

    .statistics {
      h2 {
        font-weight: 400;
        font-size: 16px;
      }

      p {
        line-height: 1.5em;
        font-weight: 400;
        margin-top: 0.4em;

        &.total_records {
          margin-top: 1.5em;
          font-size: 16px;
          color: #888;
        }
      }
    }
  }
}
</style>
