import { defineStore } from "pinia";

import getLanguage from "@/helpers/i18n/getLanguage";
import router, { ROUTE_NAMES } from "@/router";
import useStorage from "@/helpers/storage";
import { STORAGE_KEYS } from "@/config";
import i18n from "@/i18n";

export const STORE_NAMESPACE = "user";

export const ACTION_LOGIN = "login";
export const ACTION_LOGOUT = "logout";
export const ACTION_UPDATE_LANGUAGE = "updateLanguage";

const storage = useStorage();

export default defineStore(STORE_NAMESPACE, {
  state: () => {
    const language = getLanguage();
    const token = storage.get(STORAGE_KEYS.token);
    const user = storage.get(STORAGE_KEYS.user);

    let name = null;
    let position = null;

    if (user) {
      name = user.name;
      position = user.position;
    }

    return {
      isAuthenticated: Boolean(token && user),
      token,
      name,
      position,
      language,
    };
  },
  actions: {
    [ACTION_LOGIN](data) {
      const { name, position, token } = data;

      storage.save(token, STORAGE_KEYS.token);
      storage.save({ name, position }, STORAGE_KEYS.user);

      this.token = token;
      this.isAuthenticated = true;
      this.name = name;
      this.position = position;

      router.push({ name: ROUTE_NAMES.home });
    },
    async [ACTION_LOGOUT]() {
      this.isAuthenticated = false;
      storage.clear([STORAGE_KEYS.token, STORAGE_KEYS.user]);
      await router.push({ name: ROUTE_NAMES.login });
      this.$reset();
    },
    [ACTION_UPDATE_LANGUAGE](language) {
      if (this.language === language) {
        return;
      }

      this.language = language.toLowerCase();
      storage.save(this.language, STORAGE_KEYS.language);
      i18n.global.locale = this.language;
    },
  },
});
