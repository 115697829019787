<template>
  <div v-click-outside="closeSelect" :class="inlineSelectClass">
    <div class="inline-select_head" @click.stop="openSelect">
      <Icon :icon="arrowIconType" :color="arrowIconColor" />
      <p>
        {{ props.disableTranslations ? currentSelectText : $t(currentSelectText) }}
      </p>
    </div>

    <p v-if="errorMsg" class="inline-select_error">
      {{ $t(`${errorMsg}`) }}
    </p>

    <div v-show="isOpened" class="inline-select_options-container">
      <p v-for="option in props.options" :key="`inline-select-${option.value}`" class="inline-select_option">
        <BaseRadio v-model="selectedValue" :value="option.value">
          {{ props.disableTranslations ? option.text : $t(option.text) }}
        </BaseRadio>
      </p>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";

import Icon from "@/components/icons/Icon.vue";
import BaseRadio from "@/components/inputs/BaseRadio.vue";

export default {
  components: {
    Icon,
    BaseRadio,
  },

  props: {
    disableTranslations: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: ":empty:",
    },
    value: {
      type: [Number, String],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
  },

  emits: ["select", "error"],

  setup(props, { emit }) {
    const isOpened = ref(false);
    const changed = ref(false);

    const selectedValue = computed({
      get() {
        return props.value;
      },
      set(newVal) {
        changed.value = true;
        emit("select", newVal);
        closeSelect();
      },
    });

    const errorMsg = computed(() => (changed.value ? null : props.error));
    const inlineSelectClass = computed(() => {
      return { "inline-select": true, error: !!errorMsg.value && isOpened.value };
    });

    const arrowIconType = computed(() => (isOpened.value ? "arrow-up" : "arrow-down"));
    const arrowIconColor = computed(() => (isOpened.value ? "#999999" : "#444545"));
    const currentSelectText = computed(
      () => props.options.find((option) => option.value === props.value)?.text || props.placeholder
    );

    function openSelect() {
      isOpened.value = true;
    }

    function closeSelect() {
      isOpened.value = false;
    }

    watch(
      () => props.error,
      () => (changed.value = false)
    );

    return {
      props,

      isOpened,

      errorMsg,
      inlineSelectClass,
      arrowIconType,
      arrowIconColor,
      currentSelectText,
      selectedValue,

      closeSelect,
      openSelect,
    };
  },
};
</script>

<style lang="scss">
$input-height: 22px;

.inline-select {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 0 12px;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #444545;
  cursor: pointer;

  &_head {
    display: flex;
    align-items: baseline;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #444545;
  }

  &_options-container {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: fit-content;
    height: fit-content;
    background-color: white;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 8px 8px;
  }

  &_option {
    padding: 4px 12px;
  }

  &_option:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }

  &_error {
    font-size: 13px;
    color: #d63230;
    line-height: $input-height;
    max-height: $input-height;
    white-space: nowrap;
    overflow: hidden;
    max-width: 252px;
    text-overflow: ellipsis;
  }
}
</style>
