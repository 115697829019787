import i18n from "@/i18n";

export const getMessage = (msg) => {
  const { locale, messages } = i18n.global;
  const localeMessages = messages[locale];

  if (localeMessages) {
    return localeMessages[msg] ?? msg;
  }

  return msg;
};

export const t = (msg) => msg;

export const EMPTY_MESSAGE = t(":empty:");
