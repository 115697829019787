const LANGUGAGES = {
  am: "հայերեն",
  en: "English",
  lt: "Lietuvių",
  lt_LT: "Lietuvių",
  en_LT: "English LT",
  ru: "Русский",
  ua: "Українська",
  uk: "Українська",
};

export default {
  DEFAULT_LOCALE: process.env.VUE_APP_DEFAULT_LOCALE,
  LANGUGAGES,
  LOCALE: process.env.VUE_APP_LOCALE,
  SUPPORTED_LOCALES: process.env.VUE_APP_SUPPORTED_LOCALES,
};
