<template>
  <Icon :icon="iconName" :fill="iconFillColor" />
</template>

<script setup>
import { computed } from "vue";

import Icon, { DEFAULT_ICON_FILL_COLOR } from "./Icon.vue";

const props = defineProps({
  direction: {
    type: String,
    required: true,
  },
  active: {
    type: Boolean,
    required: true,
  },
});

const iconFillColor = computed(() => (props.active ? "#000" : DEFAULT_ICON_FILL_COLOR));
const iconName = computed(() => (props.direction === "asc" ? "arrow-up" : "arrow-down"));
</script>
