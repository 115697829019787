import isNumber from "lodash/isNumber";
import isNaN from "lodash/isNaN";

const DEFAULT_PRICEFORMATTER_CONFIG = {
  decimalDelimiter: ",",
  dropEmptyDecimal: false,
  locale: "en",
  precision: 2,
};

export function dateFormatter(num, numLength = 2, padSymbol = "0") {
  return num.toString().padStart(numLength, padSymbol);
}

export function priceFormatter(value, params = {}) {
  const config = Object.assign({}, DEFAULT_PRICEFORMATTER_CONFIG, params);
  const num = parseInt(value);

  if (!isNumber(num) || isNaN(num)) {
    return value;
  }

  const absNum = Math.abs(num);

  const polarity = num < 0 ? "-" : "";

  const fractional = 10 ** config.precision;
  const integer = Math.floor(absNum / fractional);
  const decimal = absNum % fractional;

  const integerPart = new Intl.NumberFormat(config.locale).format(integer);

  let decimalPart = "";

  if (!config.dropEmptyDecimal || decimal > 0) {
    const decimalNums = `${decimal}`.padStart(config.precision, "0");
    decimalPart = `${config.decimalDelimiter}${decimalNums}`;
  }

  return `${polarity}${integerPart}${decimalPart}`;
}
