<template>
  <div class="input_container">
    <select v-model="value" :class="{ quiet: showPlaceholder }">
      <option :value="null" selected disabled>{{ props.placeholder }}</option>
      <option v-for="option in options" :key="option.value" :value="option.value">{{ option.text }}</option>
    </select>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: [String, Number, null],
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const showPlaceholder = computed(() => props.modelValue === null);
const value = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});
</script>

<style lang="scss" scoped>
.input_container {
  width: 100%;
  position: relative;
  padding-bottom: 24px;

  select {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    height: 48px;
    padding: 0 24px;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    background: url("../../assets/images/icons/icon_arrow_down_grey.png") no-repeat right 16px center;

    &:focus {
      transition: border-color 0.4s;
      border-color: rgba(0, 0, 0, 0.7);
    }

    &[disabled] {
      opacity: 0.4;
    }

    &.quiet {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
