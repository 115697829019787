<template>
  <component :is="currentIcon" v-bind="iconColor" />
</template>

<script>
export const DEFAULT_ICON_FILL_COLOR = "#999";

const ICONS = {
  "add-user": AddUserIcon,
  "attention-round": AttentionRoundIcon,
  "attention-round2": AttentionRoundIcon2,
  "arrow-down": ArrowDown,
  "arrow-up": ArrowUp,
  "act-filled-icon": ActFilledIcon,
  "audit-filled-icon": AuditFilledIcon,
  "company-status-no_order": CompanyStatusNoOrderIcon,
  "company-status-paid": CompanyStatusPaidIcon,
  "company-status-pay_waiting": CompanyStatusPayWaitingIcon,
  "company-status-subscriptions_added": CompanyStatusSubscriptionsAddedIcon,
  "cross-small": CrossSmallIcon,
  "delete-stroked": DeleteIconStroked,
  "dots-vertical": DotsVerticalIcon,
  "invoice-filled-icon": InvoiceFilledIcon,
  "import-list": ImportListIcon,
  "modal-close": ModalCloseIcon,
  "pencil-small": PencilSmallIcon,
  act: ActIcon,
  approved: ApprovedIcon,
  audit: AuditIcon,
  building: BuildingIcon,
  calendar: CalendarStrokedIcon,
  check: CheckIcon,
  copy: CopyIcon,
  subscribers: SubscribersIcon,
  cross: CrossIcon,
  danger: DangerIcon,
  delete: DeleteIcon,
  download: DownloadIcon,
  dumbbell: DumbbellIcon,
  edit: EditIcon,
  employees: EmployeesIcon,
  info: InfoIcon,
  invoice: InvoiceIcon,
  language: LanguageIcon,
  logout: LogoutIcon,
  pencil: PencilIcon,
  plus: PlusIcon,
  preview: PreviewIcon,
  restore: RestoreIcon,
  search: SearchIcon,
  settings: SettingsIcon,
  success: SuccessIcon,
};

const STROKED_ICONS = ["calendar", "preview"];
</script>

<script setup>
import { computed } from "vue";

import ActFilledIcon from "./simple/ActFilledIcon.vue";
import ActIcon from "./simple/ActIcon.vue";
import AddUserIcon from "./simple/AddUserIcon.vue";
import ApprovedIcon from "./simple/ApprovedIcon.vue";
import AttentionRoundIcon from "./simple/AttentionRoundIcon.vue";
import ArrowDown from "./simple/ArrowDown.vue";
import ArrowUp from "./simple/ArrowUp.vue";
import AuditFilledIcon from "./simple/AuditFilledIcon.vue";
import AuditIcon from "./simple/AuditIcon.vue";
import BuildingIcon from "./simple/BuildingIcon.vue";
import CalendarStrokedIcon from "./stroked/CalendarIcon.vue";
import CheckIcon from "./simple/CheckIcon.vue";
import CompanyStatusNoOrderIcon from "./simple/CompanyStatusNoOrderIcon.vue";
import CompanyStatusPaidIcon from "./simple/CompanyStatusPaidIcon.vue";
import CompanyStatusPayWaitingIcon from "./simple/CompanyStatusPayWaitingIcon.vue";
import CompanyStatusSubscriptionsAddedIcon from "./simple/CompanyStatusSubscriptionsAddedIcon.vue";
import CopyIcon from "./simple/CopyIcon.vue";
import CrossIcon from "./simple/CrossIcon.vue";
import CrossSmallIcon from "./simple/CrossSmallIcon.vue";
import DangerIcon from "./simple/DangerIcon.vue";
import DeleteIcon from "./simple/DeleteIcon.vue";
import DeleteIconStroked from "./stroked/DeleteIcon.vue";
import DotsVerticalIcon from "./simple/DotsVerticalIcon.vue";
import DownloadIcon from "./simple/DownloadIcon.vue";
import EditIcon from "./simple/EditIcon.vue";
import EmployeesIcon from "./simple/EmployeesIcon.vue";
import InfoIcon from "./simple/InfoIcon.vue";
import InvoiceIcon from "./simple/InvoiceIcon.vue";
import InvoiceFilledIcon from "./simple/InvoiceFilledIcon.vue";
import ImportListIcon from "./simple/ImportListIcon.vue";
import LanguageIcon from "./simple/LanguageIcon.vue";
import LogoutIcon from "./simple/LogoutIcon.vue";
import ModalCloseIcon from "./simple/ModalCloseIcon.vue";
import PlusIcon from "./simple/PlusIcon.vue";
import PreviewIcon from "./stroked/PreviewIcon.vue";
import RestoreIcon from "./simple/RestoreIcon.vue";
import SearchIcon from "./simple/SearchIcon.vue";
import SettingsIcon from "./simple/SettingsIcon.vue";
import SubscribersIcon from "./simple/SubscribersIcon.vue";
import SuccessIcon from "./simple/SuccessIcon.vue";
import AttentionRoundIcon2 from "./simple/AttentionRoundIcon2.vue";
import DumbbellIcon from "./simple/DumbbellIcon.vue";
import PencilIcon from "./simple/PencilIcon.vue";
import PencilSmallIcon from "./simple/PencilSmallIcon.vue";

const props = defineProps({
  color: {
    type: String,
    required: false,
    default: null,
  },
  icon: {
    type: String,
    required: true,
    validator: (val) => Object.keys(ICONS).includes(val),
  },
  fill: {
    type: String,
    required: false,
    default: null,
  },
});

const currentIcon = computed(() => ICONS[props.icon]);
const iconColor = computed(() => {
  const isStrokedIcon = STROKED_ICONS.includes(props.icon);

  if (isStrokedIcon) {
    return {
      stroke: props.color ?? DEFAULT_ICON_FILL_COLOR,
    };
  }

  return {
    fill: props.fill ?? props.color ?? DEFAULT_ICON_FILL_COLOR,
  };
});
</script>
