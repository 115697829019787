import { ref } from "vue";

export default function useTimer(initialValue = 60) {
  let counter = ref(0);
  let timer = ref(null);

  function startTimer() {
    counter.value = initialValue;

    timer.value = setInterval(() => {
      counter.value--;

      if (counter.value === 0) {
        clearInterval(timer.value);
        timer.value = null;
      }
    }, 1000);
  }

  function stopTimer() {
    counter.value = 0;
    clearInterval(timer.value);
    timer.value = null;
  }

  return {
    counter,
    startTimer,
    stopTimer,
  };
}
