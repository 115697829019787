<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M18.66 20.9C18.25 20.53 17.61 20.57 17.25 20.99C16.68 21.64 15.86 22.01 15 22.01H5C3.35 22.01 2 20.66 2 19.01V5C2 3.35 3.35 2 5 2H9.51C9.67 2 9.84 2 10 2.02V7C10 8.65 11.35 10 13 10H18.81C19.12 10 19.41 9.86 19.6 9.61C19.79 9.36 19.85 9.05 19.78 8.75C19.47 7.53 18.84 6.42 17.95 5.53L14.47 2.05C13.15 0.73 11.39 0 9.52 0H5C2.24 0 0 2.24 0 5V19C0 21.76 2.24 24 5 24H15C16.43 24 17.8 23.38 18.75 22.31C19.12 21.9 19.08 21.26 18.66 20.9ZM12 2.66C12.38 2.88 12.73 3.15 13.05 3.47L16.53 6.95C16.84 7.26 17.11 7.62 17.33 8H12.99C12.44 8 11.99 7.55 11.99 7V2.66H12Z"
    />
    <path
      d="M12.5665 12.7696C12.754 12.5821 12.8594 12.3277 12.8594 12.0625C12.8594 11.7973 12.754 11.5429 12.5665 11.3554C12.3789 11.1679 12.1246 11.0625 11.8594 11.0625H3.85937C3.59416 11.0625 3.3398 11.1679 3.15227 11.3554C2.96473 11.5429 2.85938 11.7973 2.85938 12.0625C2.85938 12.3277 2.96473 12.5821 3.15227 12.7696C3.3398 12.9571 3.59416 13.0625 3.85937 13.0625H11.8594C12.1246 13.0625 12.3789 12.9571 12.5665 12.7696Z"
    />
    <path
      d="M3.85937 15.0625H8.85937C9.12459 15.0625 9.37894 15.1679 9.56648 15.3554C9.75402 15.5429 9.85937 15.7973 9.85937 16.0625C9.85937 16.3277 9.75402 16.5821 9.56648 16.7696C9.37894 16.9571 9.12459 17.0625 8.85937 17.0625H3.85937C3.59416 17.0625 3.3398 16.9571 3.15227 16.7696C2.96473 16.5821 2.85938 16.3277 2.85938 16.0625C2.85938 15.7973 2.96473 15.5429 3.15227 15.3554C3.3398 15.1679 3.59416 15.0625 3.85937 15.0625Z"
    />
    <path
      d="M19.8594 14.4844H17.8594V12.4844C17.8594 12.2192 17.754 11.9648 17.5665 11.7773C17.3789 11.5897 17.1246 11.4844 16.8594 11.4844C16.5942 11.4844 16.3398 11.5897 16.1523 11.7773C15.9647 11.9648 15.8594 12.2192 15.8594 12.4844V14.4844H13.8594C13.5942 14.4844 13.3398 14.5897 13.1523 14.7773C12.9647 14.9648 12.8594 15.2192 12.8594 15.4844C12.8594 15.7496 12.9647 16.0039 13.1523 16.1915C13.3398 16.379 13.5942 16.4844 13.8594 16.4844H15.8594V18.4844C15.8594 18.7496 15.9647 19.0039 16.1523 19.1915C16.3398 19.379 16.5942 19.4844 16.8594 19.4844C17.1246 19.4844 17.3789 19.379 17.5665 19.1915C17.754 19.0039 17.8594 18.7496 17.8594 18.4844V16.4844H19.8594C20.1246 16.4844 20.3789 16.379 20.5665 16.1915C20.754 16.0039 20.8594 15.7496 20.8594 15.4844C20.8594 15.2192 20.754 14.9648 20.5665 14.7773C20.3789 14.5897 20.1246 14.4844 19.8594 14.4844Z"
    />
  </svg>
</template>
