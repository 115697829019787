<template>
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_181_260)">
      <path
        d="M19.6296 2.73505H12.9629V16.4103H21.111V3.82906C21.111 3.53891 20.955 3.26064 20.6771 3.05548C20.3993 2.85031 20.0225 2.73505 19.6296 2.73505ZM15.9259 12.0342H14.4444V10.9402H15.9259V12.0342ZM15.9259 9.29915H14.4444V8.20513H15.9259V9.29915ZM15.9259 6.56411H14.4444V5.47009H15.9259V6.56411ZM18.8888 12.0342H17.4073V10.9402H18.8888V12.0342ZM18.8888 9.29915H17.4073V8.20513H18.8888V9.29915ZM18.8888 6.56411H17.4073V5.47009H18.8888V6.56411Z"
      />
      <path
        d="M9.91136 0H1.20025C0.783765 0 0.384335 0.122178 0.0898336 0.339656C-0.204668 0.557134 -0.370117 0.852098 -0.370117 1.15966V16.4103H3.33359V14.7692H7.77803V16.4103H11.4817V1.15966C11.4817 0.852098 11.3163 0.557134 11.0218 0.339656C10.7273 0.122178 10.3279 0 9.91136 0ZM3.33359 12.0342H1.85211V10.9402H3.33359V12.0342ZM3.33359 9.29915H1.85211V8.20513H3.33359V9.29915ZM3.33359 6.5641H1.85211V5.47009H3.33359V6.5641ZM3.33359 3.82906H1.85211V2.73504H3.33359V3.82906ZM6.29655 12.0342H4.81507V10.9402H6.29655V12.0342ZM6.29655 9.29915H4.81507V8.20513H6.29655V9.29915ZM6.29655 6.5641H4.81507V5.47009H6.29655V6.5641ZM6.29655 3.82906H4.81507V2.73504H6.29655V3.82906ZM9.25951 12.0342H7.77803V10.9402H9.25951V12.0342ZM9.25951 9.29915H7.77803V8.20513H9.25951V9.29915ZM9.25951 6.5641H7.77803V5.47009H9.25951V6.5641ZM9.25951 3.82906H7.77803V2.73504H9.25951V3.82906Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_181_260">
        <rect width="20" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
