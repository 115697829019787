import VALIDATORS_CONFIG from "@/config/validators";
import { VALIDATION_ERRORS } from "./index";
import clearPhone from "@/helpers/converters/clearPhone";

const { COUNTRIES, PHONES_COMMON_REGEXP } = VALIDATORS_CONFIG.PHONES;
const phoneCommonRegexp = new RegExp(PHONES_COMMON_REGEXP);

export default (phone) => {
  if (!phone || phone.length <= 1) {
    return false;
  }

  if (!phoneCommonRegexp.test(phone)) {
    return VALIDATION_ERRORS.invalidCharacters;
  }

  const rawPhone = clearPhone(phone);
  const countryCode = Object.keys(COUNTRIES).find((countryCode) => {
    return rawPhone.startsWith(COUNTRIES[countryCode].code);
  });

  if (!countryCode) {
    return false;
  }

  const phoneParams = COUNTRIES[countryCode];
  const phoneMaskRegexp = new RegExp(phoneParams.regexp);
  const phoneLength = phoneParams.length;

  if (rawPhone.length > phoneLength) {
    return VALIDATION_ERRORS.invalidFormat;
  }

  if (rawPhone.length === phoneLength) {
    if (!phoneMaskRegexp.test(rawPhone)) {
      return VALIDATION_ERRORS.invalidFormat;
    }
  }

  return false;
};
