<template>
  <form class="modal_container">
    <div class="modal_row header">
      <h3>{{ $t("Employee info") }}</h3>
      <a href="#" class="modal_close" @click.prevent="close">
        <Icon icon="modal-close" fill="#000" />
      </a>
    </div>
    <ErrorBanner class="error_banner" :message="$t(errorBannerMessage)" />

    <table class="table">
      <thead>
        <tr>
          <td v-for="column in TABLE_VERTICAL_COLUMNS" :key="column.header">
            {{ $t(column.header) }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="key in COMPENSATION_DEFAULT_KEYS" :key="key">
          <td :class="{ bold: true, changed: changedFields.includes(key) }">
            {{ getMessage(COMPENSATION_KEYS_MATCHES[key]) }}
          </td>
          <td :class="{ changed: changedFields.includes(key) }" :title="getMessage(prevInfo[key] ?? '—')">
            {{ getMessage(prevInfo[key] ?? "—") }}
          </td>
          <td :class="{ changed: changedFields.includes(key) }" :title="getMessage(currentInfo[key] ?? '—')">
            {{ getMessage(currentInfo[key] ?? "—") }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="modal_row buttons">
      <Button button-type="light" @click="onSubmit(state.membership, COPAYMENTS_STATUSES.UPGRADE_DECLINED)">{{
        $t("Decline")
      }}</Button>
      <Button :processing="state.processing" @click="onSubmit(state.membership, COPAYMENTS_STATUSES.APPROVED)">
        {{ $t("Confirm") }}
      </Button>
    </div>
  </form>
</template>

<script setup>
import { reactive, computed, onBeforeMount } from "vue";

import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import Icon from "@/components/icons/Icon.vue";

import useModalStore from "@/store/modal";
import { useCopaymentsStore, COPAYMENTS_STATUSES, ACTIONS_MODES } from "@/store/copayments";
import { clone } from "lodash";
import { isEmpty } from "lodash";
import { MODAL_TYPES } from "@/store/modal";

import { EMPTY_MESSAGE, t, getMessage } from "@/helpers/i18n/stubs";

const COMPENSATION_DEFAULT_KEYS = ["holder", "email", "phone", "level"];

const COMPENSATION_KEYS_MATCHES = {
  holder: t("Full name"),
  email: t("Corporate e-mail"),
  phone: t("Phone"),
  level: t("Level"),
};

const TABLE_VERTICAL_COLUMNS = [
  {
    header: "",
  },
  {
    header: t("Current"),
    style: "light",
  },
  {
    header: t("Incoming"),
    style: "light",
  },
];

const emit = defineEmits(["close"]);

const modalStore = useModalStore();
const copaymentsStore = useCopaymentsStore();

const state = reactive({
  membership: {
    id: null,
    status: null,
    holder: "",
    email: "",
    phone: "",
    level: "",
    price: "",
    new_data: {},
  },

  listName: "",

  processing: false,
  errorMessage: "",
});

const prevInfo = reactive({});

const currentInfo = computed(() => Object.assign(clone(prevInfo), state.membership.new_data));

const changedFields = computed(() => {
  if (!isEmpty(state.membership.new_data)) {
    return Object.keys(state.membership.new_data);
  }

  return [];
});

const errorBannerMessage = computed(() => {
  if (copaymentsStore.errors[MODAL_TYPES.approveChanges]) {
    return copaymentsStore.errorMessage;
  }

  return EMPTY_MESSAGE;
});

function close() {
  emit("close");
}

async function onSubmit(membershipInfo, status) {
  if (state.processing) {
    return;
  }

  const preparedInfo = {
    status,
  };

  state.processing = true;
  state.errorMessage = "";

  await copaymentsStore.updateMembershipInfo(preparedInfo, membershipInfo.id, state.listName, ACTIONS_MODES.modal);

  state.processing = false;

  if (!copaymentsStore.errors[MODAL_TYPES.approveChanges]) {
    emit("close");
  }
}

onBeforeMount(() => {
  if (modalStore.payload) {
    const membership = modalStore.payload.membershipForUpdate;

    state.listName = modalStore.payload.listName;
    state.membership = Object.assign(state.membership, membership);

    COMPENSATION_DEFAULT_KEYS.forEach((key) => (prevInfo[key] = state.membership[key]));
  }
});
</script>

<style lang="scss" scoped>
.modal_container {
  width: 800px;
  padding: 36px;
  overflow: auto;

  .modal_row.header {
    margin-bottom: 0;
  }

  .error_banner {
    margin-bottom: 0;
  }
}

.bold {
  font-weight: 600;
  font-size: 22px;
  color: #444545;
}

.changed {
  background-color: #ffeec7;
}

table {
  width: 100%;
  border-spacing: 0 16px;

  thead {
    tr {
      td {
        padding: 0 15px;
        max-width: 200px;
        font-weight: 400;
        font-size: 13px;
        color: #9c9c9c;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px 15px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid #e7e7e7;
        color: #444545;
      }
    }
  }
}

.btn {
  width: 198px;
}
</style>
