<template>
  <a href="#" tooltip-flow="up" :tooltip="tooltipMessage" @click.prevent="onClick" @mouseleave="onMouseLeave">
    <Icon icon="copy" :fill="props.fill || defaultFill" />
  </a>
</template>

<script>
import { computed, ref } from "vue";

import Icon from "@/components/icons/Icon.vue";

import { getMessage, t } from "@/helpers/i18n/stubs";
import clipboardCopy from "@/helpers/clipboardCopy";

const STATES = {
  afterCopy: Symbol("afterCopy"),
  beforeCopy: Symbol("beforeCopy"),
};

const MESSAGES = {
  [STATES.afterCopy]: t("Copied!"),
  [STATES.beforeCopy]: t("Copy to clipboard"),
};

const defaultFill = "#444545";

export default {
  components: {
    Icon,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    fill: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const state = ref(STATES.beforeCopy);

    const tooltipMessage = computed(() => getMessage(MESSAGES[state.value]));

    function onClick() {
      state.value = STATES.afterCopy;
      clipboardCopy(props.value);
    }

    function onMouseLeave() {
      state.value = STATES.beforeCopy;
    }

    return {
      props,
      defaultFill,
      tooltipMessage,

      onClick,
      onMouseLeave,
    };
  },
};
</script>
