import { createRouter, createWebHistory } from "vue-router";

import PortalLayout from "../layouts/PortalLayout.vue";
import RouterView from "@/views/RouterView.vue";

import ActPreview from "@/views/acts/ActPreview.vue";
import ActiveMembersListView from "@/views/copayments/ActiveMembersListView.vue";
import ActsListView from "@/views/acts/ActsListView.vue";
import PendingMembersListView from "@/views/copayments/PendingMembersListView.vue";
import CanceledMembersListView from "@/views/copayments/CanceledMembersListView.vue";
import ErrorView404 from "@/views/ErrorView404.vue";
import InvoicePreview from "@/views/invoices/InvoicePreview.vue";
import InvoicesListView from "@/views/invoices/InvoicesListView.vue";
import LoginView from "@/views/LoginView.vue";
import NotificationsSettingsView from "@/views/settings/NotificationsSettingsView.vue";
import SubscriptionsAuditView from "@/views/subscriptions/SubscriptionsAuditView.vue";
import SubscriptionsImportView from "@/views/subscriptions/SubscriptionsImportView.vue";
import SubscriptionsListView from "@/views/subscriptions/SubscriptionsListView.vue";
import VoucherRedeemView from "@/views/vouchers/VoucherRedeemView.vue";

import CONFIG from "@/config";
import useUserStore from "@/store/user";

export const ROUTE_NAMES = {
  home: "home",
  acts: {
    index: "acts/index",
    list: "acts/list",
    preview: "acts/preview",
  },
  invoices: {
    index: "invoices/index",
    list: "invoices/list",
    preview: "invoices/preview",
  },
  login: "login",
  copayments: {
    pending: "pending/list",
    active: "active/list",
    cancelled: "cancelled/list",
  },
  subscriptions: {
    audit: "subscriptions/audit",
    import: "subscriptions/import",
    list: "subscriptions/list",
  },
  settings: {
    notifications: "notifications",
  },
  vouchers: {
    redeem: "vouchers/redeem",
  },
};

const UNAUTHORIZED_ROUTES = [ROUTE_NAMES.login, ROUTE_NAMES.vouchers.redeem];

export const routes = [
  {
    path: "/",
    name: ROUTE_NAMES.home,
    component: PortalLayout,
    children: [
      {
        path: "subscriptions",
        component: RouterView,
        children: [
          {
            path: "list",
            name: ROUTE_NAMES.subscriptions.list,
            component: SubscriptionsListView,
          },
          {
            path: "list/import",
            name: ROUTE_NAMES.subscriptions.import,
            component: SubscriptionsImportView,
          },
          {
            path: "audit",
            name: ROUTE_NAMES.subscriptions.audit,
            component: SubscriptionsAuditView,
          },
        ],
      },
      {
        path: "copayments",
        component: RouterView,
        children: [
          {
            path: "pending/list",
            name: ROUTE_NAMES.copayments.pending,
            component: PendingMembersListView,
          },
          {
            path: "active/list",
            name: ROUTE_NAMES.copayments.active,
            component: ActiveMembersListView,
          },
          {
            path: "cancelled/list",
            name: ROUTE_NAMES.copayments.cancelled,
            component: CanceledMembersListView,
          },
        ],
      },
      {
        path: "acts",
        name: ROUTE_NAMES.acts.index,
        redirect: { name: ROUTE_NAMES.acts.list },
        children: [
          {
            path: "list",
            name: ROUTE_NAMES.acts.list,
            component: ActsListView,
          },
          {
            path: ":actId",
            name: ROUTE_NAMES.acts.preview,
            component: ActPreview,
          },
        ],
      },
      {
        path: "invoices",
        name: ROUTE_NAMES.invoices.index,
        redirect: { name: ROUTE_NAMES.invoices.list },
        children: [
          {
            path: "list",
            name: ROUTE_NAMES.invoices.list,
            component: InvoicesListView,
          },
          {
            path: ":invoiceId",
            name: ROUTE_NAMES.invoices.preview,
            component: InvoicePreview,
          },
        ],
      },
      {
        path: "settings",
        component: RouterView,
        children: [
          {
            path: "notifications",
            name: ROUTE_NAMES.settings.notifications,
            component: NotificationsSettingsView,
          },
        ],
      },
      {
        path: "/:catchAll(.*)",
        component: ErrorView404,
      },
    ],
  },
  {
    path: "/login",
    name: ROUTE_NAMES.login,
    component: LoginView,
    beforeEnter: () => {
      const userStore = useUserStore();

      if (userStore.isAuthenticated) {
        return { name: ROUTE_NAMES.home };
      }
    },
  },
  {
    path: "/vouchers",
    component: RouterView,
    children: [
      {
        path: "redeem",
        name: ROUTE_NAMES.vouchers.redeem,
        component: VoucherRedeemView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(CONFIG.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const userStore = useUserStore();

  if (UNAUTHORIZED_ROUTES.includes(to.name)) {
    return true;
  }

  if (!userStore.isAuthenticated) {
    return { name: ROUTE_NAMES.login };
  }
});

export default router;
