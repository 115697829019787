<template>
  <div class="view">
    <div class="view-top">
      <h2>{{ companyName }}</h2>
      <LanguageSwitcher class="language-switcher" />
    </div>

    <ErrorBanner v-if="counter" type="primary" class="error_banner" :message="$t('Saved successfully.')" />
    <ErrorBanner v-else type="error" class="error_banner" :message="state.errors.errorMessage" />

    <div v-if="state.processingGet" class="spinner_container">
      <SpinnerBrand />
    </div>
    <div v-else class="content">
      <h3>{{ $t("Notifications settings") }}</h3>

      <div class="settings">
        <div class="settings-email">
          <p>{{ $t("Email for notifications") }}:</p>
          <div class="settings-email_edit">
            <BaseInput
              ref="emailInputRef"
              v-model="state.currentNotificationsSettings.email"
              validator="email"
              :error="state.errors.email"
              icon="pencil-small"
              width="400px"
              inline
            />
          </div>
        </div>
        <div class="settings-language">
          <p>{{ $t("Language for SMS notifications") }}:</p>
          <InlineSelect
            :options="availableLanguages"
            :value="state.currentNotificationsSettings.locale"
            :placeholder="PLACEHOLDERS.selectLanguage"
            :error="state.errors.locale"
            @select="onLanguageSelect"
            @error="onLanguageSelectError"
          />
        </div>
      </div>
      <div class="controls">
        <Button
          class="controls_save"
          :processing="state.processingUpdate"
          :disabled="!isSaveEnabled"
          @click.prevent="updateNotificationsSettings(state.currentNotificationsSettings)"
        >
          {{ $t("Save") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, onMounted, watch, nextTick } from "vue";
import { useRoute } from "vue-router";

import Button from "@/components/buttons/Button.vue";
import LanguageSwitcher from "@/components/language/LanguageSwitcher.vue";
import InlineSelect from "@/components/inputs/InlineSelect.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";
import ErrorBanner from "@/components/banners/Banner.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";

import URLS from "@/config/urls";
import request from "@/helpers/request";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import useStore, { COMPANIES_REGISTRATION_TYPES } from "@/store";
import { t } from "@/helpers/i18n/stubs";
import { LOCALES } from "@/config";
import { clone, isEqual } from "lodash";
import { useTimer } from "@/composables";

const PLACEHOLDERS = {
  selectLanguage: t("Select language"),
};

const ICONS_COLORS = {
  edit: "#346AED",
};

const DEFAULT_ICON_FILL_COLOR = "#999999";

export default {
  components: {
    Button,
    LanguageSwitcher,
    InlineSelect,
    SpinnerBrand,
    ErrorBanner,
    BaseInput,
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const { counter, startTimer, stopTimer } = useTimer(10);

    const emailInputRef = ref(null);

    const isEmailInputError = computed(() => emailInputRef.value?.error);
    const isLanguageSelectError = ref(false);

    const state = reactive({
      initialNotificationsSettings: {
        locale: "",
        email: "",
      },

      currentNotificationsSettings: {
        locale: "",
        email: "",
      },

      hoveredIcons: {
        edit: false,
      },

      enableEditEmail: false,

      processingGet: false,
      processingUpdate: false,

      errors: {},
    });

    const availablelocales = computed(() => store.config.available_locales);

    const availableLanguages = computed(() => {
      const languagesArray = [];
      for (let locale of availablelocales.value) {
        languagesArray.push({ text: LOCALES.LANGUGAGES[locale], value: locale });
      }

      return languagesArray;
    });

    const currentCompany = computed(() => store.getCompanyById(route.query.company));
    const companyName = computed(() => currentCompany.value.name);
    const isSaveEnabled = computed(
      () =>
        !isEqual(state.initialNotificationsSettings, state.currentNotificationsSettings) &&
        !isEmailInputError.value &&
        !isLanguageSelectError.value
    );

    function iconFillStyle(icon) {
      if (state.hoveredIcons[icon]) {
        return ICONS_COLORS[icon];
      }

      return DEFAULT_ICON_FILL_COLOR;
    }

    function onMouseEnterIcon(key) {
      state.hoveredIcons[key] = true;
    }

    function onMouseLeaveIcon(key) {
      state.hoveredIcons[key] = false;
    }

    function onLanguageSelect(lang) {
      state.currentNotificationsSettings.locale = lang;
    }

    function onLanguageSelectError(isError) {
      isLanguageSelectError.value = isError;
    }

    async function updateNotificationsSettings({ email, locale } = {}) {
      state.processingUpdate = true;

      state.errors = {};

      const url = URLS.API.notificationsSettings;
      const data = { email, locale };

      try {
        const { locale, email } = await request({
          method: "PUT",
          url,
          data,
        });

        state.currentNotificationsSettings = {
          locale,
          email,
        };

        state.initialNotificationsSettings = clone(state.currentNotificationsSettings);

        nextTick(startTimer);
      } catch (xhrError) {
        const { errors, errorMessage } = prepareAxiosErrors(xhrError);

        state.errors = {
          errorMessage,
          ...errors,
        };
      }

      state.processingUpdate = false;
    }

    async function getNotificationsSettings() {
      state.processingGet = true;

      const url = URLS.API.notificationsSettings;

      try {
        const { locale, email } = await request(url);

        state.initialNotificationsSettings = {
          locale,
          email,
        };

        state.currentNotificationsSettings = clone(state.initialNotificationsSettings);
      } catch (xhrError) {
        state.errors = prepareAxiosErrors(xhrError, { flatResult: true });
      }

      state.processingGet = false;
    }

    function enableEditEmail() {
      state.enableEditEmail = true;
    }

    watch(
      () => state.currentNotificationsSettings,
      () => {
        if (counter.value) {
          stopTimer();
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      getNotificationsSettings();
    });

    return {
      COMPANIES_REGISTRATION_TYPES,
      PLACEHOLDERS,

      state,
      emailInputRef,
      counter,
      companyName,
      isSaveEnabled,
      currentCompany,
      availableLanguages,

      iconFillStyle,
      onMouseEnterIcon,
      onMouseLeaveIcon,
      onLanguageSelect,
      updateNotificationsSettings,
      enableEditEmail,
      onLanguageSelectError,
    };
  },
};
</script>

<style lang="scss" scoped>
.view {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 24px 0;

  .view-top {
    display: flex;
    padding: 0 24px;
    align-items: flex-start;
    justify-content: space-between;

    .language-switcher {
      flex-shrink: 0;
      margin-left: 24px;
    }

    h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2em;
      padding: 0.2em 0;
    }
  }

  .spinner_container {
    display: flex;
    padding: 0 24px;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
  }

  .content {
    margin-top: 14px;
    padding: 0 24px;
    display: grid;
    grid-template-columns: 1fr;

    h3 {
      margin-bottom: 34px;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #444545;
    }

    .settings {
      display: flex;
      flex-direction: column;
      gap: 30px;

      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #444545;
      }

      &-email {
        display: flex;
        align-items: flex-start;
        gap: 48px;

        .settings-email_edit {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: #444545;
        }

        &_edit {
          a {
            margin-right: 8px;
            cursor: pointer;
          }
        }
      }

      &-language {
        display: flex;
        align-items: flex-start;
        gap: 48px;
      }

      &_add-request,
      &_update-request {
        display: flex;
        flex-direction: column;

        .radio-group {
          display: flex;
          flex-direction: column;
          padding-left: 110px;
          margin-top: 16px;

          .radio-sub-group {
            display: flex;
            gap: 36px;
            align-items: flex-start;

            &_nested {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }

    .controls {
      width: 198px;
      margin-top: 32px;

      &_save {
        width: 100%;
      }
    }
  }
}
</style>
