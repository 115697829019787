<template>
  <div class="view">
    <div class="view-top">
      <h2>{{ companyName }}</h2>
      <LanguageSwitcher class="language-switcher" />
    </div>

    <Banner type="error" :message="$t(errorBannerMessage, { deactivationReason })" />
    <Banner
      v-show="unconfirmedSubscriberChanges"
      type="attention"
      :message="$t('Employee details have been changed.')"
    />

    <div class="controls">
      <BaseInput
        v-model="state.searchString"
        class="controls_search"
        icon="search"
        :placeholder="$t('Employee search')"
        hide-error-block
      />
      <Button
        :disabled="!state.checkedItemsList.length"
        @click="multipleStatusChange(state.checkedItemsList, COPAYMENTS_STATUSES.APPROVED)"
      >
        {{ $t("Confirm") }}
      </Button>
    </div>
    <div v-if="store.processingSubscriptions" class="spinner_container">
      <Spinner />
    </div>
    <div v-else class="content">
      <div class="statistics">
        <div class="statistics-item">
          <p class="statistics-item_header">{{ $t("Number of employees") }}:</p>
          <p class="statistics-item_value">{{ employeesNumber }}</p>
        </div>
        <div class="statistics-item light">
          <p class="statistics-item_header">{{ $t("New requests") }}:</p>
          <p class="statistics-item_value">{{ newRequestsNumber }}</p>
        </div>
      </div>
      <CopaymentsTable
        class="table"
        :actions="TABLE_ACTIONS"
        :approved-items="TABLE_APPROVED_ITEMS_STATUSES"
        :attention-items="TABLE_ATTENTION_ITEMS_STATUSES"
        :checkable-statuses="CHECKABLE_STATUSES"
        :checked-items-list="state.checkedItemsList"
        :customize-actions="customizeActions"
        :columns="TABLE_COLUMNS"
        :items="filteredMembershipsList"
        :processing="state.processing"
        :country="currentCompanyCountry"
        show-checkbox
        customizable-actions
        @approve="onStatusChange($event, COPAYMENTS_STATUSES.APPROVED)"
        @decline="onStatusChange($event, COPAYMENTS_STATUSES.DECLINED)"
        @update="openModal($event, MODAL_TYPES.approveChanges)"
        @checkbox-change="onCheckboxChange"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from "vue";
import { useRoute } from "vue-router";

import { useCopaymentsStore, COPAYMENTS_STATUSES, LIST_NAMES, COPAYMENTS_STATUSES_TEXT } from "@/store/copayments";
import useStore from "@/store";
import useModalStore, { ACTION_OPEN_MODAL, MODAL_TYPES } from "@/store/modal";

import { ACTIONS } from "@/components/buttons/ActionsButtonV2.vue";
import Banner from "@/components/banners/Banner.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import Spinner from "@/components/loaders/Spinner.vue";
import LanguageSwitcher from "@/components/language/LanguageSwitcher.vue";

import CopaymentsTable from "@/components/tables/CopaymentsTable.vue";

import { EMPTY_MESSAGE, t } from "@/helpers/i18n/stubs";
import { priceFormatter } from "@/helpers/formatters";

const TABLE_COLUMNS = [
  {
    header: t("Full name"),
    key: "holder",
    sortable: true,
  },
  {
    header: t("Corporate e-mail"),
    key: "email",
  },
  {
    header: t("Phone"),
    key: "phone",
  },
  {
    header: t("Subscription"),
    key: "level",
    sortable: true,
  },
  {
    header: t("Status"),
    key: "status",
    sortable: true,
    value: (item) => {
      return COPAYMENTS_STATUSES_TEXT[item.status];
    },
  },
  {
    header: t("Payment per employee"),
    key: "price",
    value: ({ price }) => {
      return priceFormatter(price);
    },
  },
];

const TABLE_ACTIONS = [
  {
    title: t("Approve"),
    key: ACTIONS.approve,
    icon: "check",
  },
  {
    title: t("Decline"),
    key: ACTIONS.decline,
    icon: "cross",
  },
];

const TABLE_UPDATE_REQUEST_ACTIONS = [
  {
    title: t("Review changes"),
    key: ACTIONS.update,
    icon: "pencil",
  },
  {
    title: t("Cancel subscription"),
    key: ACTIONS.decline,
    icon: "delete-stroked",
  },
];

const TABLE_APPROVED_ACTIONS = [
  {
    title: t("Cancel subscription"),
    key: ACTIONS.decline,
    icon: "delete-stroked",
  },
];

const TABLE_APPROVED_ITEMS_STATUSES = [
  COPAYMENTS_STATUSES.APPROVED,
  COPAYMENTS_STATUSES.UPGRADE_DECLINED,
  COPAYMENTS_STATUSES.DOWNGRADED,
];

const TABLE_ATTENTION_ITEMS_STATUSES = [COPAYMENTS_STATUSES.REQUEST_UPGRADE];

const MESSAGES = {
  deactivatedCompany: t(
    "Your company currently is disabled. You can not add or edit subscribers. Please, contact with your manager."
  ),
  deactivatedCompanyReason: t(
    'Your company currently is disabled for the reason: "{deactivationReason}". You can not add or edit subscribers. Please, contact with your manager.'
  ),
};

const CHECKABLE_STATUSES = [COPAYMENTS_STATUSES.REQUEST_ACTIVATION];

const route = useRoute();
const store = useStore();
const copaymentsStore = useCopaymentsStore();
const modalStore = useModalStore();

const state = reactive({
  sortField: "id",
  sortDirection: "asc",
  searchString: "",

  processing: false,

  checkedItemsList: [],
});

const isCompanyActive = computed(() => store.permissions?.activated ?? true);
const deactivationReason = computed(() => store.permissions?.deactivation_cause);

const pendingMembershipsList = computed(() => copaymentsStore.pendingMembershipsList);

const employeesNumber = computed(
  () => pendingMembershipsList.value.filter((item) => item.status !== COPAYMENTS_STATUSES.REQUEST_ACTIVATION).length
);
const newRequestsNumber = computed(
  () => pendingMembershipsList.value.filter((item) => item.status === COPAYMENTS_STATUSES.REQUEST_ACTIVATION).length
);

const currentCompany = computed(() => store.getCompanyById(route.query.company));
const companyId = computed(() => route.query.company);
const companyName = computed(() => currentCompany.value.name);
const currentCompanyCountry = computed(() => currentCompany.value.country);

const unconfirmedSubscriberChanges = computed(() =>
  copaymentsStore.pendingMembershipsList.some(({ status }) => status === COPAYMENTS_STATUSES.REQUEST_UPGRADE)
);

const errorBannerMessage = computed(() => {
  if (copaymentsStore.errors[LIST_NAMES.pending]) {
    return copaymentsStore.errorMessage;
  }

  const deactivatedCompanyMessage =
    deactivationReason.value && deactivationReason.value.length > 0
      ? MESSAGES.deactivatedCompanyReason
      : MESSAGES.deactivatedCompany;

  return isCompanyActive.value ? EMPTY_MESSAGE : deactivatedCompanyMessage;
});

const filteredMembershipsList = computed(() =>
  pendingMembershipsList.value.filter(({ holder, phone }) => {
    const searchData = [holder, phone].join(" ").toLowerCase();
    return searchData.includes(state.searchString.toLowerCase());
  })
);

function openModal(itemId, type) {
  const membershipForUpdate = pendingMembershipsList.value.find((membership) => membership.id === itemId);
  const payload = {
    membershipForUpdate,
    listName: LIST_NAMES.pending,
  };
  modalStore[ACTION_OPEN_MODAL]({ type }, payload);
}

function customizeActions(item) {
  if (item.status === COPAYMENTS_STATUSES.REQUEST_UPGRADE) {
    return TABLE_UPDATE_REQUEST_ACTIONS;
  } else if (
    item.status === COPAYMENTS_STATUSES.APPROVED ||
    item.status === COPAYMENTS_STATUSES.UPGRADE_DECLINED ||
    item.status === COPAYMENTS_STATUSES.DOWNGRADED
  ) {
    return TABLE_APPROVED_ACTIONS;
  }

  return TABLE_ACTIONS;
}

function onStatusChange(id, status) {
  const requestBody = {
    status,
  };
  copaymentsStore.updateMembershipInfo(requestBody, id, LIST_NAMES.pending);
  if (state.checkedItemsList.includes(id)) {
    state.checkedItemsList = state.checkedItemsList.filter((item) => item !== id);
  }
}

function onCheckboxChange(idArray) {
  state.checkedItemsList = idArray;
}

function multipleStatusChange(idArray, status) {
  const requestBody = {
    status,
  };
  copaymentsStore.multipleUpdateMembershipInfo(requestBody, idArray, LIST_NAMES.pending);
  state.checkedItemsList = state.checkedItemsList.filter((item) => !idArray.includes(item));
}

watch(
  () => route.query.company,
  async () => {
    if (companyId.value) {
      state.processing = true;
      await copaymentsStore.requestPendingMembershipsList(companyId.value);
      state.processing = false;
    }
  },
  { flush: "post", immediate: true }
);
</script>

<style lang="scss" scoped>
.view {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 24px 0;

  .view-top {
    display: flex;
    padding: 0 24px;
    align-items: flex-start;
    justify-content: space-between;

    .language-switcher {
      flex-shrink: 0;
      margin-left: 24px;
    }

    h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2em;
      padding: 0.2em 0;
    }
  }

  .controls {
    display: flex;
    padding: 0 24px;
    justify-content: space-between;
    align-items: flex-end;

    &_search {
      width: auto;
      max-width: 308px;
      flex-grow: 1;
    }
  }

  .spinner_container {
    display: flex;
    padding: 0 24px;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
  }

  .content {
    margin-top: 14px;
    display: grid;
    grid-template-columns: 1fr;

    .statistics {
      display: flex;
      padding: 0 24px;
      gap: 20px;
      margin-bottom: 16px;

      &-item {
        display: flex;
        gap: 6px;
        font-weight: 500;
        font-size: 13px;

        &.light {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
